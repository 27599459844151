import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { DashbordMenu,DashbordHeader } from "../../../components";
import { useTranslation } from 'react-i18next';
import { apiClient } from "../../../actions/api"
import { getAllCategories } from "../../../actions/pages";
import { updateInfoUser } from "../../../actions/user";
import Switch from '@material-ui/core/Switch';
import {getAllMissions,getAllMissionsv2, saveMessage, getMessages, setAsReaded,getCountUnreadMsg } from "../../../actions/pages";
import moment from "moment";
import 'moment/locale/fr'
const Page = () => {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { data } = useSelector(state => state.pages)
    const { all_missions, all_messages, all_sent_messages } = useSelector(state => state.pages)
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const [categories, setCategories] = useState([]);
    const [categ, setCateg] = useState('');
    const [rib, setRib] = useState('');
    const [loading, setLoading] = useState(false);

    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
    const label = { inputProps: { 'aria-label': '' } };

     const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
    
        
    
    }

      const goto = (slug) => {
         history.push(slug)
     }
    useEffect(() => {
    
      

    }, [])

    
 
   
 

 

    return (
        <div className="myccount editprofil editservices " >
            
                <section className="gray-bg main-dashboard-sec dashboard myccount abonnement devis " id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <DashbordMenu src='profil'/>
                                 
                            </div>


                         <div className='col-md-9 dashbordassitantcontent' >

                             <div className='tab' id=''>


                              <DashbordHeader src={'w'} />

                             <div className='row' style={{marginTop:'30px'}}>
                               <div className='col-md-12'>

                                <div className='flx889 '>
                      
                                              <div  className='item2 flx889bg'>
                                                <span className='it2' onClick={()=>goto('/assistants/devis/')} >Devis </span>
                                                <span className='it1'  >Factures </span>
                                                
                                               </div>
                                 </div>


                            

                               <div className='rowtiteldevis'>
                                  Factures
                               </div>
                         <form  onSubmit={handleSubmit} className='noraduis' >
                               
                            
                          <div className="profile-edit-container fl-wrap block_box">

                           <div className='row mr778'>
                              
                              <div className='col-md-4'>
                                  <div className='flx889'>
                                    
                                     <div className='actsearch flx889'>
                                       <input type="text" className='form-control clrbl11' placeholder="Nom du client.." />
                                       <button type="submit" className="btn float-btn color2-bg btn9876 btcustom lileft"> Rechercher</button>
                                     </div>
                                  </div>
                              </div>
                              <div className='col-md-5'>
                                <div className='flx889'>
                                     <div className='actsr55 clrbl11'>Période</div>
                                     <span style={{paddingRight:'12px'}} className='clrbl11'>Du </span>
                                     <div className='actsearch flx889'>
                                       <input type="text" className='form-control inp778' placeholder="" />
                                       <div className='absposcal'><img src='/images/icones/iconecalendrier.png' /></div>
                                     </div>
                                     <span style={{paddingRight:'12px'}} className='clrbl11'>au </span>
                                     <div className='actsearch flx889'>
                                       <input type="text" className='form-control inp778' placeholder="" />
                                        <div className='absposcal'><img src='/images/icones/iconecalendrier.png' /></div>
                                     </div>
                                  </div>
                              </div>
                              <div className='col-md-3'>
                                  <button type="submit" className="btn float-btn color2-bg btn9876 btcustom " style={{fontSize: '12px',borderRadius: '5px',float: 'right'}}> Télécharger les factures</button>
                              </div>

                           </div>
                                    <div className="custom-form">

                                  <table  id="datatable_valides" className="table table-separate  table-head-custom table-checkable dataTable no-footer dtr-inline mb-0 animated ">
                                    <thead>
                                    <tr>
                                        <th>Date facture</th>
                                        <th>N° facture</th>
                                        <th>Client</th>
                                        <th>Intervention</th>
                                        <th>date inter.</th>
                                        <th>Montant TTC</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                      {
                                          all_missions.map((element, index) => (


                                    <tr>

                                     <td>{element?.date_mission}</td>
                                     <td>Facture {element?.ref}</td>
                                     <td>{element?.nom} {element?.prenom}</td>
                                     <td>{element?.label}</td>
                                     <td> {moment(new Date(element?.updated_at)).format("DD MM Y")}</td>
                                     <td>{element?.price} Fcfa</td>
                                     <td>
                                      <div className='flx889 flmi90990'>
 
                                         
                                         <a href='javascript:void(0)' onClick={()=>history.push("/detail/mission/pro/"+element?.id)}  ><img style={{width: '28px'}} src='/images/icones/iconetransformerfacture.png' /></a>
                                        <a href='javascript:void(0)' ><img  style={{width: '15px',display:'none'}} src='/images/icones/Iconfeatherprinter.png' /></a>
                                         <a href={element?.facture_artisant} download ><img  style={{width: '18px'}}  src='/images/icones/iconetélécharger.png' /></a>
                  
                                      </div>

                                     </td>
                                     </tr>
                                                     



                                        ))


                                      }
                                    {/* 
                                               <tr>

                                     <td>12/05/2022</td>
                                     <td>Facture 062022</td>
                                     <td>DUPONT</td>
                                     <td>Pose robinet cuisine</td>
                                     <td>12/05/2022</td>
                                     <td>105.00 Fcfa</td>
                                     <td>
                                      <div className='flx889 flmi90990'>
 
                                         
                                         <a href='javascript:void(0)' ><img style={{width: '28px'}} src='/images/icones/iconetransformerfacture.png' /></a>
                                        <a href='javascript:void(0)' ><img  style={{width: '15px'}} src='/images/icones/Iconfeatherprinter.png' /></a>
                                         <a href='javascript:void(0)' ><img  style={{width: '18px'}}  src='/images/icones/iconetélécharger.png' /></a>
                  
                                      </div>

                                     </td>
                                     </tr>
                                               <tr>

                                     <td>12/05/2022</td>
                                     <td>Facture 062022</td>
                                     <td>DUPONT</td>
                                     <td>Pose robinet cuisine</td>
                                     <td>12/05/2022</td>
                                     <td>105.00 Fcfa</td>
                                     <td>
                                      <div className='flx889 flmi90990'>
 
                                         
                                         <a href='javascript:void(0)' ><img style={{width: '28px'}} src='/images/icones/iconetransformerfacture.png' /></a>
                                        <a href='javascript:void(0)' ><img  style={{width: '15px'}} src='/images/icones/Iconfeatherprinter.png' /></a>
                                         <a href='javascript:void(0)' ><img  style={{width: '18px'}}  src='/images/icones/iconetélécharger.png' /></a>
                  
                                      </div>

                                     </td>
                                     </tr>
                                               <tr>

                                     <td>12/05/2022</td>
                                     <td>Facture 062022</td>
                                     <td>DUPONT</td>
                                     <td>Pose robinet cuisine</td>
                                     <td>12/05/2022</td>
                                     <td>105.00 Fcfa</td>
                                     <td>
                                      <div className='flx889 flmi90990'>
 
                                         
                                         <a href='javascript:void(0)' ><img style={{width: '28px'}} src='/images/icones/iconetransformerfacture.png' /></a>
                                        <a href='javascript:void(0)' ><img  style={{width: '15px'}} src='/images/icones/Iconfeatherprinter.png' /></a>
                                         <a href='javascript:void(0)' ><img  style={{width: '18px'}}  src='/images/icones/iconetélécharger.png' /></a>
                  
                                      </div>

                                     </td>
                                     </tr> */}
                                    </tbody>

                                </table>
 
                                      
                                    </div>
                            </div>



                        </form>

 

                                    

                               </div>
                  

                             </div>


                                 
                          </div>
 

                              

                         </div>
                    </div>
            </section>

        </div>
    );
}

export default Page;
import React,{ useState, useEffect } from "react";
import { logo,google_play,app_store,logof,linkedin,face,insta } from "../../assets/images";
import './styles.scss';
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from 'react-i18next';
import { registerUser,loginUser,loginUserFacebook } from "../../actions/user";
import { apiClient } from "../../actions/api";
import FacebookLogin from 'react-facebook-login';
import { useHistory } from "react-router";

const Component = ({ item }) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory()
    const { user } = useSelector(state => state.user)
    const currentlang=i18n.language;
    const currentlangStorage = localStorage.getItem("currentlang");
    const cookies = localStorage.getItem("cookies");
    const [langset, setLangset] = useState(false);
    const [firstname, setFirstname] = useState('');
    const [lastname, setlastname] = useState('');
    const [email, setEmail] = useState('');
    const [isAccepted, setisAccepted] = useState(0);

    const [tokenmob, settokenmob] = useState(localStorage.getItem('typepush'));

    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('typepush');


    const [socials, setSocials] = useState({});

    useEffect(() => {
     
      if(cookies === 1 || cookies === '1' ){
         
        setisAccepted(1)
       }
       else{
       
         setisAccepted(0)

       }
       

    },[])

    const handleChangeAccepted = () => {
        
        localStorage.setItem("cookies",1);
        setisAccepted(1)
        
    } 

    const handlerconnexion = async (e) =>{
         e.preventDefault();
         const formData = new FormData(e.target)
         const formDataObj = Object.fromEntries(formData.entries())
         dispatch(loginUser(formDataObj,'user',currentlang));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        dispatch(registerUser(formDataObj,'user',currentlang));
       
    }


 const goto =  (slug) => {
        
        history.push(slug)
    }

    const gototop = () => {
        window.scrollTo(0,0);
    
    }

    const isSticky = (e) => {
        const header = document.querySelector(".toTop");
        const scrollTop = window.scrollY;
        scrollTop >= 250
        ? header.classList.add("is-top")
        : header.classList.remove("is-top");
    };

    const componentClicked =  () => {
        
       
    }

     const responseFacebook = (response) => {

    const membreData = {
        email: response.email,
    }

    return apiClient().post(`/check/user`, membreData)
          .then((res) =>
           { 

            if(res.data.success==true){
               
                 dispatch(loginUserFacebook(res))
            }
            else{

                 const params = {
                  email: response.email,
                  fname: response.first_name,
                  lname: response.last_name,
                  tel:'',
                  password:'EY778UEIEU76567'
                 }

                  dispatch(registerUser(params,'user',currentlang));

            }

         })

 
       
} 




    useEffect(() => {
        window.addEventListener("scroll", isSticky)
        return () => {
          window.removeEventListener("scroll", isSticky);
        }
 
        if(langset == false){

           


          return apiClient().get(`/getSocialMedia`, {})
          .then((res) =>
           { 

            setSocials(res.data.data)

         })


 
            setLangset(true)
        }
 
    }, [])


    return (
        <footer className="main-footer fl-wrap footer">
              <div className='footerborder'></div>
                <div className="footer-inner   fl-wrap">
                    <div className="container small-container">
                        <div className="row">
                           
                            <div className="col-md-2">
                                <div className="footer-widget fl-wrap">
                                    <div className="footer-logo"> <img src="/images/LogoProximiti.png" alt="Proximiti" /></div>
                                    <div className="footer-contacts-widget fl-wrap">
                                       
                                        
                                       
                                    </div>
                                </div>
                            </div>
                             
                            <div className="col-md-2">
                                <div className="footer-widget fl-wrap">
                                    <h3>Vous êtes</h3>
                                    <div className="footer-widget-posts fl-wrap">
                                        <ul className="no-list-style">
                                            <li className="clearfix">
                                                <a  style={{cursor:'pointer'}} onClick={() => goto('/details/59/client')} className="">Client</a>
                                                <a  style={{cursor:'pointer'}} onClick={() => goto('/details/60/presse')} className="">Presse</a>
                                                <a  style={{cursor:'pointer'}} onClick={() => goto('/details/61/professionnel')} className="">Professionnel</a>
                                                <a  style={{cursor:'pointer'}} onClick={() => goto('/details/62/candidat')} className="">Candidat</a>
                                                <a  style={{cursor:'pointer'}} onClick={() => goto('/details/63/partenaires')} className="">Partenaires</a>
                                             
                                            </li>
                                            
                                            
                                        </ul>
                                        
                                    </div>
                                </div>
                            </div>
                             
                            <div className="col-md-3">
                                <div className="footer-widget fl-wrap ">
                                    <h3>Les contrats d’assistance</h3>
                                    <div className="footer-widget-posts fl-wrap">
                                        <ul className="no-list-style">
                                            <li className="clearfix">
                                                <a style={{cursor:'pointer'}} onClick={() => goto('/details/55/contrats-d’assistance-electricité')} className="">Contrats d’assistance electricité</a>
                                                <a style={{cursor:'pointer'}} onClick={() => goto('/details/52/contrats-d’assistance-plomberie')} className="">Contrats d’assistance plomberie</a>
                                                <a style={{cursor:'pointer'}} onClick={() => goto('/details/56/contrats-d’assistance-electroménager')} className="">Contrats d’assistance electroménager</a>
                                                <a style={{cursor:'pointer'}} onClick={() => goto('/details/57/contrats-d’assistance-objet-connectés')} className="">Contrats d’assistance objet connectés</a>
                                                <a style={{cursor:'pointer'}} onClick={() => goto('/details/58/contrats-d’assistance-gaz')} className="">Contrats d’assistance gaz</a>
                                                
                                                

                                            </li>
                                            
                                            
                                        </ul>
                                        
                                    </div>
                                    
                                    
                                </div>
                            </div>


                                <div className="col-md-3">
                                <div className="footer-widget fl-wrap ">
                                    <h3>Nous contacter</h3>
                                    <div className="footer-widget-posts fl-wrap">
                                        <ul className="no-list-style">
                                            <li className="clearfix">
                                                <a  href="#" onClick={() => goto('/contact')} className=""><span style={{paddingRight:'10px'}}><img src='/images/Iconawesomephone-alt.png'/></span>09 70 99 84 45</a>
                                                <a href="#" className="">Du lundi au vendredi de 8h à 19h <br/>Le week-end de 8h à 19h</a>

                                                 {
                                                    (token || tokenmob ) ?
                                                    <div></div>
                                                    :
                                                   <a href="#" className=""><img width="140px" src='/images/apple-app-store-logo.jpg'/></a>
                                                     
                                                   }
                                                
                                                 
                                                

                                            </li>
                                            
                                            
                                        </ul>
                                        
                                    </div>
                                    
                                    
                                </div>
                            </div>

                                <div className="col-md-2">
                                <div className="footer-widget fl-wrap ">
                                    <h3><img src='/images/Frame30.png'/></h3>
                                    <div className="footer-widget-posts fl-wrap">
                                        <ul className="no-list-style">
                                            <li className="clearfix">
                                                <a href="#" className="">Start-up Lauréate French Tech Tremplin 2021-2022</a>
                                                
                                                

                                            </li>
                                            
                                            
                                        </ul>
                                        
                                    </div>
                                    
                                    
                                </div>
                            </div>

                        </div>
                        <div className='row' style={{marginTop:'30px'}}>
                         <div className='col-md-2'></div>
                         <div className='col-md-5'>
                          <div className="footer-widget-posts fl-wrap">
                                        <ul className="no-list-style ff56">
                                            <li className="clearfix">
                                            {
                                                    (token || tokenmob ) ?
                                                    <div></div>
                                                    :
                                                      <a href='javascript:void(0)'>Proximiti est présent sur vos assistants vocaux Google et Amazon. </a>
                                                     
                                                   }
                                              

                                            </li>
                                            
                                        </ul>
                            </div>
                         
                         </div>

                         <div className='col-md-5'>
                          <div className="footer-widget-posts fl-wrap">
                                        <ul className="no-list-style">
                                            <li className="clearfix">
                                               {
                                                    (token || tokenmob ) ?
                                                    <div></div>
                                                    :
                                                       <img src='/images/Frame33.png' />
                                                     
                                                   }
                                               

                                            </li>
                                            
                                        </ul>
                            </div>
                         
                         </div>


                        </div>


                        <div className='row'>
                                    
                                       <div className='col-md-12'>

                                <div className="sub-footer  fl-wrap">
                                <div className="container">
                                    <div className="copyright"> © MY Proximiti 2023 . All rights reserved.</div>
                                    
                                 
                                           <div className="footer-social">
                            
                                                        <ul className="no-list-style nop66">

                                                        

                                             
                                                       <li><a href={socials.Twitter ? socials.Twitter :"javascript:void(0)" } target="_blank"><img src='/images/ettwitter.png'/></a></li>
                                                       <li><a href={socials.Facebook ? socials.Facebook :"javascript:void(0)" } target="_blank"><img src='/images/logofacebook.png' /></a></li>
                                                       
                                                       <li><a href={socials.Instagram ? socials.Instagram :"javascript:void(0)" } target="_blank"><img src='/images/logoinstagram.png' /></a></li>
                                                       <li><a href={socials.linkedin ? socials.linkedin :"javascript:void(0)" } target="_blank"><img src='/images/logolinkedin.png' /></a></li>
                                                         

                                         
                                                            
                                                            
                                                        </ul>
                                                    </div>
                                  
                                </div>
                            </div>

                                       </div>



                         <div className='col-md-1'></div>
                           <div className='col-md-10'>


                                <div className="footer-widget-posts fl-wrap">
                                        <ul className="no-list-style">
                                            <li className="clearfix2 copyright">
                                                ©Proximiti 2023<span> </span>

                                            </li>
                                             <li className="clearfix2">
                                               <a href="#" onClick={() => goto('/details/72/conditions')} className="">Conditions juridiques<span> - </span></a>

                                            </li>
                                            <li className="clearfix2">
                                               <a href="#" onClick={() => goto('/details/73/politique-des-cookies')} className="">Politique des cookies <span> - </span></a>

                                            </li>
                                             <li className="clearfix2">
                                               <a href="#"onClick={() => goto('/details/74/gestion-des-cookies')}  className="">Gestion des cookies <span> - </span></a>

                                            </li>
                                             <li className="clearfix2">
                                               <a href="#" onClick={()=>goto('/details/76/legal-notice/')}className="">Mentions légales<span> - </span></a>

                                            </li>
                                             <li className="clearfix2">
                                               <a href="#" className="" onClick={() => goto('/details/6/cgu')} >CGU<span> - </span></a>

                                            </li>
                                            <li className="clearfix2">
                                               <a href="#" className="" onClick={() => goto('/details/75/charte')}>Charte qualité<span> - </span></a>

                                            </li>
                                              <li className="clearfix2">
                                               <a href="#" onClick={()=>goto('/details/77/rgpd')} className="">RGPD<span>  </span></a>

                                            </li>

                                            
                                        </ul>
                            </div>

              

                           </div>
                           <div className='col-md-1'></div>


                        </div>




                    </div>
                     
                 
                {
                isAccepted == 0 ?
                (token || tokenmob ) ?
                ""
                :
                <div id="cookie-alert-container" className={isAccepted == 1 ? 'displayNone':''}>
                    <div className="cookie">
                        <h3>Votre vie privée <i className="fa fa-lock"></i></h3>
                        <div className="description">
                            <p>Nous utilisons des cookies pour personnaliser notre contenu, mesurer l'efficacité de nos publicités et améliorer leur pertinence,
                            ainsi que pour offrir une expérience plus sûre. En naviguant sur le site, vous acceptez l'utilisation de cookies pour collecter des informations sur et en dehors de Proximiti.
                            </p>
                            <div className="form-group">
                                <button onClick={() => handleChangeAccepted()} className="darkbtn">J'accepte</button>
                            </div>
                        </div>
                    </div>
                </div>
                    :
                    ""
                }
                    
                </div>
               



             <div className="main-register-wrap modal">
                <div className="reg-overlay"></div>
                <div className="main-register-holder tabs-act">
                    <div className="main-register fl-wrap  modal_main">
                        <div className="main-register_title"></div>
                        <div className="close-reg center"><i className="fal fa-times"></i></div>
                        <ul className="tabs-menu fl-wrap no-list-style">
                            <li className="current"><a href="#tab-1"><i className="fal fa-sign-in-alt"></i> {t('Connexion')}</a></li>
                            <li><a href="#tab-2"><i className="fal fa-user-plus"></i> {t('Inscription')}</a></li>
                        </ul>
                                              
                        <div className="tabs-container">
                            <div className="tab">
                                
                                <div id="tab-1" className="tab-content first-tab">
                                    <div className="custom-form">
                                        <form  name="registerform" onSubmit={handlerconnexion}  >
                                            <label>{t('Email')} <span>*</span> </label>
                                            <input name="email" type="email" required/>
                                            <label >{t('Mot de passe')} <span>*</span> </label>
                                            <input name="password" type="password" required   / >
                                            <button type="submit"  className="btn float-btn color2-bg " > {t('Connexion')} <i className="fas fa-caret-right"></i></button>
                                            <div className="clearfix"></div>
                                            <div className="filter-tags">
                                                <input id="check-a3" type="checkbox" name="check"  />
                                                <label for="check-a3">{t('Se souvenir de moi')}</label>
                                            </div>
                                        </form>
                                        <div className="lost_password">
                                            <a  href="/password/user">{t('Mot de passe oublié ?')}</a>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="tab">
                                    <div id="tab-2" className="tab-content">
                                        <div className="custom-form">
                                            <form  onSubmit={handleSubmit}  name="registerform" className="main-register-form" id="main-register-form2">
                                                
                                                <label >{t('Prénom')} <span>*</span> </label>
                                                <input name="fname" type="text"   required />
                                                <label >{t('Nom')} <span>*</span> </label>
                                                <input name="lname" type="text"    required />
                                                <label >{t('Téléphone')} <span>*</span> </label>
                                                <input name="tel" type="text"   required />

                                                <label>{t('Email')}  <span>*</span></label>
                                                <input name="email" type="email"  required/>
                                                <label >{t('Mot de passe')} <span>*</span></label>
                                                <input name="password" type="password"  required />
                                                <div className="filter-tags ft-list"> 
                                                    <input id="check-a2" type="checkbox" name="check" required />
                                                    <label for="check-a2">{t('En créant un compte, j’accepte les')} <a href="javascript:void(0)">{t('CGU')}</a></label>
                                                </div>
                                                <div className="clearfix"></div>
                                               
                                                <div className="clearfix"></div>
                                                <button type="submit"      className="btn float-btn color2-bg"> {t('Je créée mon compte')}  <i className="fas fa-caret-right"></i></button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            
                            <div className="log-separator fl-wrap center"><span>{t('OU')}</span></div>
                            <div className="soc-log fl-wrap">
                                <p>{t('Pour une connexion ou un enregistrement plus rapide, utilisez votre Facebook.')}</p>
                                <a style={{display:'none'}} href="javascript:void(0)" className="facebook-log"> Facebook</a>
                               <FacebookLogin
                                        appId="1810271582506013"
                                        autoLoad={false}
                                        fields="first_name,last_name,email"
                                        textButton={t('Connexion avec Facebook')}
                                        onClick={componentClicked}
                                         callback={responseFacebook} />
                            </div>
                            <div className="wave-bg">
                                <div className='wave -one'></div>
                                <div className='wave -two'></div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
                
              <a href="javascript:void(0)" onClick={()=>gototop()} id="toTop" className='toTop' ><span id="toTopHover" ></span>To Top</a>
            </footer>
    );
}

export default Component;
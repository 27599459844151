import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { DashbordMenuClient,DashbordHeaderClient } from "../../../components";
import { useTranslation } from 'react-i18next';
import { apiClient } from "../../../actions/api"
import { getAllCategories } from "../../../actions/pages";
import { updateInfoUser } from "../../../actions/user";

const Page = () => {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { data } = useSelector(state => state.pages)
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const [categories, setCategories] = useState([]);
    const [categ, setCateg] = useState('');
    const [rib, setRib] = useState('');
    const [loading, setLoading] = useState(false);

    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
     const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        let go=true;

   


        if(go){
           dispatch(updateInfoUser(formDataObj,user.id,'watcher'));
        }
        
    
    }


    useEffect(() => {
    
       
   
         

       
        
        if(langset == false){
             

            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }

    }, [])

    
     const handleSetCategorie = (categ) => {
            
            let newcategories=[...categories];
            var index = newcategories.indexOf(categ)
            if( index > -1){
               newcategories.splice(index, 1);
               setCategories(newcategories);
 
            }
            else{

               newcategories.push(categ);
               setCategories(newcategories);

            }            
    }


      const createPdf = (file) => {
      
      setLoading(true)
      const data = new FormData();
      data.append('membreId', user.id);
      data.append('file', file);
      data.append('type', 'ribs');
      apiClient().post(`/insertimage`,data)
            .then((res) => {
               setRib(res.data.name)  
               setLoading(false)             
            })

  }

    const uploadPdf = (e) => {
        

    let files = e.target.files || e.dataTransfer.files;
    if (!files.length)
    return;

      if(files[0].type=='application/pdf'  ){
              if(files[0].size <= (2*1000000)){
                 createPdf(files[0]);
              }
              else{

                 alert(t('Votre pièce-jointe ne doit pas dépasser cette taille: 2 MO.'));
              }
      }
      else{
         alert(t("Ce format n'est pas supporté ! Le format accepté est (PDF)"));
      }

        const param = {
                id:user.id,
              //files_img:this.state.files,
            }
    }

    return (
        <div className="myccount editprofil abonnement  " >
            
                       <section className="gray-bg main-dashboard-sec dashboard myccount history" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <DashbordMenuClient src='historique'/>
                                 
                            </div>


                         <div className='col-md-9 dashbordassitantcontent' >

                             <div className='tab' id=''>


                              <DashbordHeaderClient src={'w'} />

                             <div className='row' style={{marginTop:'30px'}}>
                               <div className='col-md-8'>

                                 
                      

                                   

                              <form  onSubmit={handleSubmit} >


                              <div className='row'>

                                 <div className='col-md-4 historyttp'>
                                 
                                    <h3 className='htri1'>Solde </h3>
                                
                                   <label>au 30 mars 20202</label>
                                  <div><strong>+ 0.00 Fcfa</strong></div>
                                 </div>


                                  <div className='col-md-4 historyttp'>
                                 
                                   <div className='rowflex'>
                                    <div><img src='/images/icones/icodown.png'/></div>
                                    <div>
                                       <label>Total des entrées</label>
                                       <div><strong>+ 0.00 Fcfa</strong></div>
                                    </div>

                                   </div>
                                 </div>

                                 <div className='col-md-4 historyttp'>
                                   <div className='rowflex'>
                                    <div><img src='/images/icones/icoup.png'/></div>
                                    <div>
                                       <label>Total des sorties</label>
                                       <div><strong>+ 0.00 Fcfa</strong></div>
                                    </div>

                                   </div>
                                 </div>

                              </div>
<br/><br/>
                               <div className='historyttp'>
                                
                                  <h3 >Dernières transactions </h3>

                               </div>

                       

       <br/>
       
                       <div className='row'>

                         <div className='col-md-8'>
                             <div className='devis'>

                               <div className='flx889'>
                                     <div className='actsr55 clrbl11'>Période</div>
                                     <span style={{paddingRight:'12px'}} className='clrbl11'>Du </span>
                                     <div className='actsearch flx889'>
                                       <input type="text" className='form-control inp778' placeholder="" />
                                       <div className='absposcal'><img src='/images/icones/iconecalendrier.png' /></div>
                                     </div>
                                     <span style={{paddingRight:'12px'}} className='clrbl11'>au </span>
                                     <div className='actsearch flx889'>
                                       <input type="text" className='form-control inp778' placeholder="" />
                                        <div className='absposcal'><img src='/images/icones/iconecalendrier.png' /></div>
                                     </div>
                                  </div>


                        </div> 
                         </div>
                          <div className='col-md-4'>
                                <button type="submit" className="btn float-btn color2-bg btn9876 btcustom " style={{fontSize: '12px',borderRadius: '5px',float: 'right'}}> Télécharger </button>
                         </div>
                         



                       </div>

                        
                     

                            <div  className='row' style={{marginTop: '21px', marginBottom: '20px'}}>
                                <div className='col-md-7'></div>
                                <div className='col-md-5'>
                              <select className='form-control'>
                                      <option>Toutes les opérations</option>
                              </select>
                              </div>

                            </div>

                          <div className="profile-edit-container fl-wrap block_box">
                                      <table  id="datatable_valides" className="table table-separate  table-head-custom table-checkable dataTable no-footer dtr-inline mb-0 animated ">
                                    <thead>
                                    <tr>
                                        <th>Date </th>
                                        <th>Type</th>
                                        <th>Débit</th>
                                        <th>Crédit</th>
                                     
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {/* <tr>
                                     <td>25/02/22</td>
                                     <td>Tocket N° 8876</td>
                                     <td></td>
                                     <td>+ 100,00 Fcfa</td>
                                   

                                    </tr>
                                    <tr>
                                     <td>25/02/22</td>
                                     <td>Retrait</td>
                                     <td>- 200,00 Fcfa</td>
                                     <td></td>
                                   

                                    </tr>
                                           <tr>
                                     <td>25/02/22</td>
                                     <td>Tocket N° 8876</td>
                                     <td></td>
                                     <td>+ 100,00 Fcfa</td>
                                   

                                    </tr>
                                    <tr>
                                     <td>25/02/22</td>
                                     <td>Retrait</td>
                                     <td>- 200,00 Fcfa</td>
                                     <td></td>
                                   

                                    </tr> */}
                                
                                    </tbody>
                                    </table>
                                </div>



                  
 

                                   </form>

                                    

                               </div>
                               <div className='col-md-4'>
                                
                            

                                 <div className='w100'>
                                      <img src='/images/historytresor.png' />
                                 </div>
                               
                                 

                                </div>

                             </div>


                                 
                              </div>
 

                              

                         </div>
                    </div>
            </section>

        </div>
    );
}

export default Page;
// import React, { useEffect, useState } from "react";
// import { Button, Modal } from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";
// import { useHistory, useParams } from "react-router";
// import './styles.scss';



// const Page = () => {
//   const { keyword: gottenKeyword } = useParams();
//   const [keyword, setkeyword] = useState(gottenKeyword)
//   const history = useHistory()
//   const dispatch = useDispatch();
//   const [show, setShow] = useState(false);
//   const handleClose = () => setShow(false);

//   const { user } = useSelector(state => state.user)
//   const [langset, setLangset] = useState(false);




//   const handleGoTo = () => {
//     history.push('/recherche-artisan#');

//   }



//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formData = new FormData(e.target)
//     const formDataObj = Object.fromEntries(formData.entries())

//   }


//   const componentClicked = () => {


//   }


//   const handleShow = () => {

//     // history.push('/prenez-rendez-vous-avec-un-artisan#');

//   };



//   useEffect(() => {

//     // if (langset == false) {
//     //   setLangset(true)
//     //   setTimeout(handleShow, 2000);
//     // }

//     // let codepostal = localStorage.getItem('codepostal');
//     // let lat = localStorage.getItem('lat');
//     // let lng = localStorage.getItem('lng');


//     // dispatch(getPricesByElement(localStorage.getItem('intervention_type'), lat, lng, codepostal)).then((res) => {


//     //   if (res.succes == false)
//     //     setShow(true)
//     //   else {
//     //     localStorage.setItem('min_price', res.data.min_price)
//     //     localStorage.setItem('max_price', res.data.max_price)
//     //     localStorage.setItem('artisantsids', res.artisants)
//     //     setTimeout(() => {
//     //       history.push('/prenez-rendez-vous-avec-un-artisan#');
//     //     }, 300);
//     //   }
//     // })

//   }, [])



//   const [showModal, setShowModal] = useState(true);

//   return (
//     <div className="besoin  " >
//       <section className="gray-bg big-padding " id="besoin4">
//         <div className="container small-container ">
//           <form className='registerform'>
//             <div className='row '>
//               <div className='col-md-12'>
//                 <div className='progressbare'>
//                   <div className='veh' style={{ left: '60%' }}><img src='/images/Groupe6849.png' /></div>
//                   <div className="progressvalue" style={{ width: '65%' }}></div>
//                   <div className='bat' ><img src='/images/Groupe6903.png' /></div>
//                 </div>
//               </div>
//             </div>
//             <div className='row '>
//               <div className='col-md-12'>
//                 {
//                   show ?
//                     <div className="section-title"><h2 className='center'><span>Aucun intervenant trouvé ... </span></h2></div>
//                     :
//                     <div className="section-title"><h2 className='center'><span>Nous recherchons un intervenant ... </span></h2></div>

//                 }
//                 <div className='mrg78'></div><br />
//                 {
//                   show ?
//                     '' :
//                     <div className='loading'>

//                       <img src='/images/animation_500_l2pw5cf5.gif' />

//                     </div>
//                 }
//               </div>
//             </div>
//           </form>
//         </div>
//       </section>
//       <MailModal setShowModal={setShowModal} showModal={showModal} />
//     </div>
//   );
// }




// const MailModal = ({ showModal, setShowModal }) => {
//   const [name, setName] = useState();
//   const [email, setEmail] = useState();
//   const [contact, setContact] = useState();



//   const handleSubmit = () => { }
//   return (
//     <Modal show={showModal} onHide={() => setShowModal(false)}>
//       <Modal.Header closeButton>
//         <div className="modal-header-container">
//           <div className="circular-image">
//             <img src="/images/maçon.png" alt="Modal Image" style={{ width: '70px', height: '70px' }} />
//           </div>
//           <div className="modal-title">
//             <h4>Any Question ?</h4>
//           </div>
//         </div>
//       </Modal.Header>
//       <Modal.Body>
//         <div className="form-group">
//           <label htmlFor="name">Name</label>
//           <input
//             type="text"
//             id="name"
//             className="form-control"
//             placeholder="Enter your name"
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//           />
//         </div>
//         <div className="form-group">
//           <label htmlFor="email">Email</label>
//           <input
//             type="email"
//             id="email"
//             className="form-control"
//             placeholder="Enter your email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//           />
//         </div>
//         <div className="form-group">
//           <label htmlFor="contact">Contact</label>
//           <input
//             type="tel"
//             id="contact"
//             className="form-control"
//             placeholder="Enter your contact number"
//             value={contact}
//             onChange={(e) => setContact(e.target.value)}
//           />
//         </div>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={() => setShowModal(false)}>
//           Close
//         </Button>
//         <Button type="submit" onClick={handleSubmit} variant="primary">
//           Send
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }

// export default Page;


import React, { useState, useEffect } from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router";
import Swal from 'sweetalert2';
import { getPricesByElement } from "../../../actions/pages";
import ManImage from '../../../assets/maçon.png'
const Page = () => {
  // const { keyword: gottenKeyword } = useParams();
  const [show, setShow] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [address, setAddress] = useState("")
  const [langset, setLangset] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleGoTo = () => {
    history.push('/recherche-artisan#');
  }

  const handleSubmit = async () => {
    var intervention_typeValue = localStorage.getItem('intervention_typeValue')
    var fuiteValue = localStorage.getItem('fuiteValue')
    var problemeDescription = localStorage.getItem('problemeDescription')
    var metierValue = localStorage.getItem('metierValue');
    var besoinValue = localStorage.getItem('besoinValue');
    var type = localStorage.getItem('type');
    var service_id = localStorage.getItem('service_id');
    // Construct the data to send to the API
    const formData = {
      user_email: email,
      name,
      contact,
      metierValue,
      besoinValue,
      type,
      service_id,
      intervention_typeValue,
      fuiteValue,
      problemeDescription,
      address,
    };
    // Send the form data to the API
    // await fetch("http://127.0.0.1:8000/api/submit-form", {
      await fetch("https://proximitici.com/proximiti.ci/admin/public/api/submit-form", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': "*/*"
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        Swal.fire({
          icon: 'success',
          title: 'Email Sent',
          text: 'Your email has been sent successfully.',
        });
        history.push("/");
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors
      });
  }

  const handleShow = () => {
    setShowModal(true);
  }

  useEffect(() => {
    setShowModal(true);
    // if (langset === false) {
    //   setLangset(true);
    //   setTimeout(() => {
    //   }, 2000);
    // }

    // let codepostal = localStorage.getItem('codepostal');
    // let lat = localStorage.getItem('lat');
    // let lng = localStorage.getItem('lng');

    // dispatch(getPricesByElement(localStorage.getItem('intervention_type'), lat, lng, codepostal)).then((res) => {
    //   if (res.success === false) {
    //     setShow(true);
    //   } else {
    //     localStorage.setItem('min_price', res.data.min_price);
    //     localStorage.setItem('max_price', res.data.max_price);
    //     localStorage.setItem('artisantsids', res.artisants);
    //     setTimeout(() => {
    //       history.push('/prenez-rendez-vous-avec-un-artisan#');
    //     }, 300);
    //   }
    // });
  }, []);



  return (
    <div className="besoin  " >
      <section className="gray-bg big-padding " id="besoin4">

        <div className="container small-container ">
          <form className='registerform'>

            <div className='row '>
              <div className='col-md-12'>
                <div className='progressbare'>
                  <div className='veh' style={{ left: '60%' }}><img src='/images/Groupe6849.png' /></div>
                  <div className="progressvalue" style={{ width: '65%' }}></div>
                  <div className='bat' ><img src='/images/Groupe6903.png' /></div>
                </div>
              </div>
            </div>


            <div className='row '>
              <div className='col-md-12'>


                {
                  show ?
                    <div className="section-title"><h2 className='center'><span>Aucun intervenant trouvé ... </span></h2></div>

                    :

                    <div className="section-title"><h2 className='center'><span>Nous recherchons un intervenant ... </span></h2></div>

                }


                <div className='mrg78'></div><br />

                {
                  show ?
                    '' :
                    <div className='loading'>

                      <img src='/images/animation_500_l2pw5cf5.gif' />

                    </div>
                }


              </div>



            </div>

          </form>




        </div>



      </section>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <div className="modal-header-container">
            <div className="circular-image">
              <img src={"/images/logo.jpeg"} alt="Modal Image" style={{ width: '70px', height: '70px' }} />
            </div>
            <div className="modal-title">
              <h4>Informations</h4>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="name">Nom</label>
            <input
              type="text"
              id="name"
              className="form-control"
              placeholder="Entrez votre Nom"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">e-mail</label>
            <input
              type="email"
              id="email"
              className="form-control"
              placeholder="Entrez votre Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="contact">Contact</label>
            <input
              type="number"
              id="contact"
              className="form-control"
              placeholder="Entrez votre numéro de contact"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="contact">Adresse</label>
            <input
              type="tel"
              id="contact"
              className="form-control"
              placeholder="Entrez votre adresse"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
          Fermer
          </Button>
          <Button type="submit" onClick={async (e) => {
            e.preventDefault();
            e.currentTarget.innerText = "S'il vous plaît, attendez";
            await handleSubmit();
            // e.currentTarget.innerText = "Send";

          }} variant="primary">
            Envoyer
          </Button>
        </Modal.Footer>
      </Modal>


    </div>

  );
}

export default Page;
import React, { useRef, useState } from 'react';
import './styles.scss';
import { Container, Row, Col, Form, Button, Nav, Card, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap, BiShoppingBag, BiUserCircle, BiHistory } from 'react-icons/bi';
import { DashbordMenuClient, DashbordHeaderClient } from "../../../components";
import { useTranslation } from 'react-i18next';
import { apiClient } from "../../../actions/api"

import { AUTHENTICATE_USER, LOGOUT_USER } from "../../../constants/actions";
import { saveBien } from "../../../actions/pages";
import { BASE_URL, BASE_URL_PROD } from "../../../components/config/keys";
import Switch from '@material-ui/core/Switch';
import Slider from '@material-ui/core/Slider';
import GoogleMapReact from 'google-map-react';
import Swal from 'sweetalert2';
import axios from "axios";

import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import PlacesAutocomplete from 'react-places-autocomplete';

const Page = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user)
  const { data } = useSelector(state => state.pages)
  const { t, i18n } = useTranslation();
  const currentlangStorage = localStorage.getItem("currentlang");
  const currentlang = i18n.language;
  const [langset, setLangset] = useState(false);
  const history = useHistory()
  const [startDate, setStartDate] = useState(new Date());

  const [nb_chambres, setNbChambre] = useState(0);
  const [nb_salle_bain, setNbBain] = useState(0);
  const [nb_wc, setNbWc] = useState(0);
  const [aproximite, setAproximite] = useState([{ 'label': 'Bus', 'selected': 0 }, { 'label': 'Métro', 'selected': 0 }, { 'label': 'PER', 'selected': 0 }, { 'label': 'Gar', 'selected': 0 }, { 'label': 'Commerces', 'selected': 0 }, { 'label': 'Accès plage', 'selected': 0 }]);
  const [animaux, setAnimaux] = useState(0);
  const [fumeur, setFumeur] = useState(0);
  const [image1, setImageName1] = useState("");
  const [image2, setImageName2] = useState("");
  const [image3, setImageName3] = useState("");
  const [image4, setImageName4] = useState("");
  const [image5, setImageName5] = useState("");
  const [image6, setImageName6] = useState("");
  const [equipements, setEquipement] = useState([
    { 'class': 't1', 'label': 'Wifi', 'selected': 0 },
    { 'class': 't2', 'label': 'Télévision', 'selected': 0 },
    { 'class': 't3', 'label': 'Climatisation', 'selected': 0 },
    { 'class': 't4', 'label': 'Chauffage', 'selected': 0 },
    { 'class': 't5', 'label': 'Cuisine', 'selected': 0 },
    { 'class': 't6', 'label': 'Lave vaisselle', 'selected': 0 },
    { 'class': 't7', 'label': 'Lave linge', 'selected': 0 },
    { 'class': 't8', 'label': 'Réfrigérateur', 'selected': 0 },
    { 'class': 't9', 'label': 'Micro ondes', 'selected': 0 },
    { 'class': 't10', 'label': 'Four', 'selected': 0 },
    { 'class': 't11', 'label': 'Cuisinière', 'selected': 0 },
    { 'class': 't12', 'label': 'Cafetière', 'selected': 0 },
    { 'class': 't13', 'label': 'Equipement cuisine', 'selected': 0 },
    { 'class': 't14', 'label': 'Equipement toilette', 'selected': 0 },
    { 'class': 't15', 'label': 'Sèche cheveux', 'selected': 0 },
    { 'class': 't16', 'label': 'Cintres', 'selected': 0 },
    { 'class': 't17', 'label': 'Draps', 'selected': 0 },
    { 'class': 't18', 'label': 'Fer à repasser', 'selected': 0 },
    { 'class': 't19', 'label': 'Détecteur de fumée', 'selected': 0 },
    { 'class': 't20', 'label': 'Accès PMR', 'selected': 0 },
  ]);
  const [exterieurs, setExterieurs] = useState([
    { 'class': 't21', 'label': 'Parking gratuit', 'selected': 0 },
    { 'class': 't22', 'label': 'Terrasse', 'selected': 0 },
    { 'class': 't23', 'label': 'Balcon', 'selected': 0 },
    { 'class': 't24', 'label': 'Jardin', 'selected': 0 },
    { 'class': 't25', 'label': 'Piscine', 'selected': 0 },
  ]);

  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [value, setValue] = useState(null);
  const markerRef = useRef(null);
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);

  const label = { inputProps: { 'aria-label': '' } };

  const defaultProps = {
    center: {
      lat: 48.8733089,
      lng: -0.4833178
    },
    zoom: 5
  };

  const handleChange = address => {
    setAddress(address);
  };

  const selectValuemap = (value) => {

    setAddress(value);
    setValue(value);
    geocodeByAddress(value)
      .then(results => {
        const localityComponent = results[0].address_components.find(component => component.types.includes("locality"));
  
        // Check if the locality component and its long_name are defined
        if (localityComponent && localityComponent.long_name) {
          const city = localityComponent.long_name;
          setCity(city);
        } else {
          // Handle the case where long_name is not defined
          console.error('Locality long_name not defined:', localityComponent);
        }
  
        getLatLng(results[0]).then(({ lat, lng }) => {
          setLat(lat);
          setLng(lng);
          renderMarkers(map, maps, lat, lng);
        });
      })
      .catch(error => {
        // Handle any geocoding errors here
        console.error('Geocoding error:', error);
      });
  };

  const renderMarkers = (map, maps, lat, lng) => {
    console.log("map object:", map);
    console.log("maps object:", maps);
    if (markerRef.current) {
      // If the marker already exists, update its position
      markerRef.current.setPosition({ lat, lng });
      if (map) {
        map.setCenter({ lat, lng });
      }
    } else {
      console.log('not map ref');
      setMap(map);
      setMaps(maps);
      // If the marker doesn't exist, create a new one
      markerRef.current = new maps.Marker({
        position: { lat, lng },
        map,
        draggable: false,
      });
      map.panTo({ lat, lng });
      if (map) {
        map.setCenter({ lat, lng });
      }

      // Add a listener for the marker's dragend event
      // maps.event.addListener(markerRef.current, 'dragend', function (event) {
      //   const newLat = event.latLng.lat();
      //   const newLng = event.latLng.lng();
      //   console.log('New Marker Position:', { lat: newLat, lng: newLng });
      //   // Handle the updated position as needed (e.g., setLat and setLng)
      // });
    }
  };

  const onChangeaddress = (text) => {
    console.log("jjjjjjjjj", text)
  }

  const renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions, loading }) => (
    <div className="autocomplete-root">
      <input {...getInputProps({
        placeholder: 'Adresse ...',
        className: 'form-control',
      })} />
      <div className="autocomplete-dropdown-container my-2">
        {loading && <div>Loading...</div>}
        {suggestions.map(suggestion => (

          <div className=" mb-2  listitem" {...getSuggestionItemProps(suggestion)}>
            <span >{suggestion.description}</span>
          </div>
        ))}
      </div>
    </div>
  );

  const returnFormAddLocation = () => {
    return (
      <div className='col-md-3  position_relative'></div>
    )
  }

  const handleChangeAproximite = (element) => {
    const data = aproximite
    if (data[element].selected == 0)
      data[element].selected = 1
    else
      data[element].selected = 0
    setAproximite(data)
    console.log("aproximite");
    console.log(aproximite);
  }

  const handleChangeAnimaux = () => {
    if (animaux == 0)
      setAnimaux(1)
    else
      setAnimaux(0)
  }
  const handleChangeFumeur = () => {
    if (fumeur == 0)
      setFumeur(1)
    else
      setFumeur(0)
  }

  const handelePlus = (element) => {
    if (element == 1) {
      const nb = nb_chambres + 1
      setNbChambre(nb)
    }
    else if (element == 2) {
      const nb = nb_salle_bain + 1
      setNbBain(nb)
    }
    else if (element == 3) {
      const nb = nb_wc + 1
      setNbWc(nb)
    }
  };
  const handeleMoins = (element) => {
    if (element == 1) {
      if (nb_chambres > 0) {
        const nb = nb_chambres - 1
        setNbChambre(nb)
      }
    }
    else if (element == 2) {
      if (nb_salle_bain > 0) {
        const nb = nb_salle_bain - 1
        setNbBain(nb)
      }
    }
    else if (element == 3) {
      if (nb_wc > 0) {
        const nb = nb_wc - 1
        setNbWc(nb)
      }
    }
  };

  const handleSubmit = (e) => {

    e.preventDefault();
    const form = document.getElementById('patrimoineForm');
    console.log('form.checkValidity(): ', form.checkValidity());
    if (form.checkValidity()) {
      const formData = new FormData(e.target)
      const formDataObj = Object.fromEntries(formData.entries())
      formDataObj.equipements = equipements
      formDataObj.exterieurs = exterieurs
      formDataObj.aproximite = aproximite
      formDataObj.animaux = animaux
      formDataObj.fumeur = fumeur
      formDataObj.user_id = user.id
      formDataObj.image1 = image1
      formDataObj.image2 = image2
      formDataObj.image3 = image3
      formDataObj.image4 = image4
      formDataObj.image5 = image5
      formDataObj.image6 = image6
      console.log('formDataObj');
      console.log(formDataObj);
  
      dispatch(saveBien(formDataObj)).then((res) => {
        console.log('res: ', res);
        if(res.success === 1) {
          Swal.fire({
            icon: 'success',
            title: "Success",
            text: res.message,
          });
          history.push('/client/patrimoine/')
        } else {
          Swal.fire({
            icon: 'error',
            title: "Error",
            text: res.message,
          });
        }
      })
    } else {
      alert('Please fill out all required fields and correct any errors.');
    }
  }

  const onChangeFile = (e) => {
    let files = e.target.files || e.dataTransfer.files;

    if (!files.length)
      return;

    if (files[0].type == 'image/png' || files[0].type == 'image/jpeg' || files[0].type == 'image/jpg' || files[0].type == 'image/gif') {
      if (files[0].size <= (2 * 1000000)) {
        createImage(files[0], e.target.name);
      }
      else {
        alert('Votre pièce-jointe ne doit  pas dépasser cette taille: 2 MO.');
      }
    }
    else {
      alert("Ce Format document n'est pas supporté ! Les formats acceptés sont (JPG,JPEG,PNG,GIF)");
    }
  }

  function createImage(file, tag_name) {
    // $('#divLoad').show()
    let reader = new FileReader();
    reader.onload = (e) => {
      fileUpload(e.target.result, tag_name);
    };
    reader.readAsDataURL(file);
  }
  function fileUpload(values, tag_name) {
    var self = this;
    axios.post(BASE_URL + '/insertimage', { 'file': values, 'type': 'images_bien' }, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
    }).then((res) => {
      if (tag_name == 'image1')
        setImageName1(res.data.name)
      else if (tag_name == 'image2')
        setImageName2(res.data.name)
      else if (tag_name == 'image3')
        setImageName3(res.data.name)
      else if (tag_name == 'image4')
        setImageName4(res.data.name)
      else if (tag_name == 'image5')
        setImageName5(res.data.name)
      else if (tag_name == 'image6')
        setImageName6(res.data.name)
      // this.setState({
      // 	[tag_name]: res.data.name
      // })

      // $('#divLoad').hide()
    }).catch((e) => {
    });
  }

  return (
    <div className="myccount editprofil abonnement addnewpatremoine " >
      <section className="gray-bg main-dashboard-sec dashboard myccount" id="sec1">
        <div className="container">
          <div className="col-md-3">
            <DashbordMenuClient src='patrimoine' />
          </div>
          <div className='col-md-9 dashbordassitantcontent' >
            <div className='tab' id=''>
              <DashbordHeaderClient src={'w'} />
              <div className='row' style={{ marginTop: '30px' }}>
                <div className='col-md-12'>
                  <form style={{ background: '#f6f6f8', boxShadow: 'none' }} className='custom-form' onSubmit={handleSubmit} id="patrimoineForm">
                    <input type="hidden" name="city" value={city} />
                    <div class="rowtitel">
                      <img src="/images/addpatrimoine.png" style={{ width: '55px' }} />
                      {/* <h3 class="htri1">Patrimoine -  <span style={{ color: '#4EC5CA' }}>Ajouter un bien en location</span> </h3> */}
                      <h3 class="htri1">Patrimoine -  <span style={{ color: '#4EC5CA' }}>Ajouter un bien en location</span> </h3>
                    </div>
                    <div className='section298'>
                      <div className="form-check">
                        <input name="option" type="radio" className="form-check-input ch667" value="1" required style={{ paddingLeft: '0px !important' }} />
                        <label title="" className="form-check-label">Avec option conciergerie</label>
                      </div>
                      <div className="form-check">
                        <input name="option" type="radio" className="form-check-input ch667" value="2" required  style={{ paddingLeft: '0px !important' }} />
                        <label title="" className="form-check-label">Avec option gestion</label>
                      </div>
                    </div>
                    <div className='section298 '>
                      <div className='section298frm row'>
                        <div className='col-md-7'>
                          <h4>Titre de l'annonce <span className="required-indicator">*</span></h4>
                          <input placeholder="Ex. : Appartement T3 vue dégagée" name="title" type="text" required class="form-control inptxt" required />
                        </div>
                        <input type="hidden" className='form-control' name='type_bien' value="1" required />
                        <div className='col-md-3'>
                          <h4>Durée de l'annonce <span className="required-indicator">*</span></h4>
                          <select className='form-control' name='duree' required>
                            <option value="">Sélectionnez</option>
                            <option value="1">1 mois</option>
                            <option value="2">2 mois</option>
                            <option value="3">3 mois</option>
                            <option value="4">Pas de limite de temps</option>
                          </select>
                        </div>
                      </div>
                      <div className='section298frm row'>
                        <div className='col-md-12'> <h4>Type de logement <span className="required-indicator">*</span></h4></div>
                        <div className='col-md-4'>
                          <select className='form-control' name='type_logement' required>
                            <option value="">Sélectionnez</option>
                            <option value="1">Maison</option>
                            <option value="2">Pavillon</option>
                            <option value="3">Gîte</option>
                            <option value="4">Appartement</option>
                            <option value="5">Appartement en résidence</option>
                            <option value="6">Loft</option>
                            <option value="7">Résidence de tourisme</option>
                            <option value="8">Chambre privée</option>
                            <option value="9">Logement de vacances</option>
                          </select>
                        </div>
                        <div className='col-md-4'>
                          <select className='form-control' name='nb_pieces' required>
                            <option value="">Nb de pièces</option>
                            <option value="1">1 pièce</option>
                            <option value="2">2 pièces</option>
                            <option value="3">3 pièces</option>
                            <option value="4">4 pièces</option>
                            <option value="5">5 pièces et +</option>
                          </select>
                        </div>
                        <div className='col-md-4'>
                          <input placeholder="surface m²" name="surface" type="number" class="form-control inptxt" />
                        </div>
                      </div>
                      <div className='section298frm row'>
                        <div className='col-md-3'>
                          <h4>Prix par nuit <span className="required-indicator">*</span></h4>
                          <div className='posrela'><input placeholder="0" name="prix_nuit" type="number" min="0" class="form-control inptxt" required /><span>Fcfa</span></div>
                        </div>
                        <div className='col-md-1'></div>
                      </div>
                      <div className='section298frm row'>
                        <div className='col-md-12'>
                          <h4>Location <span className="required-indicator">*</span></h4>
                        </div>
                        <div className='col-md-2'>
                          <h6>A partir du</h6>
                          <div className='posrela'><input style={{ paddingRight: 3 }} placeholder="Date" name="a_partir" type="date" class="form-control inptxt" required/></div>
                        </div>
                        <div className='col-md-2'>
                          <h6 style={{ fontSize: '11px' }}>Durée min. de séjour</h6>
                          <select className='form-control' name='min_sejour' required>
                            <option value="">Sélectionnez</option>
                            <option value="1">1 nuit</option>
                            <option value="2">2 nuits</option>
                            <option value="3">3 nuits</option>
                            <option value="4">4 nuits</option>
                            <option value="5">5 nuits</option>
                            <option value="6">6 nuits</option>
                            <option value="7">7 nuits</option>
                            <option value="8">8 nuits et +</option>
                          </select>
                        </div>
                        <div className='col-md-2'>
                          <h6>Capacité</h6>
                          <select className='form-control' name='capacite' required>
                            <option value="">Sélectionnez</option>
                            <option value="1">1 personne</option>
                            <option value="2">2 personnes</option>
                            <option value="3">3 personnes</option>
                            <option value="4">4 personnes</option>
                            <option value="5">5 personnes</option>
                            <option value="6">6 personnes</option>
                            <option value="7">7 personnes</option>
                            <option value="8">8 personnes et +</option>
                          </select>
                        </div>
                        <div className='col-md-2'>
                          <h6>Frais de ménage</h6>
                          <div className='posrela'><input placeholder="0" name="frais_menage" type="number" class="form-control inptxt" required /><span>Fcfa</span></div>
                        </div>
                        <div className='col-md-2'>
                          <h6>Frais de service</h6>
                          <div className='posrela'><input placeholder="0" name="frais_service" type="number" class="form-control inptxt" required /><span>Fcfa</span></div>
                        </div>
                        <div className='col-md-2'>
                          <h6>Taxes de séjour</h6>
                          <div className='posrela'><input placeholder="0" name="taxe_sejour" type="number" class="form-control inptxt" required /><span>Fcfa</span></div>
                        </div>
                      </div>
                      <div className='section298frm row'>
                        <div className='col-md-12'>
                          <h4>Présentation du bien</h4>
                          <textarea name="description" style={{ border: '1px solid #e5e7f2', height: '135px' }} class="form-control" row='3' placeholder='Ex. : Situé à 400 mètres de la plage, magnifique gîte avec piscine...'></textarea>
                          <div className='xtm00'>4000 caractères max.</div>
                        </div>
                      </div>
                      <div className='section298frm row'  >
                        <div className='col-md-12'>
                          <h4>Caractéristiques</h4>
                          <br />
                          <h7>GENERAL<span></span></h7>
                          <br />
                          <div className="col-md-2 ingrouped">
                            <h6>Nb de chambres</h6>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <button onClick={() => handeleMoins(1)} class="btn btn-outline-primary" type="button"   >-</button>
                              </div>
                              <input type="text" name="nb_chambres" class="form-control" value={nb_chambres} />
                              <div class="input-group-prepend">
                                <button onClick={() => handelePlus(1)} class="btn btn-outline-primary" type="button" >+</button>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2 ingrouped">
                            <h6>Salle de bain</h6>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <button onClick={() => handeleMoins(2)} class="btn btn-outline-primary" type="button"  >-</button>
                              </div>
                              <input type="text" name="nb_salle_bain" class="form-control" value={nb_salle_bain} />
                              <div class="input-group-prepend">
                                <button onClick={() => handelePlus(2)} class="btn btn-outline-primary" type="button" >+</button>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2 ingrouped">
                            <h6>WC</h6>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <button onClick={() => handeleMoins(3)} class="btn btn-outline-primary" type="button"  >-</button>
                              </div>
                              <input type="text" name="nb_wc" class="form-control" value={nb_wc} />
                              <div class="input-group-prepend">
                                <button onClick={() => handelePlus(3)} class="btn btn-outline-primary" type="button" >+</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='section298frm row' >
                        <div className='col-md-12'>
                          <h7>EQUIPEMENTS<span></span></h7>
                          <br />
                        </div>
                        <div className='col-md-12'>
                          <ul className='row'>
                            {
                              equipements.map((eqp, index) => (
                                <li className={'col-md-4 ' + eqp.class}><span>{eqp.label}</span><Switch {...label} size="small" color="secondary" /></li>
                              ))
                            }
                          </ul>
                        </div>
                      </div>
                      <div className='section298frm row' >
                        <div className='col-md-12'>
                          <h7>EXTERIEUR<span></span></h7>
                          <br />
                        </div>
                        <div className='col-md-4'>
                          <ul>
                            {
                              exterieurs.map((ext, index) => (
                                <li className={ext.class}><span>{ext.label}</span><Switch {...label} size="small" color="secondary" /></li>
                              ))
                            }
                          </ul>
                        </div>
                      </div>
                      <div class="section298frm row bgcap89">
                        <div class="col-md-12">
                          <h4>Photos</h4>
                        </div>
                        {
                          image1 != '' ?
                            <div className='col-6 col-md-2'><div className='img' style={{ background: "url(" + BASE_URL_PROD + '/img/biens/' + image1 + ")", backgroundSize: "cover" }}></div></div>
                            :
                            <div className='col-6 col-md-2'><div className='img'><input style={{ cursor: 'pointer' }} className="inputFileInvis" name="image1" type="file" onChange={onChangeFile} /></div></div>
                        }
                        {
                          image2 != '' ?
                            <div className='col-6 col-md-2'><div className='img' style={{ background: "url(" + BASE_URL_PROD + '/img/biens/' + image2 + ")", backgroundSize: "cover" }}></div></div>
                            :
                            <div className='col-6 col-md-2'><div className='img'><input style={{ cursor: 'pointer' }} className="inputFileInvis" name="image2" type="file" onChange={onChangeFile} /></div></div>
                        }
                        {
                          image3 != '' ?
                            <div className='col-6 col-md-2'><div className='img' style={{ background: "url(" + BASE_URL_PROD + '/img/biens/' + image3 + ")", backgroundSize: "cover" }}></div></div>
                            :
                            <div className='col-6 col-md-2'><div className='img'><input style={{ cursor: 'pointer' }} className="inputFileInvis" name="image3" type="file" onChange={onChangeFile} /></div></div>
                        }
                        {
                          image4 != '' ?
                            <div className='col-6 col-md-2'><div className='img' style={{ background: "url(" + BASE_URL_PROD + '/img/biens/' + image4 + ")", backgroundSize: "cover" }}></div></div>
                            :
                            <div className='col-6 col-md-2'><div className='img'><input style={{ cursor: 'pointer' }} className="inputFileInvis" name="image4" type="file" onChange={onChangeFile} /></div></div>
                        }
                        {
                          image5 != '' ?
                            <div className='col-6 col-md-2'><div className='img' style={{ background: "url(" + BASE_URL_PROD + '/img/biens/' + image5 + ")", backgroundSize: "cover" }}></div></div>
                            :
                            <div className='col-6 col-md-2'><div className='img'><input style={{ cursor: 'pointer' }} className="inputFileInvis" name="image5" type="file" onChange={onChangeFile} /></div></div>
                        }
                        {
                          image6 != '' ?
                            <div className='col-6 col-md-2'><div className='img' style={{ background: "url(" + BASE_URL_PROD + '/img/biens/' + image6 + ")", backgroundSize: "cover" }}></div></div>
                            :
                            <div className='col-6 col-md-2'><div className='img'><input style={{ cursor: 'pointer' }} className="inputFileInvis" name="image6" type="file" onChange={onChangeFile} /></div></div>
                        }
                      </div>
                      <div class="section298frm row">
                        <div class="col-md-12">
                          <h4>Localisation du bien</h4><br />
                          <h6>Où se situe votre bien ?</h6>
                        </div>
                        <div className='col-12 col-md-12'>
                          <div style={{ height: 200, width: '100%', overflow: 'hidden', borderRadius: '14px', position: 'relative' }}>
                            <div className='google_search'>
                              <div className='flx998'>
                                {/* <input placeholder="Renseignez votre adresse ou votre ville" name="" type="text" class="form-control inptxt" required />
                                <button class="btn float-btn color2-bg btn9876 btcustom " ><i className='fa fa-search'></i></button> */}
                                <Form.Group as={Col} >
                                  <Form.Label>Adresse</Form.Label>
                                  <Form.Control defaultValue={address} type="hidden" placeholder="Votre adresse" name="address" />
                                  <Form.Control defaultValue={lat} type="hidden" placeholder="Votre adresse" name="lat" />
                                  <Form.Control defaultValue={lng} type="hidden" placeholder="Votre adresse" name="lng" />
                                  <PlacesAutocomplete value={address} onChange={handleChange} onSelect={selectValuemap}>
                                    {renderFunc}
                                  </PlacesAutocomplete>
                                </Form.Group>
                              </div>
                            </div>
                            <GoogleMapReact
                              bootstrapURLKeys={{ key: "AIzaSyBTF7Lt3-kiG9UrWnl1R6SVsAev-1Y6aNc" }}
                              yesIWantToUseGoogleMapApiInternals
                              defaultCenter={defaultProps.center}
                              defaultZoom={defaultProps.zoom}
                              onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps, defaultProps.center.lat, defaultProps.center.lng)}
                            >
                            </GoogleMapReact>
                          </div>
                        </div>
                      </div>
                      <div class="section298frm row">
                        <div class="col-md-12">
                          <h4>A proximité</h4><br />
                        </div>
                        <div className='col-12 col-md-12'>
                          <ul className='row'>
                            {
                              aproximite.map((p, index) => (
                                <li className='col-md-4 isnonbg'><Switch {...label} size="small" color="secondary" onChange={() => handleChangeAproximite(index)} /> <span>{p.label}</span></li>
                              ))
                            }
                          </ul>
                        </div>
                      </div>
                      <div class="section298frm row">
                        <div class="col-md-12">
                          <h4>Règlement intérieur</h4><br />
                        </div>
                        <div className='col-6 col-md-3'>
                          <h6>Horaire d'arrivée</h6>
                          <select className='form-control' name='heurearrive'>
                            <option value="">Sélectionnez</option>
                            <option value="08:00">08:00</option>
                            <option value="09:00">09:00</option>
                            <option value="10:00">10:00</option>
                            <option value="11:00">11:00</option>
                            <option value="12:00">12:00</option>
                            <option value="13:00">13:00</option>
                            <option value="14:00">14:00</option>
                            <option value="15:00">15:00</option>
                          </select>
                        </div>
                        <div className='col-6 col-md-3'>
                          <h6>Horaire de départ</h6>
                          <select className='form-control' name='heuredepart'>
                            <option value="">Sélectionnez</option>
                            <option value="08:00">08:00</option>
                            <option value="09:00">09:00</option>
                            <option value="10:00">10:00</option>
                            <option value="11:00">11:00</option>
                            <option value="12:00">12:00</option>
                            <option value="13:00">13:00</option>
                            <option value="14:00">14:00</option>
                            <option value="15:00">15:00</option>
                          </select>
                        </div>
                        <div className='col-12 col-md-6'></div>
                        <div className='col-12 col-md-3'>
                          <ul>
                            <li className='isnonbg'><Switch {...label} onChange={() => handleChangeAnimaux()} size="small" color="secondary" /> <span>Animaux acceptés</span></li>
                            <li className='isnonbg'><Switch {...label} onChange={() => handleChangeFumeur()} size="small" color="secondary" /> <span>Fumeurs acceptés</span></li>
                          </ul>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-12'>
                          <div>
                            <button type="submit" class="btn float-btn color2-bg btn9876 btcustom " style={{ borderRadius: '13px' }}>Publier l'annonce</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
const AnyReactComponent = ({ text }) => <div></div>;
export default Page;
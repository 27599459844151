import React,{useEffect,useState} from "react";
import './styles.scss';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { IoMdTime } from 'react-icons/io';
import { BiCategoryAlt } from 'react-icons/bi';
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';
import { LOGOUT_USER } from "../../constants/actions";
import { useDispatch, useSelector } from "react-redux"
import { apiClient } from "../../actions/api"
import { BASE_URL_PROD } from "../../components/config/keys";

const Component = ({ src }) => {
    console.log(src);
    const history = useHistory();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const currentlang=i18n.language;
    const { user } = useSelector(state => state.user)
    const [nbMessage, setNbMessage] = useState(0);
    const [value, setValue] = useState(false);

       const [abbonements, setAbonnements] = useState({
                                                   'Particuliers':[
                                                   {'title':'Bronze','price':5,'description':'60Fcfa* de gains annuels (basé sur 2 interventions)','items':['Déplacement gratuit pour tous types d’intervention (2 interventions/an à intervalle de 2 mois)',"Jusqu'à 50% de gain sur les frais de déplacement"]},
                                                   {'title':'Silver','price':15,'description':'360Fcfa* de gains annuels (basé sur 2 interventions)','items':['Gratuité de toute intervention dans le logement dans la limite de 2 interventions simples et non invasives/an',"Conseil et accompagnement d'activité","Jusqu'à 100% de gain sur les frais de déplacement, de main d’œuvre et de fournitures"]},
                                                   {'title':'Gold','price':20,'description':'960Fcfa* de gains annuels (basé sur 4 interventions)','items':["Gratuité de toutes interventions et dépannage express H24","Limite de 4 interventions/an**","Service de conseil juridique illimité","Aide à la déclaration fiscale et transmission de documents administratifs","Service d'accompagnement comptable, fiscal et patrimonial","Export de donnée CSV (Excel)","Jusqu'à 100% de gain sur les frais de déplacement, de main d’œuvre et de fournitures"],'pop':1},
                                                   {'title':'Platinium','price':30,'description':'Gain illimité sur les interventions urgentes','items':["Gratuité de toutes interventions urgente en illimité","Déplacement gratuit","Conseiller dédié","Recherche personnalisée","Assistance travaux et juridique","Intervention assainissement en partie privative","Double de clef standard","Jusqu'à 100% de gain sur les frais de déplacement, de main d’œuvre et de fournitures"]}
                                                   ],
                                                   'Professionnels':[
                                                   {'title':'Classic','price':0,'description':'','items':["10Fcfa par intervention < à 150Fcfa","20% commission intervention > à 150Fcfa","25% commission intervention > à 1000Fcfa","Accès aux outils de gestion devis, factures et messages sauf fournitures et stocks"]},
                                                   {'title':'Basic','price':5,'description':'','items':["1Fcfa par intervention < à 150Fcfa","10% de commission par intervention > à 150Fcfa","Accès aux outils de gestion devis, factures et messages sauf fournitures et stocks","Service d'assurance juridique limité","Jusqu'à 100% de gain sur les frais de déplacement, de main d’œuvre et de fournitures"],'pop':1},
                                                   {'title':'Premium','price':20,'description':'','items':["0Fcfa de commission si plus de 5 interventions par mois","Accès aux outils de gestion devis, factures, messages, fournitures et stocks","Service de conseil juridique illimité","Visibilité auprès des autres utilisateurs","Aide à la déclaration fiscale et transmission de documents administratifs","Service d'assurance juridique","Service d'accompagnement comptable, fiscal et patrimonial","Export de donnée CSV (Excel)"]}
                                                   ],
                                                   'Gestionnaires':[
                                                   {'title':'Particulier','price':0,'description':'Sans engagement (valable 1 mois)','items':["*5Fcfa par jour de réservation sur chaque réservation de logement en location hors frais bancaires et frais de ménage","Mise en vente du bien gratuite","Accès à l'abonnement dépannage","Réservation du logement saisonnier ou courte durée"]},
                                                   {'title':'Pro +','price':5,'description':'','items':["1% de commission sur chaque paiement de loyer par bien","Publication illimitée de biens","Accès à tous les outils de gestion","Dépannage sur devis"]},
                                                   {'title':'Pro','price':0,'description':'','items':["3% de commission par mois sur chaque paiement de loyer mensuel","Publication limitée à 1 bien","Accès aux outils de gestion sauf message et historique des transactions","Dépannage sur devis"]}
                                                   ]

                                                   }
                                                   );


 const handleClick = (slug) => {
         history.push(slug)
  }


  
   const handleClickMenu = () =>{
        setValue(!value)
    }

    const handleLogout = () =>{
        dispatch({type:LOGOUT_USER});
        setTimeout(() => {
            window.location.href='/'
        }, 300);
    }

 useEffect(() => {

        if (!user || !user.id || user.type!== 2) {
              history.push('/')
        }

 

}, [user])
  

 const returnAboonement = (t) => {
     let listabonnement= t=='Particuliers' ? abbonements['Particuliers'] : t=='Professionnels' ? abbonements['Professionnels'] : t=='Gestionnaires' ? abbonements['Gestionnaires'] : []
      
     return (
        <div>

        {
             listabonnement?.map((item,index) => (
           
           (user?.abonnement_type==index  ) && <span>{item?.title}</span>

        ))
        }

       
        </div>
    )
     

    
}

    return (

       <div className='menudashbord'>
             <div onClick={()=>handleClickMenu()} className="mob-nav-content-btn color2-bg init-dsmen fl-wrap "><i className="fal fa-bars" style={{marginLeft:'10px'}}></i> Dashboard menu</div>
                                <div className="clearfix"></div>
                                <div className="fixed-bar fl-wrap" id="dash_menu">
                                    <div className={value==true ? "user-profile-menu-wrap fl-wrap block_box dispblock": "user-profile-menu-wrap fl-wrap block_box dispnone" } style={{paddingBottom: '0px'}}>
                                       
                                        <div className="user-profile-menu">
                                        <div className='logomenu' onClick={()=>handleClick("/")}>
                                          <img src='/images/LogoProximiti.png' />
                                        </div>
                                          
                                            <ul className="no-list-style">
                                                <li><a onClick={()=>handleClick("/client/dashboard")} href="#" className={src=='profil' && "user-profile-act"}><img src='/images/icones/iconemenudashboard.png' />Tableau de bord</a></li>
                                                <li><a href="#" onClick={()=>handleClick("/client/videos/")} className={src=='formations' ? " user-profile-act bg_img2":'bg_img2'}>
                                                    {/* <img src='/images/icones/video.png' /> */}
                                                    Consultation Vidéo</a>
                                                </li>
                                                <li><a  onClick={()=>handleClick("/client/historiques/")} href="#"  className={src=='historique' ? " user-profile-act bg_img3":'bg_img3'}>
                                                    {/* <img src='/images/icones/iconehistorique.png' /> */}
                                                    Historique</a></li>
                                                <li><a onClick={()=>handleClick("/client/agenda/")} href="#"  className={src=='agenda' ? " user-profile-act bg_img4":'bg_img4'}>
                                                    {/* <img src='/images/icones/iconesRDV.png' /> */}
                                                    Mes rendez-vous</a></li>
                                                <li><a onClick={()=>handleClick("/client/contrat-bail/")} href="#" className={src=='contrat-bail' ? " user-profile-act bg_img5":'bg_img5'}>
                                                    {/* <img src='/images/icones/iconedocuments.png' /> */}
                                                    Contrat de Bail</a></li>
                                                <li style={{display:'none'}}><a onClick={()=>handleClick("/client/etat-lieu/")} href="#" className={src=='etat-lieu' ? " user-profile-act bg_img6":'bg_img6'} >
                                                    {/* <img src='/images/icones/checklist.png' /> */}
                                                    Etat des Lieux</a></li>
                                                   <li><a onClick={()=>handleClick("/client/gestion-des-astreintes/")} href="#" className={src=='gestion-des-astreintes' ? " user-profile-act bg_img6":'bg_img6'}>
                                                    {/* <img src='/images/icones/gestioncontrat.png' /> */}
                                                    Gestion des astreintes</a></li>
                                                <li><a onClick={()=>handleClick("/client/charges/")} href="#" className={src=='charges' ? " user-profile-act bg_img7":'bg_img7'} >
                                                    {/* <img src='/images/icones/iconetarifs.png' /> */}
                                                    Charges</a></li>
                                                <li><a onClick={()=>handleClick("/client/patrimoine/")} href="#" className={src=='patrimoine' ? " user-profile-act bg_img8":'bg_img8'}>
                                                    {/* <img src='/images/icones/gestioncontrat.png' /> */}
                                                    Patrimoine</a></li>
                                             
                                                <li><a onClick={()=>handleClick("/client/interventions/")} href="#" className={src=='interventions' ? " user-profile-act bg_img9":'bg_img9'} >
                                                    
                                                    Interventions</a></li>

                                                  <li><a onClick={()=>handleClick("/client/abonnement/")} href="#" className={src=='abonnement' ? " user-profile-act bg_img10":'bg_img10'} >
                                                    
                                                    Abonnement</a></li>   

                                                {/* <li><a onClick={()=>handleClick("/client/devis/")} href="#" className={src=='editprofil' && "user-profile-act"} ><img src='/images/icones/gestioncontrat.png' />Gestion des contrats</a></li>
                                                <li><a href="#" className={src=='reclamations' && "user-profile-act"} ><img src='/images/icones/iconedocuments.png' />Mes documents</a></li>
                                                <li><a onClick={()=>handleClick("/client/modifier-services/")} href="#" className={src=='reclamations' && "user-profile-act"} ><img src='/images/icones/iconetarifs.png' />Tarification</a></li>
                                                <li><a href="#" className={src=='reclamations' && "user-profile-act"} ><img src='/images/icones/iconeformations.png' />Formations</a></li>
                                                <li><a  onClick={()=>handleClick("/client/abonnement/")} href="#"  className={src=='abonnement' && "user-profile-act"} ><img src='/images/icones/iconeabonner.png' />Abonnement</a></li>
                                                <li><a href="#" className={src=='reclamations' && "user-profile-act"} ><img src='/images/icones/iconechantier.png' />Chantiers</a></li> */}
                                            </ul>


                                            <div className='abonnement'>
                                                  
                                                   <div className='imgprofil'>
                                    
                                                        {
                                                            user.image != '' && user.image != null ?
                                                            <img className="profilImg" src={BASE_URL_PROD+'/admin/public/img/professionnal/'+user.id+'/'+user.image} />
                                                            :
                                                            <img src='/images/icones/iconeprofilproblocabonnementmenu.png' />
                                                        }
                                                        <div style={{marginTop:'3px'}}>Formule actuelle</div>
                                                            <strong style={{    display: 'block',marginTop:'3px'}} >
                                                            {
                                                                user?.abonnement_categorie ?
                                                                  user?.abonnement_categorie
                                                                :
                                                                'Sans abonnement'
                                                            }
                                                              
                                                        </strong>
                                                        <p style={{marginBottom:'3px'}}>

                                                        {

                                                            user?.abonnement_categorie &&

                                                            returnAboonement(user?.abonnement_categorie)

                                                        }
                                                            
                                                        </p>
                                                     <div className='flx881 ' style={{marginBottom:'0px'}}>
                                                                 <button  onClick={()=>handleClick("/client/abonnement/")} className="btn float-btn color2-bg btn9876 btn9876_1 w100">Modifier mon Aonnement</button>
                                                           </div>
                                                     </div>

                                            </div>
                                            <div className='logout'>
                                               <a  onClick={handleLogout}  ><img src='/images/icones/iconedeconnecter.png' />Se déconnecter </a>
                                            </div>

                                           
                                        </div>
                                                                        
                                        
                                    </div>
            </div>
        </div>
    );
}

export default Component;
import React, { useEffect,useState } from "react";
import './styles.scss'; 
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal } from "react-bootstrap"
import { useParams } from "react-router";
import { useHistory } from "react-router";
import {getInterventions, getQuestions, getElements } from "../../../actions/pages";
 
 

const Page = () => { 
   const { metier_id } = useParams();
    const { keyword:gottenKeyword } = useParams();
    const [keyword, setkeyword] = useState(gottenKeyword)
    const history = useHistory()
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
     const [active, setActive] = useState(0);
    const [active2, setActive2] = useState(0);
    const [active2Value, setActive2Value] = useState("");
    const [activeElement, setActiveElement] = useState(0);
    const [activeElementValue, setActiveElementValue] = useState("");

    const handleClose = () => setShow(false);
    const [problemeDescription, setProblemeDescription] = useState('');
    const [elements_metiers, setElementMetiers] = useState('');
    const [all_elements_metiers, setAllElementMetiers] = useState([]);
    const [elemLevel, setLevel] = useState(0);
 
    const { user } = useSelector(state => state.user)
    const { interventions } = useSelector(state => state.pages)
    const { questions } = useSelector(state => state.pages)
    const [langset, setLangset] = useState(false);

    const handleProblemeDescription = description => {
      setProblemeDescription(description.target.value)
   }
  

     const handleActive = (s) => {
      setActiveElement(0)
      setAllElementMetiers([]);
      setLevel(0)
      setActive(s)

      dispatch(getElements(2, s)).then((res) => {
        var data_elem  = [];
        data_elem[elemLevel] = res
        var newelemLevel = elemLevel+1
        setLevel(newelemLevel)
        setAllElementMetiers(data_elem);
      })
      // dispatch(getQuestions(s));
    }
 
    const handleActive2 = (s, ind) => {
      setActiveElement(0)
      var data_elem  = [];
      for (let index = 0; index < all_elements_metiers.length; index++) {
        if(all_elements_metiers[index] != undefined && all_elements_metiers[index].data.length > 0){
          for (let h = 0; h < all_elements_metiers[index].data.length; h++) {
            if(all_elements_metiers[index].data[h].id == s){
              all_elements_metiers[index].data[h].isActivited = 1
            }else{
              all_elements_metiers[index].data[h].isActivited = 0
            }
          }
        }

        if(index <= ind){
          data_elem.push(all_elements_metiers[index])
        }

      }
      
      var newelemLevel = ind+1
      setLevel(newelemLevel)

      setAllElementMetiers([]);
       setActive2(s)
       
       dispatch(getElements(2, s)).then((res) => {
        //  var data_elem  = all_elements_metiers;
         if(res.data.length == 0)
            setActiveElement(s)
        else{

          data_elem[elemLevel] = res
          var newelemLevel = elemLevel+1
          setLevel(newelemLevel)
        }
          console.log("data_elem");
          console.log(data_elem);
         setAllElementMetiers(data_elem);
       })
    }
   

   const handleGoTo = () => {
          localStorage.setItem('intervention_type', active)
          localStorage.setItem('fuite', activeElement)
          localStorage.setItem('intervention_typeValue', active2Value)
          localStorage.setItem('fuiteValue', activeElementValue)
          localStorage.setItem('problemeDescription', problemeDescription)


          history.push('/ajouter-des-photos#');
        
    }
 
    const handlereturn = () => {
    
          history.push('/quel-est-votre-besoin-1/'+localStorage.getItem('type')+'-'+localStorage.getItem('service_id'));
        
    }
   
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())

    }


    const componentClicked =  () => {
        
       
    }

    
 
 

     useEffect(() => {
      dispatch(getInterventions(metier_id));


      dispatch(getElements(1, metier_id)).then((res) => {

        setElementMetiers(res);
      })

        if(langset == false){
            setLangset(true)
        }
 
 

    }, [])
   
    return (
        <div className="besoin  " >
           <section className="gray-bg big-padding " id="besoin2">

             <div className="container small-container ">
             <form className='registerform'>
               
               <div className='row '>
                <div className='col-md-12'>
                   <div className='progressbare'>
                     <div className='veh' style={{ left: '50%'}}><img src='/images/Groupe6849.png'/></div>
                     <div className="progressvalue" style={{width:'55%'}}></div>
                     <div className='bat' ><img src='/images/Groupe6903.png'/></div>
                   </div>
                </div>
                </div>

                   
                <div className='row '>
                <div className='col-md-12'>
                    
                 
                      
                    <div className='col-md-9'>


                     <div className="section-title"><h2><span>
                      {
                        elements_metiers.element_title != undefined ?
                        elements_metiers.element_title
                        :
                        "" 
                      } 
                      </span></h2></div>

                      <ul className='liser'>
                      
                      {
                      elements_metiers.data != undefined && elements_metiers.data.length > 0 ?
                      elements_metiers.data.map((item) => (
                            <li  onClick={()=>{
                              handleActive(item.id)
                            setActive2Value(item.label);
                            }} className={active == item.id && 'active'}>{item.label}</li>
                          ))
                        :
                        ""
                        }
                      </ul>

                    <div className='mrg78'></div>
                    <div style={{minHeight: 327}}>
                        {
                          all_elements_metiers.length > 0 ?
                          all_elements_metiers.map((items, index) => (
                            items != undefined && items.data.length > 0
                            ?
                            <div >
                              <div className="section-title"><h2><span>{items.element_title}</span></h2></div>
                              <ul className='liser'>
                              
                                {
                                  items != undefined && items.data.length > 0 ?
                                  items.data.map((item) => (
                                    <li onClick={()=>{
                                      handleActive2(item.id, index);
                                    setActiveElementValue(item.label);
                                    }}
                                     className={activeElement == item.id  || item.isActivited == 1 ? 'active':''}>{item.label}</li>
                                  ))
                                  :
                                  ''
                                }
                              
                              </ul>
                            </div>
                            :
                            ""
                           ))
                          :
                          ""
                        }
                      </div>

<br/>
<div className="log-separator fl-wrap center"><span>OU</span></div>
<br/><br/><br/>

<div className="section-title"><h3><span>Décrivez votre problème </span></h3></div>
<div className='custom-form'>
<textarea  onChange={handleProblemeDescription} placeholder='ex. : problème de fuite sur robinet cuisine eau froide' >

</textarea>
<p className='para'>300 caractères max.</p>
</div>

                      <div style={{textAlign:'left',marginBottom:'30px',color:'#73777b'}}>
                        <a href='#' onClick={()=>handleGoTo()} type="button" class="btn btnform w550 "> Valider </a>
                      </div>

                      <br/><br/>

                       <div style={{textAlign:'left',marginBottom:'30px',color:'#73777b'}}>
                        <a href='#' onClick={()=>handlereturn()} class="btn btnform w200 btngreen"> {'<<'} Etape précédente </a>
                      </div>

                       </div>

                        <div className='col-md-3 center'>
                         
                        </div>
                


                </div>

               

               </div>

             </form>
               

             </div>



             <div className='sect2'>
              <div className="container small-container">
               <div className='row'>
                  <div className='col-md-12'>
                      <div className="section-title"><h3><span>Une interrogation sur le problème à résoudre ?</span></h3>
                      <br/>
                      <p className="" style={{    maxWidth: '100%'}}>Vous pourrez échanger directement avec nos artisans pour préciser votre problème avant votre rendez-vous. Nous sommes à votre disposition pour vous aider n'hésitez pas à nous contacter au <strong>06 65 66 05 65</strong> ou <strong>consultez notre FAQ.</strong></p></div>
 
                  </div>
               </div>
              </div>
             </div>
 <br/><br/> <br/><br/><br/>
              <div className='sect3'>
              <div className="container small-container ">
 
                      <div className='cadrbs1'>

                                  <div className="process-wrap fl-wrap commentcafonctionne "><div className="default-desc margright mrgtop"><p>&nbsp;</p>
                                    <ul className="no-list-style row">



                                    <li className="col-md-4">
                                    <div className="process-item">
                                    <div className="process-img">
                                     <img src='/images/Iconeartisan.png' />
                                    </div>

                                    <p className='basecolor'>Nos artisans sont certifiés et labellisés.</p>
                                    </div>
                                    </li>

                                    <li className="col-md-4">
                                    <div className="process-item">
                                    <div className="process-img">
                                     <img src='/images/iconechrono.png' />
                                    </div>

                                    <p className='basecolor'>Traitement rapide des demandes.</p>
                                    </div>
                                    </li>

                                    <li className="col-md-4">
                                    <div className="process-item">
                                    <div className="process-img">
                                     <img src='/images/IconeIA.png' />
                                    </div>

                                    <p className='basecolor'>Un algorithme de matching client-dépanneur en fonction de la proximité et de l’incident.</p>
                                    </div>
                                    </li>

                                    </ul>
                                    </div>
                             </div>

                      </div>
 
                  </div>
             
             </div>



           </section>

        </div>
    );
}

export default Page;
import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card,Modal} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { Dashbord,DashbordHeader } from "../../../components";
import { useTranslation } from 'react-i18next';
import { getDetailMission } from "../../../actions/pages";
import { useParams } from "react-router";
import { apiClient } from "../../../actions/api"
import { BASE_URL } from "../../../components/config/keys";
import {
        Elements,
        CardElement,
        useStripe,
        useElements,
      } from "@stripe/react-stripe-js";
      import { loadStripe } from "@stripe/stripe-js";


const Page = () => {
    
    const stripe = loadStripe("pk_live_51K0tmRGpcXMYDbWtXmoE7yXXfpBUcBdR2GbcCLnbNDJtvOBenOCAKUMIdZfq6CLRfXcfYlw8xhI5h79by8hIbmyr000aUylyOX");
    const dispatch = useDispatch();
    const { slug,slug1 } = useParams()
    const { user } = useSelector(state => state.user)
    const { detailmission } = useSelector(state => state.pages)
    

    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);

    const [fondation, setFondation] = useState(0);

     const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
  
    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
    const handleSubmit = async (e) => {
        e.preventDefault();
        
      
    }

  const handlerFondation =  () => {
    
        if(fondation==0){
           setFondation(1)
        }
        else{
           setFondation(0)
        }
        
      
    }

   const returnReviews= (note) => {
          
         return(

        
            note==1 ?
             <div><i class="fas fa-star"></i></div>
             : note == 2 ?
             <div><i class="fas fa-star"></i><i class="fas fa-star"></i></div>
             : note == 3 ?
             <div><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></div>
             : note == 4 ?
             <div><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></div>
             : note == 5 ?
             <div><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></div>
             :
             <div></div>

          
       )
    }




     useEffect(() => {
        const params={
           id:user.id,
           token:user.emailToken,
           idwatcher:slug,
           id_mission:slug1,
           type:'user'
        }

        dispatch(getDetailMission(params));
       
        if(langset == false){
            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }

    }, [])
    


        const savecommande=(payement_inten_id)=>{
           
            const params = {
                 
                payement_inten_id:payement_inten_id,
                id:user.id,
                total:detailmission.price,
                token:user.emailToken,
                idwatcher:slug,
                id_mission:slug1,
                fondation:fondation,
                 
              };
              
              apiClient().post(`/mission/paiement`,params)
              .then((res) => {
                 if(res.data.success==1){

                     setShow(true)
                     setTimeout(function(){
                        window.location.href='/my-account/'
                     }, 3000);

                 }

                 else{
                    alert(t('Une erreur est survenue veuillez réessayer ultérieurement.'))
                 }
                
                 
              })
          
        }
 
    return (
        <div className="myccount paiments " >
              <DashbordHeader src={'u'} />

            <section className="gray-bg main-dashboard-sec" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <Dashbord />
                                 
                            </div>


                         <div className='col-md-9'>

                             <div className='tab' id=''>
                                 <div class="dashboard-title   fl-wrap">
                                    <h3 className='htri1'>{t('Paiement par carte bancaire')} </h3>
                                    <br/>

                                    <p className='alert alert-warning'>
                                         {t('La somme ne vous sera débitée qu’après réalisation de la tâche, une facture vous sera alors envoyée dans votre boite.')}  
                                    </p>

                                    <div className='row'>
                                        
                                        <div className='col-md-6'>
 

                                 <Elements stripe={stripe}>
                                 <CheckoutForm  BASE_URL={BASE_URL} savecommande={savecommande} user={user} total={detailmission.price + fondation}  />
                                </Elements>

                                <br/>

                                <div className='fondation'>


                                  <h6 style={{color:'#384f95'}}>{t("Notre fondation soutient l'essort des économies émergeantes")}</h6>
                                 
                                <p style={{fontSize:'13px'}}>{t('Rejoingnez nous pour soutenir les projets dans les économies émergentes')}</p>
                               
                                <div className="filter-tags"><label for="check-a3"><input style={{marginRight:'10px'}} id="check-a3" type="checkbox" name="check" onClick={handlerFondation}/>{t('Donnez 1 euro pour soutenir les projets dans les économies émergentes.')}</label></div>
                                             


                                </div>

                                <div className='center'>
                                              <img src='/images/stripe.png' />
                                             </div>

                                        </div>

                                        <div className='col-md-6'>


                                           <div className='fl-wrap lws_mobile tabs-act block_box vishidelem'>
                                              <div className='rapelasst'>
                                                <div className='center p33' style={{float: "right"}}>
                                                    <div className='ps889' style={{backgroundImage:`url(${detailmission.photo })`}}>

                                                     </div>
                                                 </div>
                                                 
                                                 <strong style={{textTransform:"capitalize"}}>{detailmission.prenom} <span>{detailmission.nom}</span>.  {detailmission.intern== 1 && <span className="verified-badge"><i className="fal fa-check"></i></span> }</strong>
                                                  <p style={{fontSize:"12px"}}>{detailmission.ville}</p>

                                                  
                                                  <div className='listing-rating card-popup-rainingvis'>
                                                 { detailmission.evaluation &&  returnReviews(detailmission.evaluation.note)}
                                                        <div className="card-popup-rainingvis_bg"><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><div></div></div>
                                             
                                                   </div>

                                              </div>

                                              <div className='rapelasstbtm '>

                                                <div className='group'>
                                                {
                                                  detailmission.mission &&

                                                  <label><i class="far fa-bookmark"></i> {detailmission.mission.ville}</label>
                                                }
                                                      
                                                    
                                                    
                                                </div>

                                                <div className='group'>
                                                      {
                                                        detailmission.mission &&
                                                        <label><i class="fal fa-calendar"></i> {detailmission.mission.date_mission}  </label>
                                                      }
                                                    
                                                    
                                                </div>

                 
                                                  <div className='group'>
                                                      

                                                    <label>
                                                    <i class="fas fa-euro-sign"></i>

                                                  {
                                              
                                                         detailmission.price > detailmission.prixmission ?
                                                              <span className=""> <span className='libebaree'>Fcfa{detailmission.prixmission}</span> <strong>Fcfa{detailmission.price}</strong></span>
                                                         :

                                                            <span className=""> Fcfa{detailmission.prixmission}</span>
       
                                                }

                  

                                                       </label>
                                                    
                                                </div>


 

                                              </div>
                                           </div>



                                        </div>


                                    </div>

                                     



                                
                                 </div>
                              </div>
 

                              

                         </div>
                    </div>
            </section>


<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body>

          <div className='center'>
            <img src='/images/validation-150x150.png' />
          </div>

          <br/><br/>
            <div className='center'><h4 className='center'>{t('Félicitations')} </h4></div>
           <br/>
          <div className='center'>
             {t('Votre paiement a bien été pris en compte.')}
          </div>

        </Modal.Body>
        <Modal.Footer>
          
         
        </Modal.Footer>
</Modal>

        </div>
    );
}

export default Page;
const CheckoutForm = ({savecommande,total,user,BASE_URL}) => {
    const [isPaymentLoading, setPaymentLoading] = useState(false);
    
    const stripe = useStripe();
    const elements = useElements();


     const getSetupClientSecret=()=>{

        const params = {
          total: total ,
          customer_id: user.id
        }
        return fetch(BASE_URL+'/payment/stripe', {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          method: 'post',
          body: JSON.stringify(params)
        }).then(response => response.json())
          .catch(error => {
            console.log("stripe_get_secret_setup_intents error", error)
          });
      } 


    const payMoney = async (e) => {
      e.preventDefault();
      
      
      if (!stripe || !elements) {
        return;
      }


      setPaymentLoading(true);
      
      const info = await getSetupClientSecret();
      const clientSecret = info.stripe.client_secret;
      const paymentResult = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: user.prenom+ +user.nom,
          },
        },
      });
      setPaymentLoading(false);
      console.log(paymentResult);
      if (paymentResult.error) {
        alert(paymentResult.error.message);
      } else {
         if (paymentResult.paymentIntent.status == "requires_capture") {
             console.log(paymentResult.paymentIntent.id);
            savecommande(paymentResult.paymentIntent.id);
        }
      }
    };


 

    return (
      <div>
        <div
          style={{
            maxWidth: "500px",
            margin: "0 auto",
          }}
        >
          <form
            style={{
              display: "block",
              width: "100%",
            }}

            onSubmit = {payMoney}
           
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CardElement
                className="card"
                options={{
                  style: {
                    base: {
                      backgroundColor: "white"
                    } 
                  },
                }}
              />
              <button
                className="pay-button"
                disabled={isPaymentLoading}
              >
                {isPaymentLoading ? "Loading..." : "Payer "+total+"Fcfa"}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
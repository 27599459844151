import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDotR5TPudzeJk3L0NZa6DeJR4naCg2CZY",
  authDomain: "proximiticicom.firebaseapp.com",
  projectId: "proximiticicom",
  storageBucket: "proximiticicom.appspot.com",
  messagingSenderId: "635313696132",
  appId: "1:635313696132:web:ff50a57897eee67c3dc1d1",
  measurementId: "G-8YLWR45PXP"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export default app;
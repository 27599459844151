import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button ,Modal,CloseButton } from "react-bootstrap"
import { AssistantItem } from "../../../../components";
import { AvisItem } from "../../../../components";
import { getListWatcher } from "../../../../actions/user";
import { useHistory } from "react-router";
import { BASE_URL_PROD } from "../../../../components/config/keys";
import { surpls,epr } from "../../../../assets/images";
import { useTranslation } from 'react-i18next';
 

const Page = () => {
    const autre_services_data = JSON.parse(localStorage.getItem('autre_services_data'));
    const services = []
    services[1] = [
      {'id':1,'service':'Shampoing', 'duree':'10 min.', 'prix':'4.00'},
      {'id':2,'service':'Soin', 'duree':'10 min.', 'prix':'10.00'},
      {'id':3,'service':'Coupe de cheveux courts et mi-longs', 'duree':'30 min.', 'prix':'32.90'},
      {'id':4,'service':'Coupe cheveux longs', 'duree':'45 min.', 'prix':'42.90'},
      {'id':5,'service':'Brushing sur cheveux courts et mi-longs', 'duree':'20 min.', 'prix':'32.90'},
      {'id':6,'service':'Brushing sur cheveux longs', 'duree':'40 min.', 'prix':'42.90'},
    ]
    services[2] = [
      {'id':7,'service':'Shampoing', 'duree':'10 min.', 'prix':'4.00'},
      {'id':8,'service':'Coupe Homme', 'duree':'30 min.', 'prix':'30.00'},
      {'id':9,'service':'Couleur Complète', 'duree':'45 min.', 'prix':'60.00'},
      {'id':10,'service':'Taille de la barbe', 'duree':'15 min.', 'prix':'20.00'},
    ]
    services[3] = [
      {'id':11,'service':'Coupe enfant', 'duree':'20 min.', 'prix':'18.00'},
      {'id':12,'service':'Coupe enfant (tresse, chignon...)', 'duree':'30 min.', 'prix':'40.00'},
    ]
    const dispatch = useDispatch();
    const history = useHistory()
    const { t, i18n } = useTranslation();
    const [genre, setGenre] = useState(autre_services_data.genre);
    const [genreText, setGenreText] = useState(autre_services_data.genreText);
    const [qte, setQte] = useState(autre_services_data.qte);
    const [categorie, setcCategorie] = useState(autre_services_data.categorie);
    const [categorieText, setcCategorieText] = useState(autre_services_data.categorieText);
    const [catServices, setcCategorieServices] = useState([]);
    const [selectedService, setSelectedService] = useState([{id:'', price:''}]);
    const [categorieError, setCategorieError] = useState(0);
    const [serviceError, setServiceError] = useState(0);
    useEffect(() => {

    }, [])
  
    const handleGoBack = () => {
      history.push('/coiffure/pour-qui');
    };

    const handleGoTo = () => {
      setCategorieError(0)
      setServiceError(0)
      if(categorie == '' || categorie == undefined){
        setCategorieError(1)
      }else if(selectedService.length == 0){
        setServiceError(1)
      }else{
        autre_services_data.categorie = categorie
        autre_services_data.categorieText = categorieText
        autre_services_data.selectedService = selectedService
        localStorage.setItem('autre_services_data', JSON.stringify(autre_services_data))
        history.push('/coiffure/date');
      }
    };
    
    const handleCategorie = element => {
      setCategorieError(0)
      setcCategorie(element);
      setcCategorieServices(services[element]);

      if(element == 1) setcCategorieText('Coupe & Coiffage')
      if(element == 2) setcCategorieText('Coloration & Balayage')
      if(element == 3) setcCategorieText('Coiffure spéciale')
    };
    const handleAddService = (element, price) => {

      setServiceError(0)
      const list = [...selectedService];
      
      var finded = 0
      var ar2 = []
      for (let index = 0; index < list.length; index++) {
        const item = list[index];
        if(parseInt(item.id) === parseInt(element))
          finded = 1
        else
          ar2.push(item)
      }

      if(finded == 0){
        ar2.push({id:element, price:price})
      }
      setSelectedService(ar2)
    };
    return (
      <div className="coiffure3" >
        <section className="gray-bg big-padding " id="besoin1">
          <div className="container small-container ">
            <div className='registerform'>
              <div className='row '>
                <div className='col-md-8'><p onClick={()=>handleGoBack()} style={{ color: "rgb(7, 52, 96)", cursor:'pointer' }}>&lt;	&lt;	Retour</p></div>
                <div className='col-md-8'>
                  <div className="section-title"><h2><span>Sélectionnez votre service </span></h2>
                  </div>
                  <div className='custom-form col-md-4' style={{ paddingLeft:"0" }}>
                    <div onClick={() => handleCategorie(1)} className={categorie == 1 ? "boxShadow coiffurebox selected":"boxShadow coiffurebox"}>
                      <p>Coupe <br/>& Coiffage</p>
                    </div>
                  </div>
                  <div className='custom-form col-md-4' style={{ paddingLeft:"0" }}>
                    <div onClick={() => handleCategorie(2)} className={categorie == 2 ? "boxShadow coiffurebox selected":"boxShadow coiffurebox"}>
                      <p>Coloration <br/>& Balayage</p>
                    </div>
                  </div>
                  <div className='custom-form col-md-4' style={{ paddingLeft:"0" }}>
                    <div onClick={() => handleCategorie(3)} className={categorie == 3 ? "boxShadow coiffurebox selected":"boxShadow coiffurebox"}>
                      <p>Coiffure <br/>spéciale</p>
                    </div>
                  </div>
                  {
                    categorieError == 1 ?
                    <div className='col-md-9' style={{ paddingLeft:"0", paddingTop:'30px', clear:'both' }}>
                      <div className="alert alert-danger" role="alert">
                        Veuillez choisir un service
                      </div>
                    </div>
                    :
                    ""
                  }

                  {
                    catServices.length > 0 ?
                    <div className='custom-form col-md-12 boxShadow listeservice' style={{ paddingLeft:"0" }}>
                        {catServices.map(item => (
                          <div className="divservice row">
                            <div className="col-md-8" style={{ padding:"0" }}>
                              <input onClick={() => handleAddService(item.id, item.prix)} data-price={item.prix} type="checkbox" value={item.id} />
                              <span >{item.service}</span>
                            </div>
                            <div className="col-md-2">
                              <span><i className="colordevis fa fa-clock"/></span><span > {item.duree}</span>
                            </div>
                            <div className="col-md-2" style={{ padding:"0", textAlign:'right' }}>
                              <span className="colordevis">Fcfa</span><span > {item.prix}</span>
                            </div>
                          </div>
                        ))}
                    </div>
                    :
                    ''
                  }


                  {
                    serviceError == 1 ?
                    <div className='col-md-9' style={{ paddingLeft:"0", paddingTop:'30px', clear:'both' }}>
                      <div className="alert alert-danger" role="alert">
                        Veuillez choisir un service
                      </div>
                    </div>
                    :
                    ""
                  }

                  <div className='col-md-9' style={{ paddingLeft:"0", paddingTop:'30px', clear:'both' }}>
                    <div style={{textAlign:'left',marginBottom:'30px'}}>
                      <a onClick={()=>handleGoTo()} class="btn btnform w200 "> Continuer </a>
                    </div>
                  </div>
                </div>    
                <div className='col-md-4'>
                    <div className="header1 boxShadow">Recapitulatif</div>
                    <div className="body1 boxShadow">
                      <p style={{ margin:'0px', paddingLeft:'10px' }}>1 intervention coiffure</p>
                      <hr style={{ margin:'10px' }}></hr>
                      {
                        genreText != '' ?
                        <ul>
                          <li>{genreText}</li>
                          <li>{qte} {qte > 1 ? 'personnes':'personne'}</li>
                        </ul>
                        :
                        ''
                      }
                    </div>
                </div>  
              </div>
            </div>
          </div>
        </section>
      </div>
    );
}

export default Page;
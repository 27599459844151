//export const BASE_URL = "http://admin_proximiti.loc/api";
//export const BASE_URL_PROD = "http://admin_proximiti.loc";

export const BASE_URL = "https://proximitici.com/proximiti.ci/admin/public/api";
export const BASE_URL_PROD = "https://proximitici.com/proximiti.ci/admin/public/api";
export const BASE_PROJECT_URL = "https://proximitici.com/proximiti.ci/admin/public";
// export const BASE_URL = "http://127.0.0.1:8000/api";
// export const BASE_URL_PROD = "http://127.0.0.1:8000/api";
// export const BASE_PROJECT_URL = "http://127.0.0.1:8000";
export const BASE_ADMIN_URL = "https://admin.proximitici.com";


export const CURRENCY = "DZ";
import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router";
import { BASE_URL_PROD, BASE_ADMIN_URL } from "../../../components/config/keys";
import { surpls,epr } from "../../../assets/images";
import Carousel from 'react-bootstrap/Carousel'
import Slider from "react-slick";
import { HiLocationMarker } from 'react-icons/hi';
import GoogleMapReact from 'google-map-react';
import {
        Elements,
        CardElement,
        useStripe,
        useElements,
      } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { saveReservationBien } from "../../../actions/pages";
import moment from 'moment'


const Page = () => {
    const detail_bient = JSON.parse(localStorage.getItem('detail_bient'));
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
 
    const history = useHistory()
  
    const stripe = loadStripe("pk_test_51LSGF1G7VfW1y640IKD9C5oLmKjNJD3WdB2jwYNOh0vqBOCdFtTCL58QrWONgbrOPA6ImuX0E2uYpk1aQJrWgQib00Ok5knJ1L");
    
    const search_data = JSON.parse(localStorage.getItem('search_data'));
    const [type_active, setTypeActive] = useState(search_data?.active);
    const [type_logement, setTypeLogement] = useState(search_data?.type_bien);
    console.log('search_data: ', search_data);
    const [adress, setAdress] = useState(search_data?.adress);
    const [valueDates, setvalueDates] = useState(search_data?.valueDates);
    const [datedepart, setdatedepart] = useState(search_data?.datedepart);
    const [datearrive, setdatearrive] = useState(search_data?.datearrive);
    const [voyageurs, setVoyageur] = useState(search_data?.adultes +  search_data?.enfants + search_data?.bebes + search_data?.animeaux);
    const [nbdays, setnbdayes] = useState(1);
    const [anulations, setAnulations] = useState(0);

    const [total, setTotal] = useState(0);




    const handleSubmit = async (e) => {
        e.preventDefault();
 

    }


 

     useEffect(() => {


      let nbday=1
      let total1 = 0
      if(detail_bient.type_bien== 1){
         if(datearrive){
           nbday=moment(new Date(datearrive)).diff(moment(new Date(datedepart)), 'days')
           setnbdayes( moment(new Date(datearrive)).diff(moment(new Date(datedepart)), 'days'))
           var new_date = moment(new Date(datearrive)).add(5, 'days');

           setAnulations(new_date)
         }

          total1 += parseFloat(detail_bient.prix_nuit * nbday)
        total1 += parseFloat(detail_bient.frais_menage)
        total1 += parseFloat(detail_bient.frais_service)
        total1 += parseFloat(detail_bient.taxe_sejour)

      }
      else{
        total1 += detail_bient.prix_nuit
      }



        
       
       
        setTotal(total1)
  

    }, [total])


         const savecommande=(payement_inten_id)=>{
          let formDataObj = {'payement_inten_id':payement_inten_id, 'total':total, 'bien_id':detail_bient.id, 'client_id':detail_bient.user_id, 'user_id':user.id, 'datedepart':datedepart,'datearrive':datearrive, 'voyageurs':voyageurs, 'type':detail_bient?.type_bien, 'nbdays':nbdays}
          dispatch(saveReservationBien(formDataObj)).then((res) => {
            history.push('/client/messages')
          })
            // history.push('/votre-paiement-accepte/')
                // history.push('/client/messages')
      // return false;
        }

 
    return (
      <div id="homwraped" className="biens">
        <div className="content">


        <section id='biens-reservation'>
        <div className="container small-container">
         <div className='row'>
            <div className='col-md-12'>
             <h1>Votre réservation</h1>
             </div>
          </div>
          <div className='row bloc8876'>
            <div className='col-md-6'>

            
             <div className='bloc889'>
             <h4>Votre voyage</h4>
              <h2>{detail_bient.logement} {detail_bient.city}</h2>
              <h3 style={{display:'none'}}>3 voyageurs - 1 chambre - 3 lits - 1 salle de bain</h3>
              {
                 detail_bient.type_bien==1 &&
                 <div>

                     <div className='flx00987'><img src="/images/icocalendar.PNG" /> <span>Dates</span><b>{valueDates}</b></div>
                     <div className='flx00987'><img src="/images/icovoyageur.PNG" /> <span>Voyageurs</span><b>{voyageurs} voyageur{voyageurs > 1 && 's'}</b></div>

                 </div>
              }
            
              <hr/>

              <h4>Réglement</h4>

              <div className='payimages'><img src='/images/paiemenetsecurise.jpg' /></div>
              <br/>
               <Elements stripe={stripe}>
                  <CheckoutForm  savecommande={savecommande}  anulations={anulations}user={user} detail_bient={detail_bient} total={parseFloat(total).toFixed(2)} history={history}  />
               </Elements>



             </div>
                
            </div>

            <div className='col-md-6'>

              <div className='row blocd8876'>
              {
                detail_bient?.images?.length > 0 ?
                  <div className='col-4 b8876'   style={{backgroundImage:`url(${detail_bient?.images[0] })`}}  > </div>
                :
                   <div className='col-4 b8876' style={{backgroundImage:"url('/images/imo.png')"}}  ></div>
               
              }
               
                <div className='col-8 bl99IU7'>
                  {
                    detail_bient.type_bien == 1 ?
                    <h6><strong>{detail_bient.price} Fcfa </strong>par nuit</h6>
                    :
                    <h6><strong>{detail_bient.price} Fcfa </strong></h6>
                  }
                   <div className='dv77'>
                   {
                    detail_bient.type_bien == 1 ?
                    <div className='tjst6'><span>{detail_bient.price} Fcfa x  {nbdays} nuit(s)</span><b>{nbdays * detail_bient.prix_nuit } Fcfa</b></div>
                    :
                    <div className='tjst6'><span>{detail_bient.price} Fcfa</span><b></b></div>
                  }
                     {
                detail_bient.frais_menage > 0 ?
                <div className='tjst6'><span>Frais de ménage</span><b>{detail_bient.frais_menage} Fcfa</b></div>
                :
                ''
               }
               {
                detail_bient.frais_service > 0 ?
                <div className='tjst6'><span>Frais de service</span><b>{detail_bient.frais_service} Fcfa</b></div>
                :
                ''
               }
               {
                detail_bient.taxe_sejour > 0 ?
                <div className='tjst6'><span>Taxes de séjour</span><b>{detail_bient.taxe_sejour} Fcfa</b></div>
                :
                ''
               }
                     <hr/>
                     <div className='tjst6'><span><strong>Total</strong></span><strong>{total} Fcfa</strong></div>
                      
                   </div>

                </div>

              </div>

              

            </div>

          </div>

        </div>


            
           
        </section>

       

        

        </div>
      </div>
    );
}
 
export default Page;

const CheckoutForm = ({savecommande,total,user,history,anulations,detail_bient}) => {
    const [isPaymentLoading, setPaymentLoading] = useState(false);
    
    const stripe = useStripe();
    const elements = useElements();


     const getSetupClientSecret=()=>{

        const params = {
          total: total ,
          customer_id: user.id
        }
        return fetch(`${BASE_ADMIN_URL}/api/payment/stripe`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          method: 'post',
          body: JSON.stringify(params)
        }).then(response => response.json())
          .catch(error => {
            console.log("stripe_get_secret_setup_intents error", error)
          });
      } 


    const payMoney = async (e) => {
      e.preventDefault();
        
      // history.push('/client/messages')
      // return false;
 
      
      if (!stripe || !elements) {
        return;
      }


      setPaymentLoading(true);
      
      const info = await getSetupClientSecret();
      const clientSecret = info.stripe.client_secret;
      const paymentResult = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: user?.prenom+ +user?.nom,
          },
        },
      });
      setPaymentLoading(false);
      console.log(paymentResult);
      if (paymentResult.error) {
        alert(paymentResult.error.message);
      } else {
         if (paymentResult.paymentIntent.status == "requires_capture") {
             console.log(paymentResult.paymentIntent.id);
            savecommande(paymentResult.paymentIntent.id);
        }
      }
    };


 

    return (
      <div>
        <div
          style={{
            maxWidth: "500px",
            margin: "0 auto",
          }}
        >
          <form
            style={{
              display: "block",
              width: "100%",
            }}

            onSubmit = {payMoney}
           
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CardElement
                className="card"
                options={{
                  style: {
                    base: {
                      backgroundColor: "white"
                    } 
                  },
                }}
              />

              <hr/>
              {
                 anulations != 0 &&

                 <div>

              <h4>Conditions d'annulation</h4>
              <p>Annulation gratuite avant le {moment(new Date(anulations)).format("DD MMM")}</p>
              <p>Votre réservation sera effective lorsque l'hôte aura accepté votre demande de réservation.</p>

                 </div>
              }

             
             <br/>

             <div style={{    width: '100%',textAlign: 'left'}}>
              <button
                className="pay-button"
                disabled={isPaymentLoading}
              >
                {isPaymentLoading ? "Loading..." : "Réserver"}
              </button>
            </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
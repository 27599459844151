import React,{useEffect,useState} from "react";
import './styles.scss';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { IoMdTime } from 'react-icons/io';
import { BiCategoryAlt } from 'react-icons/bi';
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';
import { LOGOUT_USER } from "../../constants/actions";
import { useDispatch, useSelector } from "react-redux"
import { apiClient } from "../../actions/api"
import { BASE_URL_PROD } from "../../components/config/keys";
 
const Component = ({ src }) => {
 const history = useHistory();
     const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const currentlang=i18n.language;
    const { user } = useSelector(state => state.user)
    const [nbMessage, setNbMessage] = useState(0);
    const [value, setValue] = useState(false);

 const handleClick = (slug) => {
         history.push(slug)
  }

   const handleClickMenu = () =>{
        setValue(!value)
    }

    const handleLogout = () =>{
        dispatch({type:LOGOUT_USER});
        setTimeout(() => {
            window.location.href='/'
        }, 300);
    }

 useEffect(() => {

        if (!user || !user.id || user.type!== 2) {
            window.location.href='/'
        }

 

}, [user])
  

    return (

       <div className='menudashbord'>
             <div onClick={()=>handleClickMenu()} className="mob-nav-content-btn color2-bg init-dsmen fl-wrap "><i className="fal fa-bars" style={{marginLeft:'10px'}}></i> Dashboard menu</div>
                                <div className="clearfix"></div>
                                <div className="fixed-bar fl-wrap" id="dash_menu">
                                    <div className={value==true ? "user-profile-menu-wrap fl-wrap block_box dispblock": "user-profile-menu-wrap fl-wrap block_box dispnone" } style={{paddingBottom: '0px'}}>
                                       
                                        <div className="user-profile-menu">
                                        <div className='logomenu' onClick={()=>handleClick("/")}>
                                          <img src='/images/LogoProximiti.png' />
                                        </div>
                                          
                                            <ul className="no-list-style">
                                                <li><a onClick={()=>handleClick("/client/dashboard")} href="#" className={src=='profil' && "user-profile-act"}><img src='/images/icones/iconemenudashboard.png' />Tableau de bord</a></li>
                                                <li><a onClick={()=>handleClick("/assistants/devis/")} href="#" className={src=='editprofil' && "user-profile-act"} ><img src='/images/icones/gestioncontrat.png' />Gestion des contrats</a></li>
                                                <li><a  onClick={()=>handleClick("/assistants/historiques/")} href="#"  className={src=='messages' && "user-profile-act"}><img src='/images/icones/iconehistorique.png' />Historique</a></li>
                                                <li><a onClick={()=>handleClick("/assistants/agenda/")} href="#"  className={src=='agenda' && "user-profile-act"}><img src='/images/icones/iconesRDV.png' />Mes rendez-vous</a></li>
                                                <li><a href="#" className={src=='reclamations' && "user-profile-act"} ><img src='/images/icones/iconedocuments.png' />Mes documents</a></li>
                                                <li><a onClick={()=>handleClick("/assistants/modifier-services/")} href="#" className={src=='reclamations' && "user-profile-act"} ><img src='/images/icones/iconetarifs.png' />Tarification</a></li>
                                                <li><a href="#" className={src=='reclamations' && "user-profile-act"} ><img src='/images/icones/iconeformations.png' />Formations</a></li>
                                                <li><a  onClick={()=>handleClick("/assistants/abonnement/")} href="#"  className={src=='abonnement' && "user-profile-act"} ><img src='/images/icones/iconeabonner.png' />Abonnement</a></li>
                                                <li><a href="#" className={src=='reclamations' && "user-profile-act"} ><img src='/images/icones/iconechantier.png' />Chantiers</a></li>
                                            </ul>

                                            <div className='abonnement'>
                                                  
                                                   <div className='imgprofil'>
                                    
                                                        {
                                                            user.image != '' && user.image != null ?
                                                            <img className="profilImg" src={BASE_URL_PROD+'/admin/public/img/professionnal/'+user.id+'/'+user.image} />
                                                            :
                                                            <img src='/images/icones/iconeprofilproblocabonnementmenu.png' />
                                                        }
                                                        <div style={{marginTop:'3px'}}>Formule actuelle mm</div>
                                                        <strong style={{    display: 'block',marginTop:'3px'}} >
                                                            {
                                                                user?.abonnement_categorie ?
                                                                  user?.abonnement_categorie
                                                                :
                                                                'Gratuit'
                                                            }
                                                              
                                                        </strong>
                                                        <p style={{marginBottom:'3px'}}>
                                                            {
                                                                user.abonnement_type == 0 &&
                                                                 <span></span>
                                                            }
                                                        </p>

                                                     </div>

                                                     <div className='flx'>
                                                            {
                                                                user.abonnement_type != 0 ?
                                                                <div className='cabonne'>
                                                                 <div className='cntabn'>
                                                                      <h5>Classique</h5>
                                                                      <p>10 Fcfa - inter. {'<'} 150 Fcfa</p>
                                                                    <p>20% com. - inter. {'>'} 150 Fcfa </p>
                                                                    <p>25% com. / inter. {'>'} 1000 Fcfa</p>
         
                                                                 </div>
                                                                 <div className="center"> <button type="submit" className="btn float-btn color2-bg btn9876 btcustom lileft"> Souscrire</button></div>
                                                                </div>
                                                                :
                                                                ""
                                                            }
                                                            {
                                                                user.abonnement_type != 1 ?
                                                                <div className='cabonne'>
                                                                 <div className='cntabn'>
                                                                      <h5>Basic</h5>
                                                                      <p>1 Fcfa commission</p>
                                                                      <p>int. {"<"} 150 Fcfa</p>
         
                                                                 </div>
                                                                 <div className="center"> <button type="submit" className="btn float-btn color2-bg btn9876 btcustom lileft"> Souscrire</button></div>
                                                                </div>
                                                                :
                                                                ""
                                                            }

                                                            {
                                                                user.abonnement_type != 2 ?
                                                                    <div className='cabonne'>
                                                                        <div className='cntabn'>
                                                                            <h5>Premium</h5>
                                                                            <p>0 Fcfa commission</p>
                                                                            <p>+ 5 int./mois</p>

                                                                        </div>
                                                                        <div className="center"> <button type="submit" className="btn float-btn color2-bg btn9876 btcustom lileft"> Souscrire</button></div>
                                                                    </div>
                                                                :
                                                                ""
                                                            }


                                                     </div>

                                            </div>

                                            <div className='logout'>
                                               <a  onClick={handleLogout}  ><img src='/images/icones/iconedeconnecter.png' />Se déconnecter </a>
                                            </div>

                                           
                                        </div>
                                                                        
                                        
                                    </div>
            </div>
        </div>
    );
}

export default Component;
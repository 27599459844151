import React, { useEffect,useState } from "react";
import './styles.scss'; 
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal } from "react-bootstrap"
 
import { useParams } from "react-router";
import { apiClient } from "../../../actions/api"
import { useTranslation } from 'react-i18next';
import Carousel from 'react-bootstrap/Carousel'
import Slider from "react-slick";
import { AvisItem } from "../../../components";

import { useHistory } from "react-router";
 



const Page = () => { 
    
    const { keyword:gottenKeyword } = useParams();
    const [keyword, setkeyword] = useState(gottenKeyword)
     const history = useHistory()
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
 
    const { user } = useSelector(state => state.user)
    const { mission_infos } = useSelector(state => state.pages)
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const [informerElement, setInformerElement] = useState('');
    const [email, setEmail] = useState('');

     var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide:0,
     responsive: [
    
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
             infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
             infinite: true,
            dots: false
          }
        }
      ]
   };
   
   const setInformer = (element) => {
    setInformerElement(element)
    setEmail('')
   }

   const handleGoTo = () => {

    history.push('/validation-ticket');
     return apiClient().get(`/sendmail?id=${mission_infos.id}&email=${email}`)
          .then((res) =>
           { 

             history.push('/validation-ticket');

         })


          
          
       
    }
 

    const onChangeData = (e) => {
      setEmail(e.target.value)
    }
   
    const handleSubmit = async (e) => {
     
    }


 

 

 
 

     useEffect(() => {


   
        if(langset == false){
            setLangset(true)
        }
 
 

    }, [])
   
    return (
        <div className="besoin  " >
           <section className="gray-bg big-padding besoin8 " id="besoin7">
            <div className="container small-container ">
             <div className='registerform'>

             
             <br/>

                <div className='row '>
                <div className='col-md-6'>

                 <div className="section-title"><h2 >Votre paiement a été accepté ! <span>nous vous envoyons un artisan</span></h2>
                      
                  </div>
                      <br/><br/>


                    <div className="cadrerecap">
                            <div className='titlerecap'>Ticket travaux</div>
                            <div className='bodyrecap '>
                                 
                                 <ul className='ticketul'>

                                   <li><img src='/images/iconeprofil.png' /><span>{user.nom+' '+user.prenom}</span></li>
                                   <li><img src='/images/iconegéolocalisation-1.png' /><span>{user.adresse+' '+user.ville}</span></li>
                                   <li><img src='/images/iconetarifrdv.png' /><span>{localStorage.getItem('total')} Fcfa</span></li>
                                   <li><img src='/images/iconecalendrier-1.png' /><span>{mission_infos.date_mission}</span></li>
                                   <li><img src='/images/iconehoraire.png' /><span>{mission_infos.duree == 1 ? 'matin':(mission_infos.duree == 2 ? 'après-midi':'soirée')}</span></li>
                                   {/* <li><img src='/images/iconeprofil.png' /><span>Xavier Desmoulins - Plombier</span></li> */}

                                 </ul>

                                 <br/> <br/>

                                 <h5>Informer un tiers de confiance </h5>

                                  <ul className='ticketconf'>

                                   <li onClick={() => setInformer('Locataire')}><img src={informerElement == 'Locataire' ? '/images/Trace2.png':'/images/Tracé2102.png'} /><span style={{paddingRight:'20px'}}>Locataire</span>
                                   {
                                    informerElement == 'Locataire'  ?
                                    <input name="email" value={email} type="text"   onChange={e => onChangeData(e)} placeholder="Email"/>
                                    :
                                    ""
                                   }
                                   </li>
                                   <li onClick={() => setInformer('Gardien')}><img src={informerElement == 'Gardien' ? '/images/Trace2.png':'/images/Tracé2102.png'} /><span style={{paddingRight:'20px'}}>Gardien</span>
                                   {
                                    informerElement == 'Gardien'  ?
                                    <input name="email" value={email}  onChange={e => onChangeData(e)} type="text"  placeholder="Email"/>
                                    :
                                    ""
                                   }
                                   </li>
                                   <li onClick={() => setInformer('Voisin')}><img src={informerElement == 'Voisin' ? '/images/Trace2.png':'/images/Tracé2102.png'} /><span style={{paddingRight:'20px'}}>Voisin</span>
                                   {
                                    informerElement == 'Voisin'  ?
                                    <input name="email" value={email} type="text"  onChange={e => onChangeData(e)}  placeholder="Email"/>
                                    :
                                    ""
                                   }
                                   </li>
                                   <li onClick={() => setInformer('Bailleur')}><img src={informerElement == 'Bailleur' ? '/images/Trace2.png':'/images/Tracé2102.png'} /><span style={{paddingRight:'20px'}}>Bailleur</span>
                                   {
                                    informerElement == 'Bailleur'  ?
                                    <input name="email" value={email} type="text"   onChange={e => onChangeData(e)} placeholder="Email"/>
                                    :
                                    ""
                                   }
                                   </li>
                                   <li onClick={() => setInformer('Assurance')}><img src={informerElement == 'Assurance' ? '/images/Trace2.png':'/images/Tracé2102.png'} /><span style={{paddingRight:'20px'}}>Assurance</span>
                                   {
                                    informerElement == 'Assurance'  ?
                                    <input name="email" value={email} type="text"   onChange={e => onChangeData(e)} placeholder="Email"/>
                                    :
                                    ""
                                   }
                                   </li>

                                 </ul>
                                <br/> <br/>

                                <p>
                                 En validant ce ticket pour la date que vous avez fixée, vous souhaitez que le prestataire intervienne avant la fin du délai légal de rétractation d’une durée de 14 jours. Vous renoncez de ce fait à votre droit de rétractation.
                                </p>

                                <br/>
                                <button type="button" onClick={()=>handleGoTo()} className="btn btnform w100 ">Confirmez le ticket </button>
                                {/* <br/><br/>
                                 <button type="button" className="btn btnform w100 btnwthbg "> Programmer plus tard </button> */}



                            </div>

                         </div>

 

                </div>

                 <div className='col-md-6'>
                         
                       
                      <div className='imglg'>
                         <img src='/images/logo.png' />
                         <div className='lgposabs'></div>
                      </div>




                </div>

                </div>



             </div>
             </div>


            <div className='sect2'>
              <div className="container small-container ">
                 <div className='row'>
                  <div className='col-md-12'>
                      <div className="section-title" style={{paddingBottom:'0px'}}>
                        <h5><span>Nous vous accompagnons tout au long de l'intervention, en cas de problème vous pouvez nous appelez au <strong>06 65 66 05 65</strong></span></h5>
                      </div>
 
 
                  </div>
               </div>
              </div>
             </div>
 
 

         <div className='sect3'>
              <div className="container small-container ">
 
                      <div className='cadrbs1' style={{background:'#f6f6f6'}}>

                                  <div className="process-wrap fl-wrap commentcafonctionne "><div className="default-desc margright mrgtop"><p>&nbsp;</p>
                                    <ul className="no-list-style row">



                                    <li className="col-md-4">
                                    <div className="process-item">
                                    <div className="process-img">
                                     <img src='/images/Iconeartisan.png' />
                                    </div>

                                    <p className='basecolor'>Nos artisans sont certifiés et labellisés.</p>
                                    </div>
                                    </li>

                                    <li className="col-md-4">
                                    <div className="process-item">
                                    <div className="process-img">
                                     <img src='/images/iconechrono.png' />
                                    </div>

                                    <p className='basecolor'>Suivez votre intervention dans votre espace dédié et activez les notifications pour rester informé du déroulement de l'intervention.</p>
                                    </div>
                                    </li>

                                    <li className="col-md-4">
                                    <div className="process-item">
                                    <div className="process-img">
                                     <img src='/images/IconeIA.png' />
                                    </div>

                                    <p className='basecolor'>Bénéficier d'un abonnement qui vous permet de faire des économies dès la première intervention.</p>
                                    </div>
                                    </li>

                                    </ul>
                                    </div>
                             </div>

                      </div>
 
                  </div>
             
             </div>








           </section>

<div className='home'>
 <section className='pd889 sectionbesoin'>
   <div className="container small-container">
   <div class="section-title left"><h5 className='basecolor' style={{textAlign:'left'}}><span>Rappel : en cas d'urgences consultez nos tutos pour plus de sécurité</span></h5></div>
   <div className='row '>

         <div className='col-md-3'>
            <div><img src='/images/Rectanglp657.png' /></div>
            <h6>Plomberie</h6>
            <p>On vous explique comment vous pouvez augmenter la durée de vie de vos équipements.</p>
         </div>

         <div className='col-md-3'>
            <div><img src='/images/Rectangle6572.png' /></div>
            <h6>Tutos</h6>
            <p>Découvrez nos tutos qui vous aideront dans la réalisation de vos projets.</p>
         </div>

            <div className='col-md-3'>
            <div><img src='/images/Rectangle657.png' /></div>
            <h6>Assurance</h6>
            <p>Comparez votre assurance avec nos formules assurance habitation.</p>
         </div>
            <div className='col-md-3'>
            <div><img src='/images/Rectangle67.png' /></div>
            <h6>Aides de l'état</h6>
            <p>Découvrez les aides de l'état pour l'amélioration de votre habitat.</p>
         </div>
            

                            
    </div>
    </div>
</section>
</div>
 

        </div>

    );
}

export default Page;


import React,{ useState,useEffect ,useRef} from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card,Modal} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { DashbordMenu,DashbordHeader } from "../../../components";
import { useTranslation } from 'react-i18next';
import { apiClient } from "../../../actions/api"
import { BASE_URL } from "../../../components/config/keys";
import { AUTHENTICATE_USER, LOGOUT_USER } from "../../../constants/actions";
import { getAllCategories } from "../../../actions/pages";
import { updateInfoUser, suspendCompte,updateInfoUserImage } from "../../../actions/user";
import { BASE_URL_PROD } from "../../../components/config/keys";
import Switch from '@material-ui/core/Switch';
import Slider from '@material-ui/core/Slider';
import moment from "moment";
import 'moment/locale/fr'



import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const Page = () => {

  const stripe = loadStripe("pk_test_51LSGF1G7VfW1y640IKD9C5oLmKjNJD3WdB2jwYNOh0vqBOCdFtTCL58QrWONgbrOPA6ImuX0E2uYpk1aQJrWgQib00Ok5knJ1L");
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user)
  const { data } = useSelector(state => state.pages)
  const { t, i18n } = useTranslation();
  const currentlangStorage = localStorage.getItem("currentlang");
  const currentlang=i18n.language;
  const [langset, setLangset] = useState(false);
  const [profilcomplete, setProfilcomplete] = useState(50);
  const [categories, setCategories] = useState([]);
  const [categ, setCateg] = useState('');
  const [rib, setRib] = useState('');
  const [loading, setLoading] = useState(false);
  const [rayon, setRayon] =  useState(user.rayon);
  const [show, setShow] = useState(false);
  const [showSuspend, setShowSuspend] = useState(false);
  const [disponibilte, setDisponibilite] = useState(user.disponibilite);
  const [geolocalisation, setGeolocalisation] = useState(user.geolocation);
  const [qrcode, setQrcode] = useState(user.qrcode);
  const [notif_email, set_notif_email] = useState(user.notif_email);
  const [notif_sms, set_notif_sms] = useState(user.notif_sms);
  const [notif_proximity, set_notif_proximity] = useState(user.notif_proximity);
  const fileInput = useRef(null)

  
  const [metier, setSecteurActivite] = useState(user.type_logement);
  const [type_entreprise, setTypeEntreprise] = useState(user.type_entreprise);
  const [societe, setSocieteName] = useState(user.societe_name);
  const [nb_employes, setNbEmploye] = useState(user.nb_employes);
  const [adresse, setAdresse] = useState(user.adresse);
  const [codepostal, setZipCode] = useState(user.zipcode);
  const [ville, setVille] = useState(user.ville);
  const [profession, setProfession] = useState(user.profession);
  const [prenom, setPrenom] = useState(user.prenom);
  const [nom, setNom] = useState(user.nom);
  const [telephone, setTelephone] = useState(user.telephone);
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState('');
  const handleCloseDetail = () => setShowDetail(false);
  const [showDetail, setShowDetail] = useState(false);
  const [abonnement_price, setAbonnPrice] = useState(0);
  const [type_abonnement, setTypeabonnement] = useState(user?.abonnement_categorie);
  const [abonnement_current, setabonnement_current] = useState('');
  const [abonnement_category, setabonnement_categorie] = useState('');
  const [changeNotif, setchangeNotif] = useState(0);
  const [typeabonnement, setTypeAbonnement] = useState(user?.abonnement_categorie)




     const [abbonements, setAbonnements] = useState({
                                                   'Particuliers':[
                                                   {'title':'Bronze','price':5,'description':'60Fcfa* de gains annuels (basé sur 2 interventions)','items':['Déplacement gratuit pour tous types d’intervention (2 interventions/an à intervalle de 2 mois)',"Jusqu'à 50% de gain sur les frais de déplacement"]},
                                                   {'title':'Silver','price':15,'description':'360Fcfa* de gains annuels (basé sur 2 interventions)','items':['Gratuité de toute intervention dans le logement dans la limite de 2 interventions simples et non invasives/an',"Conseil et accompagnement d'activité","Jusqu'à 100% de gain sur les frais de déplacement, de main d’œuvre et de fournitures"]},
                                                   {'title':'Gold','price':20,'description':'960Fcfa* de gains annuels (basé sur 4 interventions)','items':["Gratuité de toutes interventions et dépannage express H24","Limite de 4 interventions/an**","Service de conseil juridique illimité","Aide à la déclaration fiscale et transmission de documents administratifs","Service d'accompagnement comptable, fiscal et patrimonial","Export de donnée CSV (Excel)","Jusqu'à 100% de gain sur les frais de déplacement, de main d’œuvre et de fournitures"],'pop':1},
                                                   {'title':'Platinium','price':30,'description':'Gain illimité sur les interventions urgentes','items':["Gratuité de toutes interventions urgente en illimité","Déplacement gratuit","Conseiller dédié","Recherche personnalisée","Assistance travaux et juridique","Intervention assainissement en partie privative","Double de clef standard","Jusqu'à 100% de gain sur les frais de déplacement, de main d’œuvre et de fournitures"]}
                                                   ],
                                                   'Professionnels':[
                                                   {'title':'Classic','price':0,'description':'','items':["10Fcfa par intervention < à 150Fcfa","20% commission intervention > à 150Fcfa","25% commission intervention > à 1000Fcfa","Accès aux outils de gestion devis, factures et messages sauf fournitures et stocks"]},
                                                   {'title':'Basic','price':5,'description':'','items':["1Fcfa par intervention < à 150Fcfa","10% de commission par intervention > à 150Fcfa","Accès aux outils de gestion devis, factures et messages sauf fournitures et stocks","Service d'assurance juridique limité","Jusqu'à 100% de gain sur les frais de déplacement, de main d’œuvre et de fournitures"],'pop':1},
                                                   {'title':'Premium','price':20,'description':'','items':["0Fcfa de commission si plus de 5 interventions par mois","Accès aux outils de gestion devis, factures, messages, fournitures et stocks","Service de conseil juridique illimité","Visibilité auprès des autres utilisateurs","Aide à la déclaration fiscale et transmission de documents administratifs","Service d'assurance juridique","Service d'accompagnement comptable, fiscal et patrimonial","Export de donnée CSV (Excel)"]}
                                                   ],
                                                   'Gestionnaires':[
                                                   {'title':'Particulier','price':0,'description':'Sans engagement (valable 1 mois)','items':["*5Fcfa par jour de réservation sur chaque réservation de logement en location hors frais bancaires et frais de ménage","Mise en vente du bien gratuite","Accès à l'abonnement dépannage","Réservation du logement saisonnier ou courte durée"]},
                                                   {'title':'Pro +','price':5,'description':'','items':["1% de commission sur chaque paiement de loyer par bien","Publication illimitée de biens","Accès à tous les outils de gestion","Dépannage sur devis"]},
                                                   {'title':'Pro','price':0,'description':'','items':["3% de commission par mois sur chaque paiement de loyer mensuel","Publication limitée à 1 bien","Accès aux outils de gestion sauf message et historique des transactions","Dépannage sur devis"]}
                                                   ]

                                                   }
                                                   );




   const choiseAbonnement = (e,item,index,t) => {
        e.preventDefault();
        if(user?.id){

               setabonnement_current(index)
               setabonnement_categorie(t)
               setAbonnPrice(changeNotif== 0 ? item?.price : item?.price * 12 )

               if(item?.price > 0){

                 setShowDetail(true)

               }
               else{

                saveAbonnement('0000')

               }

               

        }
        else{

            alert('Veuillez vous connecter à votre compte !')
            history.push('/login')
        }
       

    }



  const handleClose = () => setShow(false);
  const handleCloseSuspend = () => setShowSuspend(false);
  const handleShow = () => {


    setShow(true);
  }
  const handleShow1 = () => {
    setShowSuspend(true);
  }


  const onChangeData = (e) => {
    if(e.target.name == 'profession')
      setProfession(e.target.value)
    else if(e.target.name == 'nom')
      setNom(e.target.value)
    else if(e.target.name == 'prenom')
      setPrenom(e.target.value)
    else if(e.target.name == 'email')
      setEmail(e.target.value)
    else if(e.target.name == 'password')
      setPassword(e.target.value)
    else if(e.target.name == 'telephone')
      setTelephone(e.target.value)
  }

const changeShowDetail = (type, price) => {
  setTypeabonnement(type)
  if(type > 0){
    setShowDetail(true)
    setAbonnPrice(price)
  }
}

    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
    const label = { inputProps: { 'aria-label': '' } };
    const changeDisponibilite = async (valeur) => {
      setDisponibilite(valeur)
    }
    const changeGeolocalisation = async (valeur) => {
      setGeolocalisation(valeur)
    }
    const changeQrcode = async (valeur) => {
      setQrcode(valeur)
    }
    const changeRayon = async (valeur) => {
      setRayon(valeur)
    }
    const changeNotifEmail = async (valeur) => {
      set_notif_email(valeur)
    }
    const changeNotifSms = async (valeur) => {
      set_notif_sms(valeur)
    }
    const changeNotifProximity = async (valeur) => {
      set_notif_proximity(valeur)
    }
     const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
    
        
    
    }

    const handleSuspendreCompte = () => {
      dispatch(suspendCompte(user.id)).then(() => {
        dispatch({type:LOGOUT_USER});
        setTimeout(() => {
          window.location = '/'
        }, 500);
      })
      // 
    }

    const handleSubmit1 = async (e) => {
      e.preventDefault();

      const formData = new FormData(e.target)
      const formDataObj = Object.fromEntries(formData.entries())
      let go=true;

 


      if(go){
         dispatch(updateInfoUser(formDataObj,user.id,'professionnal'));
      }
   }



 const handleClickfile = () => {
      fileInput.current.click()
  }

  const handleFileChange = event => {
       
      const formData =  new FormData();
      formData.append('image',event.target.files[0]);
      formData.append('profile','professionnal');
      formData.append('id',user.id);
      formData.append('type','image');
     
     dispatch(updateInfoUserImage(formData));
     
  }



    useEffect(() => {
      let percent= 50
      if(user?.logo != ''){
          percent=percent+10
      }

       if(user?.kbis != ''){
          percent=percent+10
      }

       if(user?.assurance != ''){
          percent=percent+10
      }

       if(user?.zipcode != ''){
          percent=percent+5
      }

        if(user?.latitude != ''){
          percent=percent+5
      }

        if(user?.adresse != ''){
          percent=percent+5
      }

      if(user?.rayon != ''){
          percent=percent+5
      }

      setProfilcomplete(percent)
    
      

    }, [user])

   
     const saveAbonnement=(payement_inten_id)=>{
          const params = {
            payement_inten_id:payement_inten_id,
            user_id:user.id,
            user_type:user?.type==2 ?'client' : 'pro',
            type_abonnement:abonnement_current,
            category_abonnement:abonnement_category,
            abonnement_price:abonnement_price,
            duree:changeNotif==0 ? 'mois' : 'an'
          };
          
          apiClient().post(`/professional/saveabonnement`,params)
          .then((res) => {
            if(res.data.success == 1){
                 setShowDetail(false)
                 dispatch({ type: AUTHENTICATE_USER, payload:res.data?.user})
                 setTimeout(function(){
                    if(user?.type == 2){
                         history.push('/client/abonnement/')
                    }
                    else{
                         history.push('/assistants/abonnement')

                    }
                   
                 }, 1000);

            }
            else{
              alert('Une erreur est survenue veuillez réessayer ultérieurement.')
            }
            
             
          })
      
    } 
 
    // Changing State when volume increases/decreases
    const rangeSelector = (event, newValue) => {
      setRayon(newValue);
      
    };
   
    const changeTelephone = (event, newValue) => {
      setTelephone(newValue);
      
    };
    const changeNom = (event, newValue) => {
      setNom(newValue);
      
    };


         const returnAboonement = (t) => {

        let listabonnement= t=='Particuliers' ? abbonements['Particuliers'] : t=='Professionnels' ? abbonements['Professionnels'] : t=='Gestionnaires' ? abbonements['Gestionnaires'] : []
       
       return (

         <div className='items itemsparticulaires' >

                         {
                            
                             listabonnement?.map((item,index) => (


                            <div className='item bxshadow' key={index}>
                            <div>

                              <div className='title'>{item?.title}</div>
                              {
                                item?.price > 0 ?
                                  <h5>{    changeNotif== 0 ? item?.price : item?.price * 12 }Fcfa<sup>/{changeNotif==0 ? 'mois' : 'an'}</sup></h5>
                                  :
                                  <h5>Gratuit</h5>


                              }
                              
                               <p>{item?.description}</p>

                               <ul>
                               {
                              
                                 item['items']?.map((rw,ri) => (

                                   <li key={ri}><img src="/images/icones/iconechecklisteformule.png" /><span>{rw}</span></li>

                                ))


                               }

                                
                               </ul>
                               </div>
                               {

                                (user?.abonnement_type==index  ) ?

                                 <div className='center' styles={{with:'100%'}}><button class="offeractio" style={{backgroundColor:'#CCC'}} > Souscription actuelle </button></div>


                                :

                                 <div className='center' styles={{with:'100%'}}><button class="offeractio" onClick={(e)=>choiseAbonnement(e,item,index,t)}> Souscrire </button></div>


                               }

                              

                           </div>



                              ))
                         }

                         
                      

                         </div>

        )
    }

 

 

    return (
        <div className="myccount editprofil abonnement formules " >
            
                       <section className="gray-bg main-dashboard-sec dashboard myccount" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <DashbordMenu src='abonnement'/>
                                 
                            </div>


                         <div className='col-md-9 dashbordassitantcontent' >

                             <div className='tab' id=''>


                              <DashbordHeader src={'w'} />

                             <div className='row' style={{marginTop:'30px'}}>

                          
                               <div className='col-md-12'>

                                 
                              <form  onSubmit={handleSubmit} >

                               <div className='rowtitel'>
                                                                    
                                  <img src='/images/icones/iconeabonnement.png' />
                                  <h3 className='htri1'>Votre abonnement </h3>

                               </div>

          <div>

               <div className='row row9998'>
               
                     <div className='col-md-12'>
                         <div className='item labelswt'>
                                                    <label > 
                                                    <span style={{    width: 75}} className='inlineblk' > Mensuel </span> 
                                                    {
                                                      changeNotif == 1 ?
                                                      <Switch {...label} defaultChecked size="small" onChange={() => setchangeNotif(0)} color="primary"  />
                                                      :
                                                      <Switch {...label} size="small" color="secondary" onChange={() => setchangeNotif(1)}  />
                                                    }
                                                    <span style={{    width: 75}} className='inlineblk' >Annuel</span>
                                                    </label>
                         </div>


                     </div>
                  
                   </div>


          </div>

          {
            (type_abonnement=='' || !type_abonnement || type_abonnement==null) &&
             <div className='alert alert-primary center'>

                     <a href='#' onClick={()=>history.push('/formules')}>Formules</a>
               </div>
          }


         <section className='offerabonnement offerabonnementadm'>
  

             <div className='offerabbody' style={{backgroundColor:'#FFF'}}>



            <div className='container'>
             <div className='row offeritems'>
                   
                     <div className='col-md-12'>

                     {
                         typeabonnement=='Particuliers' && returnAboonement('Particuliers')
                     }
                      {
                         typeabonnement=='Professionnels' && returnAboonement('Professionnels')
                     }
                     {
                         typeabonnement=='Gestionnaires' && returnAboonement('Gestionnaires')
                     }

                     <br/> <br/>

                     {
                      user?.fin_abonnement &&

                      <div className='alert alert-primary center'>

                      La Date de fin d'abonnement :   {moment(new Date(user?.fin_abonnement)).format("DD MMM Y")}

                      </div>
                     }


            
                     </div>
                     
             </div>
             </div>
                





             </div>


           </section>



                               







                                   </form>


           

                                   
                  

                               </div>
                  

                             </div>


                                 
                          </div>
 

                              

                         </div>
                    </div>
            </section>
     <Modal show={show}   
      size="lg"
 
     onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='clrbl90'>Supprimer mon compte</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <p className='clrbl10'>En supprimant votre compte vous supprimez également ses données (services, base clients, factures/devis, chiffre d'affaires) sans remboursement possible.<br/>
          <strong>Vous ne pourrez plus vous connecter à votre espace de travail.</strong></p>

          <div className='mdflx99'>

         
            <input type='checkbox'  />
               <label>
                  Je confirme que mes transactions sont bien finalisées.
            </label>

          </div>

          <div>
           <textarea className='form-control clrbl10' placeholder='Aidez-nous à nous améliorer, pourquoi souhaitez-vous supprimer votre compte ?'></textarea>
          </div>


        </Modal.Body>
        <Modal.Footer style={{justifyContent: 'flex-start'}}>
          <Button className='btnmodal btnmodalbrd' variant="secondary" onClick={handleClose} >
            Annuler
          </Button>
          <Button className='btnmodal' variant="primary" onClick={handleClose}>
            Supprimer mon compte
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showSuspend}   
      size="lg"
 
     onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='clrbl90'>Suspendre mon compte</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong>Voullez vous vraiment suspendre votre compte ?</strong>
        </Modal.Body>
        <Modal.Footer style={{justifyContent: 'flex-start'}}>
          <Button className='btnmodal btnmodalbrd' variant="secondary" onClick={handleCloseSuspend} >
            Annuler
          </Button>
          <Button className='btnmodal' variant="primary" onClick={handleSuspendreCompte}>
            Suspendre mon compte
          </Button>
        </Modal.Footer>
      </Modal>


          <Modal show={showDetail} onHide={handleCloseDetail}>
                  <Modal.Header closeButton>
                        Abonnement
                  </Modal.Header>
                  <Modal.Body>

                  <div className="cadrerecap">
                            
                            <div className='bodyrecap '>
                              <div className="ligne">
                                <div className="col-md-12">
                                    <img src='/images/paiemenetsecurise.jpg' />
                                  </div>
                              
                              </div>
                                <div className="ligne paiments">
                                <div className="col-md-12">
                        <Elements stripe={stripe}>
                            <CheckoutForm BASE_URL={BASE_URL} saveAbonnement={saveAbonnement} user={user} total={abonnement_price} history={history}  />
                        </Elements>
                                  </div>
                              
                              </div>


                            </div>

                        </div>
                  </Modal.Body>
          </Modal>
        </div>
    );
}

export default Page;


const CheckoutForm = ({saveAbonnement,total,user,history,BASE_URL}) => {
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  
  const stripe = useStripe();
  const elements = useElements();


   const getSetupClientSecret=()=>{

      const params = {
        total: total ,
        customer_id: user.id
      }
      return fetch(BASE_URL+'/payment/stripe', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'post',
        body: JSON.stringify(params)
      }).then(response => response.json())
        .catch(error => {
          console.log("stripe_get_secret_setup_intents error", error)
        });
    } 


  const payMoney = async (e) => {
    e.preventDefault();
      
      // history.push('/votre-paiement-accepte/')
    // return false;
    
    if (!stripe || !elements) {
      return;
    }


    setPaymentLoading(true);
    
    const info = await getSetupClientSecret();
    const clientSecret = info.stripe.client_secret;
    const paymentResult = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: user.prenom+ +user.nom,
        },
      },
    });
    setPaymentLoading(false);
    console.log(paymentResult);
    if (paymentResult.error) {
      alert(paymentResult.error.message);
    } else {
       if (paymentResult.paymentIntent.status == "requires_capture") {
           console.log(paymentResult.paymentIntent.id);
          saveAbonnement(paymentResult.paymentIntent.id);
      }
    }
  };




  return (
    <div>
      <div
        style={{
          maxWidth: "500px",
          margin: "0 auto",
        }}
      >
        <form
          style={{
            display: "block",
            width: "100%",
          }}

          onSubmit = {payMoney}
         
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CardElement
              className="card"
              options={{
                style: {
                  base: {
                    backgroundColor: "white",
                    width: "100%",
                    
                  } 
                },
              }}
            />
            <button
              className="pay-button"
              disabled={isPaymentLoading}
            >
              {isPaymentLoading ? "Loading..." : "Payer "+total+" Fcfa"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
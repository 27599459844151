import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card,Modal} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { DashbordMenuClient,DashbordHeaderClient } from "../../../components";
import { useTranslation } from 'react-i18next';
import { apiClient } from "../../../actions/api"

import { AUTHENTICATE_USER, LOGOUT_USER } from "../../../constants/actions";
import { getAllCategories } from "../../../actions/pages";
import { updateInfoUser, suspendCompte } from "../../../actions/user";
import { BASE_URL_PROD } from "../../../components/config/keys";
import Switch from '@material-ui/core/Switch';
import Slider from '@material-ui/core/Slider';
import { getDossierNumber, saveRapport, getLocataire } from "../../../actions/pages";

 

const Page = () => {

   
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user)
  const { data } = useSelector(state => state.pages)
  const { t, i18n } = useTranslation();
  const currentlangStorage = localStorage.getItem("currentlang");
  const currentlang=i18n.language;
  const [langset, setLangset] = useState(false);
  const [code, setCode] = useState('');
  const { list_locataires } = useSelector(state => state.pages)

  const history = useHistory()
  const [debut_astreinte, setStartDate] = useState(new Date());
  const [debut_astreinte_heure, setStartHeure] = useState();
  const [fin_astreinte, setEndDate] = useState(new Date());
  const [fin_astreinte_heure, setEndHeure] = useState();
  const [technicien, setTechnicien] = useState('');
  const [adresse, setAdresse] = useState('');
  const [cp, setCp] = useState('');
  const [ville, setVille] = useState('');
  const [type_intervention, setTypeIntervention] = useState('');
  const [status, setStatus] = useState('');
  const [commentaire, setCommentaire] = useState('');
  const [locataire, setLocataire] = useState({});
  const [locataire_id, setLocataireId] = useState();
  const [etage, setEtage] = useState();
  const [porte, setPorte] = useState();
  const [showLocataire, setShowLocataire] = useState(false);
  
  const [demande_rappel, setDemandeRappel] = useState();
  const [confirmation_locataire, setConfirmationLocataire] = useState();
  const [locataire_en_attente, setLocataireAttente] = useState();
  const label = { inputProps: { 'aria-label': '' } };


  useEffect(() => {
    dispatch(getDossierNumber(user.id)).then((res) => {
      setCode(res)
    });
    dispatch(getLocataire(user.id));
  }, [])

  const handelChange = (e) => {
    if(e.target.name == "debut_astreinte")
      setStartDate(e.target.value)
    else if(e.target.name == "debut_astreinte")
      setStartDate(e.target.value)
    else if(e.target.name == "debut_astreinte_heure")
      setStartHeure(e.target.value)
    else if(e.target.name == "fin_astreinte")
      setEndDate(e.target.value)
    else if(e.target.name == "fin_astreinte_heure")
      setEndHeure(e.target.value)
    else if(e.target.name == "technicien")
      setTechnicien(e.target.value)
    else if(e.target.name == "adresse")
      setAdresse(e.target.value)
    else if(e.target.name == "cp")
      setCp(e.target.value)
    else if(e.target.name == "ville")
      setVille(e.target.value)
    else if(e.target.name == "type_intervention")
      setTypeIntervention(e.target.value)
    else if(e.target.name == "status")
      setStatus(e.target.value)
    else if(e.target.name == "commentaire")
      setCommentaire(e.target.value)
    else if(e.target.name == "etage")
      setEtage(e.target.value)
    else if(e.target.name == "porte")
      setPorte(e.target.value)
  }

  const handleShowLocataire = () => {
    if(showLocataire == true)
      setShowLocataire(false)
    else
      setShowLocataire(true)
  }

  const HandleLocataire = (index) => {
    setLocataire(list_locataires[index])
    setLocataireId(list_locataires[index].id)
    setShowLocataire(false)
  }


  const handeSubmitRapport = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target)
    const formDataObj = Object.fromEntries(formData.entries())
    formDataObj.user_id = user.id
    formDataObj.locataire_id = locataire_id  
    dispatch(saveRapport(formDataObj)).then((res) => {
      history.push('/client/gestion-des-astreintes')
    });
  }
    const returnitems =  () => {
        return(

            <div className='col-md-3  position_relative'>

                 <div className="process-item position_relative" style={{cursor:'pointer'}} onClick={()=>history.push('/detail-bien/1869')}>
                        <div className="process-img" style={{backgroundImage:"url('/images/imo.png')", minHeight: '95px'}}>
                       
                        </div>
                       
                        <h4 className='basecolor title' ><strong style={{color:'#0E0E0E'}}>Appartement </strong>3 pièces 65 m²</h4>
                       
                        <h6 className='' style={{ textAlign:"left", fontSize:"11px", color:"gray",     display: 'flex',alignItems: 'center',justifyContent: 'space-between' }}>
                          <div><img src="/images/immo/icone-map.png" className="" style={{ width:"12px",    height: '16px',     marginTop: '0px' }} /> Paris 18ème</div>
                          <div className="priceoffre" style={{ textAlign:"left", fontSize:"18px" ,padding: '4px',    background: '#073460'}}><span style={{fontSize: '12px', color: '#FFF'}}>85 0000 Fcfa </span></div>
                        </h6>
                        
                      </div>

            </div>


          )
    }
     

 

    return (
        <div className="myccount editprofil abonnement rapportestreint " >
            
                       <section className="gray-bg main-dashboard-sec dashboard myccount" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <DashbordMenuClient src=''/>
                                 
                            </div>


                         <div className='col-md-9 dashbordassitantcontent' >

                             <div className='tab' id=''>


                              <DashbordHeaderClient src={'w'} />

                             <div className='row' style={{marginTop:'30px'}}>

                               <div className='col-md-12'>

                               <form onSubmit={handeSubmitRapport}>
                                    <div class="rowtitel" style={{    justifyContent: 'space-between', marginBottom:60}}>
                                       
                                       <h3 class="htri1" style={{fontSize:21,    marginLeft: 0}}>Rapport d'astreinte</h3>
                                       <h3>N° dossier <span>{code}</span></h3>
                                    </div>

                                   <div className="custom-form">

                                     <div className="row" style={{marginBottom:'10px'}}>
                                       <div className="col-md-2">
                                         <label>Début d'astreinte</label>
                                       </div>
                                        <div className="col-md-3">
                                            <input onChange={handelChange} name="debut_astreinte" value={debut_astreinte} type="date" class="form-control inptxt" required=""  />
                                       </div>
                                        <div className="col-md-2">
                                            <input onChange={handelChange} name="debut_astreinte_heure" value={debut_astreinte_heure} type="time" class="form-control inptxt" required=""  />
                                       </div>
                                     </div>


                                        <div className="row" style={{marginBottom:'10px'}}>
                                       <div className="col-md-2">
                                         <label>Fin d'astreinte</label>
                                       </div>
                                        <div className="col-md-3">
                                            <input onChange={handelChange} name="fin_astreinte" value={fin_astreinte} type="date" class="form-control inptxt" required=""  />
                                       </div>
                                        <div className="col-md-2">
                                            <input onChange={handelChange} name="fin_astreinte_heure" value={fin_astreinte_heure} type="time" class="form-control inptxt" required=""  />
                                       </div>
                                     </div>

                                      <div className="row" style={{marginBottom:'10px'}}>
                                       <div className="col-md-2">
                                         <label>Technicien</label>
                                       </div>
                                        <div className="col-md-5">
                                            <input onChange={handelChange} name="technicien" value={technicien} type="text" class="form-control inptxt" required=""  />
                                       </div>
                                        
                                     </div>


                                     <h3>Lieu de l'intervention</h3>
                                     <br/>

                                     <div className='row'  style={{marginBottom:'10px'}}>
                                        <div className="col-md-6"> 
                                        <input placeholder="Adresse" onChange={handelChange} name="adresse" value={adresse} type="text" class="form-control inptxt" required=""  />
                                        </div>
                                        <div className="col-md-2"> 
                                        <input placeholder="CP"  onChange={handelChange} name="cp" value={cp} type="text" class="form-control inptxt" required=""  />
                                        </div>
                                        <div className="col-md-4"> 
                                        <input placeholder="Ville"  onChange={handelChange} name="ville" value={ville} type="text" class="form-control inptxt" required=""  />
                                        </div>
                                     </div>

                                        <div className='row'  style={{marginBottom:'10px'}}>

                                        <div className="col-md-4"> 
                                         <label>Technicien</label>
                                          <select className='form-control' name=''>
                                           <option value=''>Sélectionnez</option>
                                       </select>
                                        </div>
                                        <div className="col-md-4"> 
                                           <label>Type d'intervention</label>
                                          <select className='form-control'  onChange={handelChange} name="type_intervention" value={type_intervention}>
                                           <option value=''>Sélectionnez</option>
                                           <option value='Assainissement'>Assainissement</option>
                                           <option value='Electricité générale'>Electricité générale</option>
                                           <option value='Dégâts des eaux'>Dégâts des eaux</option>
                                           <option value='Squat'>Squat</option>
                                           <option value='Effraction'>Effraction</option>
                                           <option value='Décès'>Décès</option>
                                           <option value='Incendie'>Incendie</option>
                                           <option value="Coupure d'eau générale">Coupure d'eau générale</option>
                                           <option value="Absence générale d'eau">Absence générale d'eau</option>
                                           <option value='Chauffage collectif'>Chauffage collectif</option>

                                          </select>
                                        </div>
                                        <div className="col-md-4"> 
                                          <label>Statut</label>
                                          <select className='form-control' onChange={handelChange} name="status" value={status}>
                                           <option value=''>Sélectionnez</option>
                                           <option value='En cours'>En cours</option>
                                           <option value='Traité'>Traité</option>
                                           <option value='Clôturé'>Clôturé</option>
                             

                                          </select>
                                        </div>

                                     </div>


                                     <div className='row'  style={{marginBottom:'10px'}}>

                                        <div className="col-md-12"> 
                                          <textarea style={{height: '130px'}} className="form-control inptxt" placeholder='Commentaire' onChange={handelChange} name="commentaire" value={commentaire} rows='10'></textarea>
                                        </div>

                                     </div>


                                     <div className='flx9980'>

                                    <h3 style={{margin:'0px'}}>Locataire concerné</h3>

                                    <a href='javascript:void(0)' onClick={handleShowLocataire}>

                                       Rechercher un locataire

                                    </a>


                                    </div>
                                    <br/>
                                    {
                                      showLocataire == true ?
                                      <div className='row'  style={{marginBottom:'10px'}}>
                                        <div className="col-md-12 custom-form">
                                        <table  id="datatable_valides" className="rowtabcustomer table table-separate  table-head-custom table-checkable dataTable no-footer dtr-inline mb-0 animated ">
                                            <thead>
                                              <tr>
                                                <th>Nom</th>
                                                <th>Prénom</th>
                                                <th>Adresse</th>
                                                <th></th>
                                              </tr>
                                            </thead>
                                            {
                                              list_locataires != undefined ?
                                              list_locataires.map((item,indic) => (
                                                <tr>
                                                  <td>{item.last_name}</td>
                                                  <td>{item.first_name}</td>
                                                  <td>{item.adresse}</td>
                                                  <td><img onClick={() => HandleLocataire(indic)} style={{ cursor:'pointer' }} src="/images/Trace2.png"/></td>
                                                </tr>
                                              ))
                                              :
                                              ""
                                            }
                                          </table>
                                        </div>
                                      </div>
                                      :
                                      ""
                                    }
                                    <br/>

                                      <div className='row'  style={{marginBottom:'10px'}}>

                                        <div className="col-md-6"> 
                                        <input placeholder="Nom" value={locataire.last_name} type="text" class="form-control inptxt" required=""  />
                                        </div>
                                        <div className="col-md-6"> 
                                        <input placeholder="Prénom" value={locataire.first_name} type="text" class="form-control inptxt" required=""  />
                                        </div>
                                   

                                     </div>


                                      <div className='row'  style={{marginBottom:'10px'}}>

                                        <div className="col-md-12"> 
                                        <input placeholder="Adresse" value={locataire.adresse} type="text" class="form-control inptxt" required=""  />
                                        </div>

                                      </div>


                                      <div className='row'  style={{marginBottom:'10px'}}>

                                 
                                        <div className="col-md-2"> 
                                        <input placeholder="CP" value={locataire.cp} type="text" class="form-control inptxt" required=""  />
                                        </div>
                                        <div className="col-md-6"> 
                                        <input placeholder="Ville" value={locataire.ville} type="text" class="form-control inptxt" required=""  />
                                        </div>
                                         <div className="col-md-2"> 
                                        <input placeholder="Etage" onChange={handelChange} name="etage" value={etage} type="text" class="form-control inptxt" required=""  />
                                        </div>
                                          <div className="col-md-2"> 
                                        <input placeholder="Porte" onChange={handelChange} name="porte" value={porte} type="text" class="form-control inptxt" required=""  />
                                        </div>

                                     </div>

                                     


                                <div className='row'  style={{marginTop:'30px'}}>
                                   <div className="col-md-12"> 
                                         <div class="form-check">
                                                 <input  onChange={handelChange} name="demande_rappel" value={demande_rappel} type="checkbox" class="form-check-input ch667" value="1"/>
                                                <label title="" class="form-check-label">Demande de rappel du locataire</label>

                                           </div>

                                          <div class="form-check">
                                                 <input onChange={handelChange} name="confirmation_locataire" value={confirmation_locataire} type="checkbox" class="form-check-input ch667" value="1"/>
                                                <label title="" class="form-check-label">Confirmation avec le locataire</label>

                                           </div>

                                          <div class="form-check">
                                                 <input onChange={handelChange} name="locataire_en_attente" value={locataire_en_attente} type="checkbox" class="form-check-input ch667" value="1"/>
                                                <label title="" class="form-check-label">Locataire en attente</label>

                                           </div>




                                         

                                    </div>
                              </div>

                                    <div className='row'  style={{marginTop:'30px'}}>
                                       <div className="col-md-12"> 
                                       <a href='javascript:void(0)' className='addnew' onClick={()=>history.push('/client/ajouter-locataire/')}>

                                          <img src='/images/addnew.png' style={{width:40, marginRight:20}} />
                                          <span>Ajouter un locataire</span>

                                       </a>
                                       </div>

                                    </div>

                                 
                                     







                   
                                   </div>

                         

                                   <button type="submit" className="btn float-btn color2-bg btn9876 btcustom "  style={{borderRadius:27, marginTop: '5px'}}>Enregistrer</button>
                                    </form>   


                                
                     

                               </div>
                  

                             </div>


                                 
                          </div>
 

                              

                         </div>
                    </div>
            </section>
     
        </div>
    );
}

export default Page;



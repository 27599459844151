import React, { useEffect,useState } from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal } from "react-bootstrap"
import { AssistantItem } from "../../components";
import { useParams } from "react-router";
import { apiClient } from "../../actions/api"
import { useTranslation } from 'react-i18next';
import { getWatcher,saveMission } from "../../actions/pages";
import Slider from '@material-ui/core/Slider';


const Page = () => {
    const { slug } = useParams();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const {watcher } = useSelector(state => state.pages)
    const { user } = useSelector(state => state.user)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow(false);

    const [price, setPrice] =  useState(10);
    const [minprice, setMinprice] =  useState(10);
    const [daterun, setDaterun] =  useState(0);

    const categoryitem = localStorage.getItem("categoryitem");
    const villeitem = localStorage.getItem("villeitem");

 // Changing State when volume increases/decreases
    const rangeSelector = (event, newValue) => {
      setPrice(newValue);
      
    };

    const findAwatcher = () =>{
        window.location.href='/find-a-watcher/';
    }
 
    const handleSubmit = (e) => {
        e.preventDefault();
       
         let go=true;

         if(!categoryitem || !villeitem ){
            go=false;
            window.location.href='/';
          }

          if(price < minprice ){
              alert(t('Le prix minimum imposé est : ')+minprice+'Fcfa ');
              return false;
          }


            const formData = new FormData(e.target)
            const formDataObj = Object.fromEntries(formData.entries())

          if(user.id){
           
            dispatch(saveMission(formDataObj,price,categoryitem,villeitem,user.id,user.emailToken,currentlang));
            setShow(false)
            setShow2(true)
          }
          else{
              // store data in session
              localStorage.setItem("formDataObj_mission",JSON.stringify(formDataObj))
              localStorage.setItem("price_mission",price) 
              localStorage.setItem("categoryitem_mission",categoryitem) 
              localStorage.setItem("villeitem_mission",villeitem) 
         
               window.location.href='/login'
             // alert(t('Veuillez vous connecter à votre espace client !'));
            
          }
    }

 const handleGoTo = (e) => {
        e.preventDefault();
        window.location.href='/mon-compte'
    }

    const handleShow = (e) => {
        e.preventDefault();

        setShow(true)
        setDaterun(1)


    }


    useEffect(() => {
         const params={
            id:slug
         }
        dispatch(getWatcher(params))
        if(langset == false){

             apiClient().get(`/get/price`,{})
            .then((res) => {
               if(res.data.success==true){
                
                 setPrice(Number(res.data.data))
                 setMinprice(Number(res.data.data)) 
               }
                      
            })

            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }
 
 

    }, [])


    const returnReviews= (note) => {
          
         return(

        
            note==1 ?
             <div><i class="fas fa-star"></i></div>
             : note == 2 ?
             <div><i class="fas fa-star"></i><i class="fas fa-star"></i></div>
             : note == 3 ?
             <div><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></div>
             : note == 4 ?
             <div><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></div>
             : note == 5 ?
             <div><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></div>
             :
             <div></div>

          
       )
    }
   
    return (
        <div className="single" >
             
              <section className="listing-hero-section hidden-section" data-scrollax-parent="true" id="sec1">
                        <div className="bg-parallax-wrap">
                            <div className="ps998 " >


                                  
                                <div className='center p33'>
                                        <div className='ps889' style={{backgroundImage:`url(${watcher.photo })`}}>

                                        </div>
                                </div>
                            </div>
                            <div className="overlay"></div>
                        </div>
                        <div className="container">
                            <div className="list-single-header-item  fl-wrap">
                                <div className="list-single-stats frmb34 return">
                                      <div onClick={findAwatcher} className="geodir_status_date gsd_open selected">{t('Retour')}</div>
                                    </div>
                                <div className="row">
                                    <div className="col-md-9">
                                   

                                        <h1 style={{textTransform:'capitalize'}}> {watcher.prenom} {watcher.lname}. {watcher.intern==1 && <span className="verified-badge"><i className="fal fa-check"></i></span>} </h1>
                                        <div className="geodir-category-location fl-wrap"><a ><i className="fas fa-map-marker-alt"></i> {watcher.ville}</a>  </div>
                                    </div>
                                    <div className="col-md-3">
                                        <a className="fl-wrap list-single-header-column custom-scroll-link " >
                                            <div className="listing-rating-count-wrap single-list-count">
                                                <div className="review-score">{ parseFloat(watcher.reviews).toFixed(1)}</div>
                                                

                                                <div className="listing-rating card-popup-rainingvis" data-starrating2="4">
                                                {returnReviews(watcher.reviews)}
                                                <div className="card-popup-rainingvis_bg"><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><div></div></div>

                                                </div>
                                                

                                                <br/>                                                
                                                <div className="reviews-count">{watcher.avis} {t('avis')}</div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                           
                            <div className="list-single-header_bottom fl-wrap">
                       {
                                watcher.catgories &&
                          
                            <div>

                                 {watcher.catgories.map((item,index) => {
                                       let newindex=index+1;
                                       return (
                                            
                                        
                                            
                                                 <a key={index} className={"listing-item-category-wrap mrg88"} >
                                                    <div className={"listing-item-category  center tain"+newindex}><i className="far fa-check"></i></div>
                                                    <span>{currentlang == 'fr' ? item.label : item.label_en }</span>
                                                </a>
                                             

                                        );
                            
                                   })}


                            </div>
                              }
 
                                
                                <div className="geodir_status_date gsd_open price frmb34">Fcfa {watcher.tarif}/hr</div>
                                <div className="geodir_status_date gsd_close missions frmb34">{watcher.nbmission} mission{watcher.nbmission > 1 && "s"}</div> 
                                <div className="list-single-stats frmb34">
                                  <div onClick={handleShow} className="geodir_status_date gsd_open selected">{t('Proposer une mission')}</div>
                                </div>
                            </div>
                        </div>
                    </section>



                    <section className="gray-bg no-top-padding">
                        <div className="container">
                            
                            <div className="clearfix"></div>
                            <div className="row">
                                
                                <div className="col-md-12">
                                    
                                    <div className="list-single-main-wrapper fl-wrap" id="sec2">
                                        
                                         
                                        <div className="list-single-main-item fl-wrap block_box">
                                            <div className="list-single-main-item-title">
                                                <h3>Description</h3>
                                            </div>
                                            <div className="list-single-main-item_content fl-wrap">
                                                 {watcher.description}
                                            </div>
                                        </div>

                                        {
                                            /*


                                                <div className="list-single-main-item fl-wrap block_box">
                                            <div className="list-single-main-item-title">
                                                <h3>Tags</h3>
                                            </div>
                                            <div className="list-single-main-item_content fl-wrap">
                                                <div className="listing-features fl-wrap">
                                                    <ul className="no-list-style tags89">
                                                        <li><a  > Elevator in building</a></li>
                                                        <li><a  > Free Wi Fi</a></li>
                                                        <li><a  > Free Parking</a></li>
                                                        <li><a  > Air Conditioned</a></li>
                                                        <li><a  > Online Ordering</a></li>
                                                        <li><a  > Pet Friendly</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>




                                            */
                                        }
                                                                                       
                                         
                                      
                                        <div className="list-single-main-item fl-wrap block_box" id="sec5">
                                            <div className="list-single-main-item-title">
                                                <h3>{t('avis')} -  <span> {watcher.avis} </span></h3>
                                            </div>
                                               
                                            
                                                                                         
                                            <div className="list-single-main-item_content fl-wrap">
                                                <div className="reviews-comments-wrap">

                                    {watcher.evaluations && watcher.evaluations.map((item,index) => {
                                       let newindex=index+1;
                                       return (
                                            
                                        
                                                 <div key={newindex} className="reviews-comments-item">
                                                        <div className="review-comments-avatar">
                                                           
                                                            <div className='ps9988' style={{backgroundImage:`url(${item.profil_picture })`}}>

                                                              </div> 
                                                        </div>
                                                        <div className="reviews-comments-item-text fl-wrap">
                                                            <div className="reviews-comments-header fl-wrap">
                                                                <h4><a href="javascript:void(0)" >{item.prenom} {item.nom}</a></h4>
                                                                <div className="review-score-user">
                                                                    <span className="review-score-user_item center">{item.note}</span>
                                                                    <div className="listing-rating card-popup-rainingvis" data-starrating2="4">
                                                {returnReviews(item.note)}
                               
                                                </div>
                                                                </div>
                                                            </div>
                                                            <p>{item.commentaire}</p>
                                                            <div className="reviews-comments-item-footer fl-wrap">
                                                                <div className="reviews-comments-item-date"><span><i className="far fa-calendar-check"></i>{item.date}</span></div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                             

                                        );
                            
                                   })}

                                                     
                                                
                                                    
                                            
                                                                                                                     
                                                </div>
                                            </div>
                                        </div>
                                                                               
                                           
                                        
                                                                            
                                    </div>
                                </div>
                                                              
                            </div>
                        </div>
                    </section>

    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body>

                                                                <div className="tab listing " style={{paddingTop:"10px"}}>
                                                        <div id="filters-search" className="tab-content  first-tab " >
                                                            

                                                    <form  onSubmit={handleSubmit}  className="add-comment custom-form">
                                                    
                                                    <input type='hidden' value={slug} name='watcherid' />

                                                    <fieldset>
                                                        <label><i className="far fa-bookmark"></i> </label>
                                                        <input required type="text" name="adresse" placeholder={t('Adresse ou Nom du lieu') + "*"} />
                                                        <div className="clearfix"></div>
                                                          <textarea style={{marginBottom:"20px"}} required name='description' cols="40" rows="2" placeholder={t('Que souhaitez vous faire ? Décrivez ce que dois réaliser l’assistant...')}></textarea>
                                                    
                                                        
                                                        <div className='blockbg55'>
                                                           <i className="fal fa-calendar"></i>
                                                           <div className='x778'>{t('Choisissez une durée approximative')}*</div>
                                                           <div class="mb-3 row"><div class="col"><div class="form-check"><input required name="duree" type="radio" class="form-check-input" value="15mn"/><label title="" class="form-check-label"> 15mn</label></div></div><div class="col"><div class="form-check"><input required name="duree" type="radio" class="form-check-input" value="30mn"/><label title="" class="form-check-label">30mn</label></div></div></div>
                                                           <div class="mb-3 row"><div class="col"><div class="form-check"><input required name="duree" type="radio" class="form-check-input" value="45mn"/><label title="" class="form-check-label">45mn</label></div></div><div class="col"><div class="form-check"><input  required name="duree" type="radio" class="form-check-input" value="1h"/><label title="" class="form-check-label">1h</label></div></div></div>

                                                        </div>

                                                        <div className="listsearch-input-item clact date-container2">
                                                            <label><i className="fal fa-calendar"></i></label>
                                                            <input required type="text" placeholder={t('Date souhaitée')+"*"} autocomplete="off" name="datepicker-here-time" />
                                                            <span className="clear-singleinput"><i className="fal fa-times"></i></span>
                                                        <div className="daterangepicker ltr single opensright"><div className="ranges"></div><div className="drp-calendar left single" style={{display: "block"}}><div className="calendar-table"></div><div className="calendar-time"></div></div><div className="drp-calendar right" style={{display: "none"}}><div className="calendar-table"></div><div className="calendar-time"></div></div><div className="drp-buttons"><span className="drp-selected"></span><button className="cancelBtn btn btn-sm btn-default" type="button">Clear</button><button className="applyBtn btn btn-sm btn-primary" disabled="disabled" type="button">{t('Appliquer')}</button> </div></div></div>
                                                            <div className='blockbg55'>
                                                           <i className="fal fa-euro-sign"></i>
                                                           <div >{t('Prix minimum')}* {" "}({price} Fcfa)</div>
                                                             <Slider value={price} onChange={rangeSelector} valueLabelDisplay="auto" min={10} step={1} max={200} valueLabelFormat={value => <div>{value} Fcfa</div>} />
 
                                                        </div>

                      

                                                      
                                                    </fieldset>

                                                      <div className="listsearch-input-item">
                                                         
                                                          <button type="submit"  className="toggle-mission-btn tsb_act "  ><i className="fal fa-clock"></i> <span>{t('Envoyer ma demande')}</span></button>

                                                        
                                                                
                                                      </div>
                                                        

                                                    
                                                </form>                                    
                                                            
                                                        </div>
                                                    </div>

        </Modal.Body>
        <Modal.Footer>
         
        </Modal.Footer>
</Modal>


<Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body>

          <div className='center'>
            <img src='/images/send.png' />
          </div>

          <br/><br/>
            <div className='center'><h4 className='center'>{t('Félicitations')} </h4></div>
           <br/>
          <div className='center'>
             {t('Nous avons envoyé votre demande aux assistants selectionés, nous vous informerons de leur disponibilité pour votre mission.')}
          </div>

        </Modal.Body>
        <Modal.Footer>
          
          <Button variant="success" onClick={handleGoTo}>
            {t('Dashboard')} 
          </Button>
        </Modal.Footer>
</Modal>
  <input type="hidden" id='pageprofilassistant' value='1' />  
  <input type="hidden" id='pageprofilassistantdate' value={daterun} />          
        </div>
    );
}

export default Page;
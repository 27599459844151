



import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import {useHistory} from "react-router-dom";
function CustomModal({ showModal, setShowModal, names }) {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [contact, setContact] = useState("");
    const [address, setAddress] = useState("")

    const history = useHistory();

    const handleSubmit = async () => {

        // Construct the data to send to the API
        const formData = {
            user_email: email,
            name,
            contact,
            metierValue: names,
            address,
        };
        // Send the form data to the API
        // await fetch("http://127.0.0.1:8000/api/submit-form", {
        await fetch("https://proximitici.com/proximiti.ci/admin/public/api/submit-form", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': "*/*"
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Email Sent',
                    text: 'Your email has been sent successfully.',
                }).then((res)=>{
                    history.push("/");
                });
            })
            .catch((error) => {
                console.error("Error:", error);
                // Handle errors
            });
    }
    return (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <div className="modal-header-container">
                    <div className="circular-image">
                        <img src={"/images/logo.jpeg"} alt="Modal Image" style={{ width: '70px', height: '70px' }} />
                    </div>
                    <div className="modal-title">
                        <h4>Informations</h4>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    <label htmlFor="name">Nom</label>
                    <input
                        type="text"
                        id="name"
                        className="form-control"
                        placeholder="Entrez votre Nom"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">e-mail</label>
                    <input
                        type="email"
                        id="email"
                        className="form-control"
                        placeholder="Entrez votre Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="contact">Contact</label>
                    <input
                        type="number"
                        id="contact"
                        className="form-control"
                        placeholder="Entrez votre numéro de contact"
                        value={contact}
                        onChange={(e) => setContact(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="contact">Adresse</label>
                    <input
                        type="tel"
                        id="contact"
                        className="form-control"
                        placeholder="Entrez votre adresse"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Fermer
                </Button>
                <Button type="submit" onClick={async (e) => {
                    e.preventDefault();
                    e.currentTarget.innerText = "S'il vous plaît, attendez";
                    await handleSubmit();
                }} variant="primary">
                    Envoyer
                </Button>
            </Modal.Footer>
        </Modal>


    )
}

export default CustomModal
import React, { useEffect, useState } from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap"
import { useParams } from "react-router";
import { useHistory } from "react-router";
import { getMetier, getServices } from "../../../actions/pages";
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import PlacesAutocomplete from 'react-places-autocomplete';




const Page = () => {
  const { keyword: gottenKeyword } = useParams();
  const { type } = useParams();
  const { service_id } = useParams();
  const [keyword, setkeyword] = useState(gottenKeyword)
  const dispatch = useDispatch();
  const history = useHistory()
  const [show, setShow] = useState(false);
  const [active, setActive] = useState(0);
  const [active2, setActive2] = useState(0);
  const [activeValue, setActiveValue] = useState("");
  const [active2Value, setActive2Value] = useState("");
  const [type_service, setType] = useState(0);
  const [codepostal, setCodePostal] = useState('');
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [value, setValue] = useState(null);
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");

  const [show_zip_erro, setZipError] = useState(false);
  const [show_besoin_erro, setBesoinError] = useState(false);
  const handleClose = () => setShow(false);
  const { user } = useSelector(state => state.user)
  const { metiers, services } = useSelector(state => state.pages)

  const [langset, setLangset] = useState(false);


  const handleGoTo = () => {
    setZipError(false)
    setBesoinError(false)
    localStorage.setItem('codepostal', codepostal)
    localStorage.setItem('adresse', address)
    localStorage.setItem('lat', lat)
    localStorage.setItem('lng', lng)
    localStorage.setItem('metier', active2)
    localStorage.setItem('metierValue', active2Value)
    localStorage.setItem('besoin', active)
    localStorage.setItem('besoinValue', active==1?"Urgent":active==2?'Devis':"Urgence extrême");
    localStorage.setItem('type', type)
    localStorage.setItem('service_id', service_id)

    if (active2 == 0)
      alert('Veuillez choisir un métier')
    // else if (codepostal == '')
    //   setZipError(true)
    else if (active == '')
      setBesoinError(true)
    else
      history.push('/quel-est-votre-besoin-2/' + active2);

  }

  const handleCodePostal = codePostalVal => {
    setCodePostal(codePostalVal.target.value);
  };
  const handleActive = (s) => {
    if (active == s)
      setActive('')
    else
      setActive(s)
  }

  const handleActive2 = (s) => {
    if (active2 == s)
      setActive2('')
    else
      setActive2(s)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target)
    const formDataObj = Object.fromEntries(formData.entries())

  }


  const componentClicked = () => {


  }



  const handleChange = address => {

    setAddress(address);
  };
  const selectValuemap = (value) => {


    setAddress(value);
    setValue(value);
    geocodeByAddress(value).then(results => setCodePostal(results[0]['address_components'][results[0]['address_components'].length - 1]['long_name']))
    geocodeByAddress(value)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {

        setLat(lat);
        setLng(lng);
      }
      );

  }

  const renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions, loading }) => (

    <div className="autocomplete-container autocomplete-root">


      <input required name="adresse" {...getInputProps({
        placeholder: 'ex:37000',
        className: 'form-control utocomplete-input',
      })} />


      <div className="autocomplete-dropdown-container my-2">
        {loading && <div>Loading...</div>}
        {suggestions.map((suggestion, index) => (

          <div key={index} className="listitem" {...getSuggestionItemProps(suggestion)}>
            <span >{suggestion.description}</span>
          </div>
        ))}
      </div>
    </div>


  );


  useEffect(() => {
    if (service_id != undefined) setActive2(service_id)
    if (type == 1) dispatch(getMetier());
    if (type == 2) dispatch(getServices());
    if (langset == false) {
      setLangset(true)
    }



  }, [])

  return (
    <div className="besoin  " >
      <section className="gray-bg big-padding " id="besoin1">

        <div className="container small-container ">
          <div className='registerform'>

            <div className='row '>
              <div className='col-md-12'>
                <div className='progressbare'>
                  <div className='veh' style={{ left: '20%' }}><img src='/images/Groupe6849.png' /></div>
                  <div className="progressvalue" style={{ width: '25%' }}></div>
                  <div className='bat' ><img src='/images/Groupe6903.png' /></div>
                </div>
              </div>
            </div>


            <div className='row '>
              <div className='col-md-12'>

                {/* <div className="section-title"><h2><span>Votre code postal ? </span></h2> */}
                {/* <p className="center">Afin de nous assurer que nous avons bien un expert près de chez vous.</p> */}

                {/* </div> */}
                {/* <div className='custom-form col-md-4' >
                        <input type='hidden' placeholder="ex:37000"  id='codepostal' name='codepostal' value={codepostal}  onChange={handleCodePostal} />
                          
                            <PlacesAutocomplete  value={address} onChange={handleChange}   onSelect={selectValuemap} searchOptions={{ types: ['address'], componentRestrictions: {country: "fr"} }}>
                             {renderFunc}
                           </PlacesAutocomplete>



                      </div> */}
                {/* {
                        show_zip_erro ?
                        <div class="alert alert-danger custom-form col-md-4" style={{clear:'both'}} role="alert">
                          Veuillez saisir votre code postal
                        </div>
                        :
                        ''
                      } */}
                <div className='mrg78'></div>
                <div className='col-md-9'>


                  <div className="section-title"><h2><span>Quel est votre besoin ? </span></h2></div>

                  {
                    show_besoin_erro ?
                      <div class="alert alert-danger custom-form col-md-8" style={{ clear: 'both' }} role="alert">
                        Veuillez saisir votre besoin
                      </div>
                      :
                      ''
                  }
                  <ul className='liser'>

                    <li onClick={() => handleActive(1)} className={active == 1 && 'active'}>Urgent</li>
                    {/* <li onClick={()=>handleActive(2)} className={active==2 && 'active'}>Travaux</li> */}
                    <li onClick={() => handleActive(3)} className={active == 3 && 'active'}>Devis</li>
                    <li onClick={() => handleActive(4)} className={active == 4 && 'active'}>Urgence extrême</li>
                  </ul>

                  <div className='separet'></div>

                  <ul className='liser'>
                    {
                      type == 1 ?
                        metiers?.data?.map((item) => (
                          <li onClick={() => {
                            handleActive2(item.id)
                          setActive2Value(item.label);
                          }} className={active2 == item.id && 'active'}>{item.label}</li>
                        ))
                        :
                        ''
                    }
                    {
                      type == 2 ?
                        services?.data?.map((item) => (
                          <li onClick={() => handleActive2(item.id)} className={active2 == item.id && 'active'}>{item.label}</li>
                        ))
                        :
                        ''
                    }

                  </ul>

                  <div style={{ textAlign: 'right', marginBottom: '30px' }}>
                    <a onClick={() => handleGoTo()} class="btn btnform w200 "> Etape suivante  </a>
                  </div>


                </div>

                <div className='col-md-3 center'>
                  <img src="/images/plombier.png" style={{ width: "140px" }} />
                </div>



              </div>



            </div>

          </div>


        </div>



        <div className='sect2'>
          <div className="container small-container ">
            <div className='row'>
              <div className='col-md-12'>
                <div className="section-title"><h3><span>Transparence des prix </span></h3>
                  <p className="">Les prix sont connus à l'avance.</p></div>

              </div>
            </div>
          </div>
        </div>
        <br /><br /> <br /><br /><br />
        <div className='sect3'>
          <div className="container small-container ">

            <div className='cadrbs1'>

              <div className="process-wrap fl-wrap commentcafonctionne "><div className="default-desc margright mrgtop"><p>&nbsp;</p>
                <ul className="no-list-style row">



                  <li className="col-md-4">
                    <div className="process-item">
                      <div className="process-img">
                        <img src='/images/Iconeartisan.png' />
                      </div>

                      <p className='basecolor'>Nos artisans sont certifiés et labellisés.</p>
                    </div>
                  </li>

                  <li className="col-md-4">
                    <div className="process-item">
                      <div className="process-img">
                        <img src='/images/iconechrono.png' />
                      </div>

                      <p className='basecolor'>Traitement rapide des demandes.</p>
                    </div>
                  </li>

                  <li className="col-md-4">
                    <div className="process-item">
                      <div className="process-img">
                        <img src='/images/IconeIA.png' />
                      </div>

                      <p className='basecolor'>Un algorithme de matching client-dépanneur en fonction de la proximité et de l’incident.</p>
                    </div>
                  </li>

                </ul>
              </div>
              </div>

            </div>

          </div>

        </div>



      </section>

    </div>
  );
}

export default Page;
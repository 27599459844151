import React, { useEffect,useState } from "react";
import './styles.scss'; 
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal } from "react-bootstrap"
 
import { useParams } from "react-router";
import { apiClient } from "../../../actions/api"
import { useTranslation } from 'react-i18next';
import Carousel from 'react-bootstrap/Carousel'
import Slider from "react-slick";
import { AvisItem } from "../../../components";
import { useHistory } from "react-router";
import { BASE_URL } from "../../../components/config/keys";
import { SET_MISSION_INFOS } from "../../../constants/actions"

import {
        Elements,
        CardElement,
        useStripe,
        useElements,
      } from "@stripe/react-stripe-js";
      import { loadStripe } from "@stripe/stripe-js";


      import {checkCoupon } from "../../../actions/pages";

const Page = () => { 
    const autre_services_data = JSON.parse(localStorage.getItem('autre_services_data'));
    const stripe = loadStripe("pk_test_51LSGF1G7VfW1y640IKD9C5oLmKjNJD3WdB2jwYNOh0vqBOCdFtTCL58QrWONgbrOPA6ImuX0E2uYpk1aQJrWgQib00Ok5knJ1L");
    const { keyword:gottenKeyword } = useParams();
    const [keyword, setkeyword] = useState(gottenKeyword)
    const [coupon, setCoupon] = useState("")
    const [couponSucces, setCouponSucces] = useState("OK")
    const [couponValue, setCouponValue] = useState(0)
    const [errorCoupon, setErrorCoupon] = useState(false);
    const [commission, setCommision] = useState('');
     const history = useHistory()
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [price_intervention, setPrice] = useState(localStorage.getItem('min_price'))
    const [total, setTotal] = useState(autre_services_data.toPay)
    const [promo_price, setPricePromo] = useState(0)
 
    const { user } = useSelector(state => state.user)
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);

     var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide:0,
     responsive: [
    
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
             infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
             infinite: true,
            dots: false
          }
        }
      ]
   };
    
    const handleChangeCoupon = couponData => {
      setCoupon(couponData.target.value);
    };
   
    const handleAddCoupon =  () => {
      setCouponValue(0)
      setCouponSucces("OK")
      setErrorCoupon(false)
      if(coupon == '')
        setErrorCoupon(true)
      else
        dispatch(checkCoupon(coupon)).then((res) => {
          setCouponSucces(res.succes)
          setCouponValue(res.valeur)

          let promo_price_val = price_intervention*res.valeur/100
          setPricePromo(promo_price_val)

          let new_total = parseFloat(price_intervention)-parseFloat(promo_price_val)
          setTotal(new_total)
        }) 
    }

    const savecommande=(payement_inten_id)=>{            
        autre_services_data.payement_inten_id = payement_inten_id
        autre_services_data.total = total
        const data = {
            user_id:user.id,
            params:autre_services_data,
        }
            
        apiClient().post(`/saveAutreService`,data).then((res) => {
            if(res.data.succes==1){
                history.push("/autre-service-succes")
            }
            else{
                alert('Une erreur est survenue veuillez réessayer ultérieurement.')
            }
        })
    }





    useEffect(() => {
       
        console.log(autre_services_data);

        if(autre_services_data?.type_title=='Intervention Jardinage'){
            setTotal(autre_services_data?.besoinPrice)
        }
       if(autre_services_data?.type_title=='Intervention Coiffure'){
            setTotal(autre_services_data?.total * autre_services_data?.qte)
        }

       if(autre_services_data?.type_title=='Intervention Cours'){
            setTotal(autre_services_data?.besoinPrice)
        }

        if(autre_services_data?.type_title=='Intervention Déménagement Meubles Electro'){
            setTotal(autre_services_data?.besoinPrice)
        }

         if(autre_services_data?.type_title=='Intervention Déménagement'){
            setTotal(autre_services_data?.besoinPrice * autre_services_data?.qte )
        }

        if(autre_services_data?.type_title=='Intervention Garde Animaux'){
            setTotal(60 * autre_services_data?.qte )
        }

        if(autre_services_data?.type_title=='Intervention Garde Enfants'){
            setTotal(100 * autre_services_data?.qte )
        }
        if(autre_services_data?.type_title=='Intervention Informatique'){
            setTotal(autre_services_data?.besoinPrice )
        }
       if(autre_services_data?.type_title=='Intervention Jardinage'){
            setTotal(autre_services_data?.besoinPrice )
        }

        if(langset == false){
            setLangset(true)

            
        apiClient().get(`/get/price`).then((res) => {
          
               setCommision(res.data.data)
        
            
        })



        }
    }, [])
   
    return (
        <div className="besoin  " >
            <section className="gray-bg big-padding " id="besoin7">
                <div className="container small-container ">
                    <div className='registerform'>
                        <div className='row '></div>
                        <div className='row '>
                            <div className='col-md-5'>
                                <div className="section-title"><h2 ><span>Réservez votre Service</span></h2></div>
                                <br/> <br/> <br/> <br/>

                                <div className='alert alert-primary'>
                                   {commission}
                                </div>
                            </div>
                            <div className='col-md-7'>
                                <div className="cadrerecap">
                                    <div className='titlerecap'>Récapitulatif</div>
                                    <div className='bodyrecap '>
                                        <div className="ligne">
                                            <div className="col-md-9 ">{autre_services_data.type_title}</div>
                                            <div className="col-md-3 tright"> {parseFloat(total).toFixed(2)} Fcfa</div>
                                        </div>
                                        {/* {
                                            couponValue > 0 ?
                                            <div className="ligne">
                                                <div className="col-md-9">Remise ({couponValue} %)</div>
                                                <div className="col-md-3 tright"> {parseFloat(promo_price).toFixed(2)} Fcfa</div>
                                            </div>
                                            :
                                            ''
                                        } */}
                                        {/* <div className="ligne home" style={{padding:'0px',marginTop:'10px'}}>
                                            <div className='col-md-12'> 
                                                <div className="form-group--nest">
                                                    <input name='coupon'  onChange={handleChangeCoupon} className="form-control" type="text" placeholder="Code promo"/>
                                                    <button className="ps-btn" onClick={handleAddCoupon}>OK</button>
                                                </div>
                                                {
                                                    errorCoupon ?
                                                    <div class="alert alert-danger col-md-12" style={{clear:'both', marginTop:'5px'}} role="alert">
                                                    Veuillez saisir le code promo
                                                    </div>
                                                    :
                                                    ''
                                                }
                                                {
                                                    couponSucces == false ?
                                                    <div class="alert alert-danger col-md-12" style={{clear:'both', marginTop:'5px'}} role="alert">
                                                    Veuillez saisir le code promo
                                                    </div>
                                                    :
                                                    ""
                                                }
                                            </div>
                                        </div>
                                        <br/> */}
                                        <div className="ligne">
                                            <div className="col-md-9 "><strong>TOTAL</strong>  </div>
                                            <div className="col-md-3 tright"> <strong>{parseFloat(total).toFixed(2)} Fcfa</strong></div>
                                        </div>
                                        <br/>
                                    </div>
                                </div>
                                <br/>
                                <div className="cadrerecap">
                                    <div className='bodyrecap '>
                                        <div className="ligne">
                                            <div className="col-md-12"><img src='/images/paiemenetsecurise.jpg' /></div>
                                        </div>
                                        <div className="ligne paiments">
                                            <div className="col-md-12">
                                                <Elements stripe={stripe}>
                                                <CheckoutForm  savecommande={savecommande} user={user} total={parseFloat(total).toFixed(2)} history={history} BASE_URL={BASE_URL}  />
                                                </Elements>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Page;

const CheckoutForm = ({savecommande,total,user,history,BASE_URL}) => {
    const [isPaymentLoading, setPaymentLoading] = useState(false);
    
    const stripe = useStripe();
    const elements = useElements();


     const getSetupClientSecret=()=>{

        const params = {
          total: total ,
          customer_id: user.id
        }
        return fetch(BASE_URL+'/payment/stripe', {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          method: 'post',
          body: JSON.stringify(params)
        }).then(response => response.json())
          .catch(error => {
            console.log("stripe_get_secret_setup_intents error", error)
          });
      } 


    const payMoney = async (e) => {
      e.preventDefault();
        
        // history.push('/votre-paiement-accepte/')
      // return false;
      
      if (!stripe || !elements) {
        return;
      }


      setPaymentLoading(true);
      
      const info = await getSetupClientSecret();
      const clientSecret = info.stripe.client_secret;
      const paymentResult = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: user.prenom+ +user.nom,
          },
        },
      });
      setPaymentLoading(false);
      console.log(paymentResult);
      if (paymentResult.error) {
        alert(paymentResult.error.message);
      } else {
         if (paymentResult.paymentIntent.status == "requires_capture") {
             console.log(paymentResult.paymentIntent.id);
            savecommande(paymentResult.paymentIntent.id);
        }
      }
    };


 

    return (
      <div>
        <div
          style={{
            maxWidth: "500px",
            margin: "0 auto",
          }}
        >
          <form
            style={{
              display: "block",
              width: "100%",
            }}

            onSubmit = {payMoney}
           
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CardElement
                className="card"
                options={{
                  style: {
                    base: {
                      backgroundColor: "white"
                    } 
                  },
                }}
              />
              <button
                className="pay-button"
                disabled={isPaymentLoading}
              >
                {isPaymentLoading ? "Loading..." : "Payer "+total+" Fcfa"}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
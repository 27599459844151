import React, { useEffect,useState } from "react";
import './styles.scss'; 
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal } from "react-bootstrap"
import { AssistantItem } from "../../components";
import { useParams } from "react-router";
import { apiClient } from "../../actions/api"
import { useTranslation } from 'react-i18next';
import {getContentPage } from "../../actions/pages";
import Switch from '@material-ui/core/Switch';
import { AUTHENTICATE_USER, LOGOUT_USER } from "../../constants/actions";
import { BASE_URL } from "../../components/config/keys";
import { useHistory } from "react-router-dom";

import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";



const Page = () => {

     const stripe = loadStripe("pk_test_51LSGF1G7VfW1y640IKD9C5oLmKjNJD3WdB2jwYNOh0vqBOCdFtTCL58QrWONgbrOPA6ImuX0E2uYpk1aQJrWgQib00Ok5knJ1L");
   
    const { keyword:gottenKeyword } = useParams();
    const [keyword, setkeyword] = useState(gottenKeyword)
    const { user } = useSelector(state => state.user)
     const history = useHistory()

   const handleCloseDetail = () => setShowDetail(false);
  const [showDetail, setShowDetail] = useState(false);
  const [abonnement_price, setAbonnPrice] = useState(0);
  const [type_abonnement, setTypeabonnement] = useState(user?.type_abonnement);
  const [abonnement_current, setabonnement_current] = useState('');
  const [abonnement_category, setabonnement_categorie] = useState('');



    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
     const { t, i18n } = useTranslation();
    const [langset, setLangset] = useState(false);
    const [changeNotif, setchangeNotif] = useState(0);
    const [typeabonnement, setTypeAbonnement] = useState('Particuliers'); //1 part 2: Pro, 3: gestion bien

    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const { contents } = useSelector(state => state.pages)
    const label = { inputProps: { 'aria-label': '' } };


    const [abbonements, setAbonnements] = useState({
                                                   'Particuliers':[
                                                   {'title':'Bronze','price':5,'description':'60Fcfa* de gains annuels (basé sur 2 interventions)','items':['Déplacement gratuit pour tous types d’intervention (2 interventions/an à intervalle de 2 mois)',"Jusqu'à 50% de gain sur les frais de déplacement"]},
                                                   {'title':'Silver','price':15,'description':'360Fcfa* de gains annuels (basé sur 2 interventions)','items':['Gratuité de toute intervention dans le logement dans la limite de 2 interventions simples et non invasives/an',"Conseil et accompagnement d'activité","Jusqu'à 100% de gain sur les frais de déplacement, de main d’œuvre et de fournitures"]},
                                                   {'title':'Gold','price':20,'description':'960Fcfa* de gains annuels (basé sur 4 interventions)','items':["Gratuité de toutes interventions et dépannage express H24","Limite de 4 interventions/an**","Service de conseil juridique illimité","Aide à la déclaration fiscale et transmission de documents administratifs","Service d'accompagnement comptable, fiscal et patrimonial","Export de donnée CSV (Excel)","Jusqu'à 100% de gain sur les frais de déplacement, de main d’œuvre et de fournitures"],'pop':1},
                                                   {'title':'Platinium','price':30,'description':'Gain illimité sur les interventions urgentes','items':["Gratuité de toutes interventions urgente en illimité","Déplacement gratuit","Conseiller dédié","Recherche personnalisée","Assistance travaux et juridique","Intervention assainissement en partie privative","Double de clef standard","Jusqu'à 100% de gain sur les frais de déplacement, de main d’œuvre et de fournitures"]}
                                                   ],
                                                   'Professionnels':[
                                                   {'title':'Classic','price':0,'description':'','items':["10Fcfa par intervention < à 150Fcfa","20% commission intervention > à 150Fcfa","25% commission intervention > à 1000Fcfa","Accès aux outils de gestion devis, factures et messages sauf fournitures et stocks"]},
                                                   {'title':'Basic','price':5,'description':'','items':["1Fcfa par intervention < à 150Fcfa","10% de commission par intervention > à 150Fcfa","Accès aux outils de gestion devis, factures et messages sauf fournitures et stocks","Service d'assurance juridique limité","Jusqu'à 100% de gain sur les frais de déplacement, de main d’œuvre et de fournitures"],'pop':1},
                                                   {'title':'Premium','price':20,'description':'','items':["0Fcfa de commission si plus de 5 interventions par mois","Accès aux outils de gestion devis, factures, messages, fournitures et stocks","Service de conseil juridique illimité","Visibilité auprès des autres utilisateurs","Aide à la déclaration fiscale et transmission de documents administratifs","Service d'assurance juridique","Service d'accompagnement comptable, fiscal et patrimonial","Export de donnée CSV (Excel)"]}
                                                   ],
                                                   'Gestionnaires':[
                                                   {'title':'Particulier','price':0,'description':'Sans engagement (valable 1 mois)','items':["*5Fcfa par jour de réservation sur chaque réservation de logement en location hors frais bancaires et frais de ménage","Mise en vente du bien gratuite","Accès à l'abonnement dépannage","Réservation du logement saisonnier ou courte durée"]},
                                                   {'title':'Pro +','price':5,'description':'','items':["1% de commission sur chaque paiement de loyer par bien","Publication illimitée de biens","Accès à tous les outils de gestion","Dépannage sur devis"]},
                                                   {'title':'Pro','price':0,'description':'','items':["3% de commission par mois sur chaque paiement de loyer mensuel","Publication limitée à 1 bien","Accès aux outils de gestion sauf message et historique des transactions","Dépannage sur devis"]}
                                                   ]

                                                   }
                                                   );

   
 
    const handleSubmit = (e) => {
        e.preventDefault();
        setShow(true)
    }


      const choiseAbonnement = (e,item,index,t) => {
        e.preventDefault();
        if(user?.id){

               setabonnement_current(index)
               setabonnement_categorie(t)
               setAbonnPrice(changeNotif== 0 ? item?.price : item?.price * 12 )

               if(item?.price > 0){

                 setShowDetail(true)

               }
               else{

                saveAbonnement('0000')

               }

               

        }
        else{

            alert('Veuillez vous connecter à votre compte !')
            history.push('/login')
        }
       

    }



     const handleGoTo = (e) => {
        e.preventDefault();
         
    }
     useEffect(() => {
        
        //dispatch(getContentPage({},'RGPD'));
        if(langset == false){
            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }
 
    }, [])


   const saveAbonnement=(payement_inten_id)=>{
          const params = {
            payement_inten_id:payement_inten_id,
            user_id:user.id,
            user_type:user?.type==2 ?'client' : 'pro',
            type_abonnement:abonnement_current,
            category_abonnement:abonnement_category,
            abonnement_price:abonnement_price,
            duree:changeNotif==0 ? 'mois' : 'an'
          };
          
          apiClient().post(`/professional/saveabonnement`,params)
          .then((res) => {
            if(res.data.success == 1){
                 setShowDetail(false)
                 dispatch({ type: AUTHENTICATE_USER, payload:res.data?.user})
                 setTimeout(function(){
                    if(user?.type == 2){
                         history.push('/client/abonnement/')
                    }
                    else{
                         history.push('/assistants/abonnement')

                    }
                   
                 }, 1000);

            }
            else{
              alert('Une erreur est survenue veuillez réessayer ultérieurement.')
            }
            
             
          })
      
    } 



     const returnAboonement = (t) => {

        let listabonnement= t=='Particuliers' ? abbonements['Particuliers'] : t=='Professionnels' ? abbonements['Professionnels'] : t=='Gestionnaires' ? abbonements['Gestionnaires'] : []
       
       return (

         <div className='items itemsparticulaires' >

                         {
                            
                             listabonnement?.map((item,index) => (


                            <div className='item' key={index}>
                            <div>

                              <div className='title'>{item?.title}</div>
                              {
                                item?.price > 0 ?
                                  <h5>{    changeNotif== 0 ? item?.price : item?.price * 12 }Fcfa<sup>/{changeNotif==0 ? 'mois' : 'an'}</sup></h5>
                                  :
                                  <h5>Gratuit</h5>


                              }
                              
                               <p>{item?.description}</p>

                               <ul>
                               {
                              
                                 item['items']?.map((rw,ri) => (

                                   <li key={ri}><img src="/images/icones/iconechecklisteformule.png" /><span>{rw}</span></li>

                                ))


                               }

                                
                               </ul>
                               </div>

                               <div className='center' styles={{with:'100%'}}><button class="offeractio" onClick={(e)=>choiseAbonnement(e,item,index,t)}> Souscrire </button></div>


                           </div>



                              ))
                         }

                         
                      

                         </div>

        )
    }
   
    return (
        <div className="formules" >


  
           <section className="big-padding " style={{paddingBottom:25}}>
                <div className="container">

                <div className='row'>
                <div className='col-md-2'></div>
                     <div className='col-md-8'>
                        
                          

                             <div className="section-title" >
                              
                         
                                            <h2>3 formules pour répondre à vos besoins</h2>
                                            <p className="center">Sans engagement et selon vos envies.</p>
                            
                                         

                              </div>
                         


                     </div>
                      <div className='col-md-2'></div>

                  </div>


                   <div className='row row9998'>
                    <div className='col-md-2'></div>
                     <div className='col-md-8'>
                         <div className='item labelswt'>
                                                    <label > 
                                                    <span className='inlineblk' > Mensuel </span> 
                                                    {
                                                      changeNotif == 1 ?
                                                      <Switch {...label} defaultChecked size="small" onChange={() => setchangeNotif(0)} color="primary"  />
                                                      :
                                                      <Switch {...label} size="small" color="secondary" onChange={() => setchangeNotif(1)}  />
                                                    }
                                                    <span className='inlineblk' >Annuel</span>
                                                    </label>
                         </div>


                     </div>
                      <div className='col-md-2'></div>
                   </div>

                
                </div>
           </section>


           <section className='offerabonnement'>
           <div className='container'>
             <div className='row offerheader'>
                    <div className='col-md-2'></div>
                     <div className='col-md-8'>

                         <div className='items'>
                           <div className={typeabonnement=='Particuliers' ? 'item active' : 'item'}  onClick={()=>setTypeAbonnement('Particuliers')}>Particuliers</div>
                           <div className={typeabonnement=='Professionnels' ? 'item active' : 'item'} onClick={()=>setTypeAbonnement('Professionnels')} >Professionnels</div>
                           <div className={typeabonnement=='Gestionnaires' ? 'item active' : 'item'}  onClick={()=>setTypeAbonnement('Gestionnaires')} >Gestionnaires de biens</div>

                         </div>

                     </div>
                      <div className='col-md-2'></div>
             </div>
             </div>

             <div className='offerabbody'>



            <div className='container'>
             <div className='row offeritems'>
                    <div className='col-md-2'></div>
                     <div className='col-md-8'>

                     {
                         typeabonnement=='Particuliers' && returnAboonement('Particuliers')
                     }
                      {
                         typeabonnement=='Professionnels' && returnAboonement('Professionnels')
                     }
                     {
                         typeabonnement=='Gestionnaires' && returnAboonement('Gestionnaires')
                     }


                     {
                        typeabonnement=='Particuliers' &&
                        <div className='description'><p>* Les pourcentages de gain sont basés sur les coûts moyens de déplacement, de main d’œuvre et de fournitures pour les interventions d'artisans. Les gains annuels sont basés sur le nombre d'interventions incluses dans chaque abonnement. Les chiffres sont donnés à titre indicatif et peuvent varier selon les besoins individuels de chaque utilisateur. La souscription de l’abonnement est soumise à un délai de carence de 30 jours.</p><br/><p>** hors assainissement, vitrage, chaudière, pose et dépose de WC, salle de bain et portail à intervalle de 2 mois. Intervention uniquement en partie privative visible et accessible.</p></div>
                     }

                      {
                        typeabonnement=='Professionnels' &&
                        <div className='description'><p>** hors assainissement, vitrage, chaudière, pose et dépose de WC, salle de bain et portail à intervalle de 2 mois. Intervention uniquement en partie privative visible et accessible.</p><br/><p>** hors assainissement, vitrage, chaudière, pose et dépose de WC, salle de bain et portail à intervalle de 2 mois. Intervention uniquement en partie privative visible et accessible.</p></div>
                     }

                      {
                        typeabonnement=='Gestionnaires' &&
                        <div className='description'><p>* Exemple : pour une réservation de 3 jours pour la nuit à 50Fcfa (15Fcfa pour la plateforme et 135 pour l’hôte). Les pros ou les particuliers peuvent souscrire à d'autres abonnements Proximiti. Les frais de conciergerie dépendent du tarif imposé par le professionnel en charge de la conciergerie.</p><br/><p>** hors assainissement, vitrage, chaudière, pose et dépose de WC, salle de bain et portail à intervalle de 2 mois. Intervention uniquement en partie privative visible et accessible.</p></div> }

                         

                     </div>
                      <div className='col-md-2'></div>
             </div>
             </div>
                





             </div>


           </section>


            <Modal show={showDetail} onHide={handleCloseDetail}>
                  <Modal.Header closeButton>
                        Abonnement
                  </Modal.Header>
                  <Modal.Body>

                  <div className="cadrerecap">
                            
                            <div className='bodyrecap '>
                              <div className="ligne">
                                <div className="col-md-12">
                                    <img src='/images/paiemenetsecurise.jpg' />
                                  </div>
                              
                              </div>
                                <div className="ligne paiments">
                                <div className="col-md-12">
                        <Elements stripe={stripe}>
                            <CheckoutForm saveAbonnement={saveAbonnement} user={user} total={abonnement_price} history={history}  BASE_URL={BASE_URL} />
                        </Elements>
                                  </div>
                              
                              </div>


                            </div>

                        </div>
                  </Modal.Body>
          </Modal>

 
             
        </div>
    );
}

export default Page;


const CheckoutForm = ({saveAbonnement,total,user,history,BASE_URL}) => {
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  
  const stripe = useStripe();
  const elements = useElements();


   const getSetupClientSecret=()=>{

      const params = {
        total: total ,
        customer_id: user.id
      }
      return fetch(BASE_URL+'/payment/stripe', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'post',
        body: JSON.stringify(params)
      }).then(response => response.json())
        .catch(error => {
          console.log("stripe_get_secret_setup_intents error", error)
        });
    } 


  const payMoney = async (e) => {
    e.preventDefault();
      
      // history.push('/votre-paiement-accepte/')
    // return false;
    
    if (!stripe || !elements) {
      return;
    }


    setPaymentLoading(true);
    
    const info = await getSetupClientSecret();
    const clientSecret = info.stripe.client_secret;
    const paymentResult = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: user.prenom+ +user.nom,
        },
      },
    });
    setPaymentLoading(false);
    console.log(paymentResult);
    if (paymentResult.error) {
      alert(paymentResult.error.message);
    } else {
       if (paymentResult.paymentIntent.status == "requires_capture") {
           console.log(paymentResult.paymentIntent.id);
          saveAbonnement(paymentResult.paymentIntent.id);
      }
    }
  };




  return (
    <div>
      <div
        style={{
          maxWidth: "500px",
          margin: "0 auto",
        }}
      >
        <form
          style={{
            display: "block",
            width: "100%",
          }}

          onSubmit = {payMoney}
         
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CardElement
              className="card"
              options={{
                style: {
                  base: {
                    backgroundColor: "white",
                    width: "100%",
                    
                  } 
                },
              }}
            />
            <button
              className="pay-button"
              disabled={isPaymentLoading}
            >
              {isPaymentLoading ? "Loading..." : "Payer "+total+" Fcfa"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
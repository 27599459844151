import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card,Modal} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { DashbordMenu,DashbordHeader,DashbordMenuClient,DashbordHeaderClient } from "../../../components";
import { getMissionById,EditMission,getDetailMission,setFiles,acceptMission,valideMission } from "../../../actions/pages";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router";
import { apiClient } from "../../../actions/api"

const Page = () => {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { slug } = useParams()
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const { user } = useSelector(state => state.user)
    const { detailmission,files } = useSelector(state => state.pages)
    const [langset, setLangset] = useState(false);
    const [price, setPrice] = useState(false);
    const [newprice, setNewPrice] = useState("");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);


    const [loading, setLoading] = useState(false);
    const [succesAccept, setSuccesAccept] = useState(2);
    const [succesValide, setSuccesValide] = useState(2);
    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
    const handleSubmit = async (e) => {
 
         e.preventDefault();
        const params={
            newprice:newprice ? newprice : detailmission.price
        }
        dispatch(EditMission(params,user.id,slug,user.emailToken,'editbywatcher','watcher'));
        setShow(true)
        
      
    }

    const updatetarifdevis = async (e) => {
       e.preventDefault();
      const formData = new FormData(e.target)
      const formDataObj = Object.fromEntries(formData.entries())

      if(parseInt(formDataObj.tarif) < parseInt(detailmission.price)){
        alert('Veuillez saisir un prix supérieur à  '+ detailmission.price)
        return false;
      }
 
      dispatch(acceptMission(user.id, slug,formDataObj?.typedevis==1 ? 4 : 6,formDataObj.tarif,formDataObj.comment,formDataObj.codeclient)).then((res) => {
        if(res.succes == -1){
           alert('Le code client saisi est incorrect !')
        }
        else if(res.succes == 3){
          setSuccesAccept(6)
        }
        else if(res.succes == true){
          setSuccesAccept(1)
        }
        else{
          setSuccesAccept(0)
         
        }
      }) 

      setShow2(false)
        
      
    }



     const handlePrice = async (e) => {
         setPrice(!price)
        
      
    }

    const accepterMission = () =>{
      dispatch(acceptMission(user.id, slug,1,0,'')).then((res) => {
        if(res.succes)
          setSuccesAccept(4)
        else
          setSuccesAccept(0)
      }) 
    }
    const validerMission = () =>{
      dispatch(valideMission(user.id, slug)).then((res) => {
        if(res.succes)
          setSuccesValide(1)
        else
          setSuccesValide(0)
      }) 
    }

    const createFile = (file) => {
      
      setLoading(true)
      const data = new FormData();
      data.append('membreId', user.id);
      data.append('file', file);
      data.append('type', 'videoimg');
      data.append('id_mission', slug);

      apiClient().post(`/insertimage`,data)
            .then((res) => {
              const params={
                id_mission:slug
              }
              dispatch(setFiles(params,user.id,'',user.type==2 ? 'watcher' : 'user','select',user.emailToken,''));
               setLoading(false)             
            })

  }

    const uploadFIle = (e) => {
        

    let files = e.target.files || e.dataTransfer.files;
    if (!files.length)
    return;

      if(files[0].type=='video/mp4' || files[0].type=='video/quicktime' || files[0].type=='video/mov' ||files[0].type=='image/png' || files[0].type=='image/jpeg' || files[0].type=='image/jpg' || files[0].type=='image/gif' ){
              if(files[0].size <= (10*1000000)){
                 createFile(files[0]);
              }
              else{

                 alert(t('Votre pièce-jointe ne doit pas dépasser cette taille: 10 MO.'));
              }
      }
      else{
         alert(t("Ce format n'est pas supporté ! Le format accepté est (PNG,JPEG,JPEG,GIF,MOV,MP4)"));
      }

        const param = {
                id:user.id,
              //files_img:this.state.files,
            }
    }


    useEffect(() => {
    
          const params={
          //  id:user.id,
          //  token:user.emailToken,
          //  id_mission:slug,
          //  type:'watcher'
          id_mission:slug
        }

        dispatch(getDetailMission(params));

        //  const params2={
        //         id_mission:slug
        //   }

        // dispatch(setFiles(params2,user.id,'',user.type==2 ? 'watcher' : 'user','select',user.emailToken,''));
    
        
        if(langset == false){
             

            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }

    }, [])
    
 
    return (
        <div className="myccount responses " >


            <section className="gray-bg main-dashboard-sec dashboard detailmission " id="sec1">

                    <div className="container">
                            
                            <div className="col-md-3">
                               
                                    <DashbordMenu src='profil' />
                                  
                                 
                            </div>


                            <div className='col-md-9 dashbordassitantcontent'>

                                <div className='tab' id=''>
           
          
              <DashbordHeader />
            
                                 <div className="dashboard-title   fl-wrap">
                                  <br/><br/>
                                    <h3 className='htri1' style={{border:0}}>{t('Ticket travaux')} - Ref #{detailmission.ref} </h3>
                                    <br/>

                                    <div className="user-profile-header fl-wrap">
                                            <div className="user-profile-header_media fl-wrap">
                                                <div className="bg bg-parallax-wrap" ></div>
                                                <div className="user-profile-header_media_title flx00983">

                                                  <div>
                                                     {
                                                           <h4>{detailmission.prenom} {detailmission.nom}</h4>
                                                     }

                                                  </div>

                                                  <div className='p339383'>
                                                      <img src={detailmission.qr_code} style={{width: '80px'}}></img>
                                                  </div>
                                                   
                                                </div>
                                                <div className="user-profile-header_stats">
                                                     
                                                </div>
                                                {
                                                detailmission.statwatcher == 1 &&


                                                <div className="follow-btn color2-bg" onClick={handleSubmit} >{t('Accepter la mission')} <i className="fal fa-check"></i></div>
                                                


                                                }
                                            </div>
                                            <div className="user-profile-header_content fl-wrap">
                                                <div className="user-profile-header-avatar">
                                                    <img src={'/images/icones/iconeprofilproblocabonnementmenu.png'} alt="" />
                                                </div>
                                                <p> {detailmission.description}   </p>
                                                

                                                <div className="list-author-widget-contacts list-item-widget-contacts bwc-padside">

                                                     <div className='row'>

                                                       <div className='col-md-6'>

                                                           <ul className="no-list-style">
                                                            <li><span><i className="fal fa-map-marker"></i> {t('Adresse')}:</span> <a href="javascript:void(0)">{detailmission.adresse}</a></li>
                                                            <li><span><i className="fal fa-check"></i> {t('Catégorie')}:</span> <a href="javascript:void(0)">{currentlang=='fr' ?  detailmission.label : detailmission.label_en }</a></li>
                                                            {/* <li><span><i className="fal fal fa-calendar"></i> {t('Durée approximative')}:</span> <a href="javascript:void(0)">{detailmission.duree}</a></li> */}
                                                            <li><span><i className="fas fa-euro-sign"></i> {t('Prix')}:</span> <a href="javascript:void(0)">Fcfa{detailmission.price}</a></li>
                                                             {
                                                              detailmission?.professional?.price &&
                                                              <li><span><i className="fas fa-euro-sign"></i> {t('Nouveau Prix')}:</span> <a href="javascript:void(0)">Fcfa{detailmission?.professional?.price}</a></li>
                                                             }
                                                             
                                                               {
                                                              detailmission.watcher &&
 <li><span><i className="fas fa-euro-sign"></i> {t('Prix accepté')}:</span> <a href="javascript:void(0)">Fcfa{detailmission.pricewatcher}</a></li>
                                                                 
                                                             }


                                                        </ul>

                                                       </div>

                                                       <div className='col-md-6'>


                                                        <ul className="no-list-style">
                                                           
                                                             <li><span><i className="fal fa-calendar"></i> {t('Code Postal')}:</span> <a href="javascript:void(0)">{detailmission.codepostal}</a></li>
                                                            <li><span><i className="fal fa-info"></i> {t('Intervention')}:</span> <a href="javascript:void(0)">{detailmission.demande}</a></li>
                                                            <li><span><i className="fal fa-calendar"></i> {t('Date souhaitée')}:</span> <a href="javascript:void(0)">{detailmission.date_mission}&nbsp;  {detailmission.duree == 1 ? 'Matin':(detailmission.duree == 2 ? 'Après-midi':'Soir')}</a></li> 
                                                           


                                                        </ul>

                                                          <div>
                                                          </div>



                                                       </div>


              <div className='col-md-12' style={{clear:'both'}}>
                <div className="row" style={{paddingTop:20, borderTop:'1px solid #ccc'}}>
                  {
                    detailmission.image1_url != '' ?
                    <div className='col-md-4'><img style={{width:'100%'}} src={detailmission.image1_url}></img></div>
                    :
                    ""
                  }   
                  {
                    detailmission.image2_url != '' ?
                    <div className='col-md-4'><img style={{width:'100%'}} src={detailmission.image2_url}></img></div>
                    :
                    ""
                  }  
                  {
                    detailmission.image3_url != '' ?
                    <div className='col-md-4'><img style={{width:'100%'}} src={detailmission.image3_url}></img></div>
                    :
                    ""
                  }   
                </div>
              </div>
              <div className='col-md-12' style={{clear:'both'}}>
                <div className="row">
                  {
                    detailmission.video1_url != '' ?
                    <div className='col-md-4'>
                      <video controls style={{position:'relative'}}>
                        <source src={detailmission.video1_url} type="video/mp4" />
                      </video>
                    </div>
                    :
                    ""
                  }   
                  {
                    detailmission.video2_url != '' ?
                    <div className='col-md-4'>
                      <video controls style={{position:'relative'}}>
                        <source src={detailmission.video2_url} type="video/mp4" />
                      </video>
                    </div>
                    :
                    "" 
                  }  
                  {
                    detailmission.video3_url != '' ?
                    <div className='col-md-4'>
                      <video controls style={{position:'relative'}}>
                        <source src={detailmission.video3_url} type="video/mp4" />
                      </video>
                    </div>
                    :
                    ""
                  }   
                </div>
              </div>

                                                       
                                    {
                                      succesAccept == 0 ?
                                      <div class="alert alert-danger col-md-12" style={{clear:'both', marginTop:'5px'}} role="alert">
                                         Désolé ticket déjà accepté !
                                      </div> 
                                      :
                                      ""
                                    }
                                    {
                                      succesAccept == 1 ?
                                      <div class="alert alert-success col-md-12" style={{clear:'both', marginTop:'5px'}} role="alert">
                                        Ticket bien accepté ! veuillez attendre la validation du client.
                                      </div>
                                      :
                                      ""
                                    }

                                      {
                                      succesAccept == 4 ?
                                      <div class="alert alert-success col-md-12" style={{clear:'both', marginTop:'5px'}} role="alert">
                                        Ticket bien validé !.
                                      </div>
                                      :
                                      ""
                                    }


                                       
                                    {
                                      succesValide == 1 ?
                                      <div class="alert alert-success col-md-12" style={{clear:'both', marginTop:'5px'}} role="alert">
                                        Ticket est validé par le client !
                                      </div>
                                      :
                                      ""
                                    }
                                                       <div className='col-md-6'></div>
                                                       {
                                                        (succesAccept == 2 &&  detailmission?.status == 0 )  ?
                                                        <div className='col-md-6'>
                                                           <div className='flx881 ' >
                                                                 <button onClick={accepterMission} style={{background:'#384F95', marginRight:10}} className="btn float-btn color2-bg btn98996 w100">Valider le ticket</button>
                                                                 <button onClick={()=>setShow2(true)}  style={{background:'#E99F28'}} className="btn float-btn color2-bg btn9886 w100">Modifier le ticket</button>
                                  

                                                           </div>
                                                         </div>
                                                        :
                                                        ""
                                                       }


        {

            detailmission?.status== 2 &&


                 <div className='row '   >
                                  
                                <div className='col-md-12'>

                                    <a href={detailmission?.facture_artisant} download  style={{background:'#073460'}} className="btn float-btn color2-bg  w100">Télécharger la facture <img src="/images/icones/iconetélécharger.png" style={{width: 18, marginLeft:20}} /></a>
                                  

                                </div>  

                                {

                                  (detailmission?.token !=1 && succesAccept !=6 ) && 

                                       <div className='col-md-12'>
                                   <br/><br/>
                                <p className='alert alert-warning'>Veuillez valider le ticket si vous avez bien effectué la mission </p>


                                   <form className='row' onSubmit={updatetarifdevis} style={{padding: 20}}>
                                      <input type='hidden' name="typedevis" value='2' />
                                      <div style={{marginBottom:20}}>
                                         <label style={{color:"#073460"}}>Code client</label>
                                         <input placeholder="" name="codeclient" type="text" className="form-control"   required/> 
                                      </div>
                                      <button type='submit' style={{background:'#E99F28',    width: '100%'}} className="btn float-btn color2-bg btn9886 w100">Marquer le ticket comme effectué</button>
            
                                   </form>

                      


                                </div> 

                                  
                                }


                                                     
                                                                
                                  
                 </div>

           
                                                      
          


          }

                                                      {
                                                        (detailmission.status == 4) &&
                                                          <div class="alert alert-success col-md-12" style={{clear:'both', marginTop:'5px'}} role="alert">
                                                           Ticket est bien accepté ! Veuillez attendre la validation du client.
                                                         </div>

                                                      }
                                                    
                                                        
                                                     </div>
                                                     <br/>



                                                     <div className='row'>
                                                       <div className='col-md-6'>
                                                       {
                                                        detailmission.statwatcher==1 &&
                                                           <Form.Group className="mb-4">
                                                               <Form.Check onClick={()=>handlePrice()} type="checkbox" label={t('Je veux modifier le prix !')}   />
                                                            </Form.Group> 
                                                       }
                                                       
                                                       </div>
                                                        <div className='col-md-6'>

                                                        {
                                                            price && <Form.Control    type="number" min={detailmission.price} className="form-control"  value={newprice} onChange={e => setNewPrice(e.target.value)} placeholder={t('Votre prix..')} />
                                                        }
 
                                                         
 
                                                       </div>


                                                     </div>

                                                       
                                                </div>

                                                <br/>

                        
  

                                              {
                                                detailmission.statwatcher == 1 &&
                                                <div className="follow-btn color2-bg btn9987"  onClick={handleSubmit}>{t('Accepter la mission')} <i className="fal fa-check"></i></div>
                                              }


                                              {
                                                (detailmission.statwatcher == 3 || detailmission.statwatcher == 4) &&

                                                <div className=''>

                                      <div className="custom-form">
                                        <div className="row">

                                          <div className='col-md-12'>

                                              <p className='para alert alert-warning center'>{t('Uploader un ou plusieurs fichiers pour faire valider votre mission')}.</p>

                                          </div>
                                        </div>
                                    </div>              

                                    <div className="custom-form">
                                        <div className="row">

                                          <div className='col-md-6'>

                                            <label  for="imgprofil" className="color-bg edit-prof_btn labbnrd">

                                              <input
                                                type="file"
                                                name="imgprofil"
                                                className='inputFileInvis'
                                                onChange={(event) => {
                                                  
                                                  uploadFIle(event);
                                                }}
                                              />
                                              {
                                                 
                                                t('Uploader un fichier (photo, vidéo..)')
                                              }

                                                

                                            </label>
                                          </div>
                                          <div className='col-md-6'>

                                          <ul>


                                            {files.map((item,index) => {
                                       let newindex=index+1;
                                       return (
                                            <li  key={index} className=''><a href={item.url} download className="">{item.file}</a></li>
                                        );
                            
                                     })}

                                          </ul>


                                          

                                    

                                          

                                          { 
                                            loading &&  <img src='/images/loading.gif' />
                                          }

                                          { 
                                            loading &&  <p className='alert alert-primary'>
                                               {t('Cette operation peut durer plusieurs minutes, merci de ne pas fermer cette fenêtre..')}
                                            </p>
                                          }
 
                                           
                                          </div>

                                        </div>
                                    </div>
 

                                                </div>
                                                
                                              }



                                               
                                               {
                                                /*

                                                 <div className="follow-btn red-bg btn9987 btn776">Contacter le client <i className="fal fa-envelope"></i></div>
 
                                                */
                                               }
                                               

                                            </div>



                                        </div>






                                </div>
                             </div>

                             


                                 
                             </div>


                              

                    </div>
                    
            </section>


<Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton style={{    border: 0}}>
          
        </Modal.Header>
        <Modal.Body>

         <div classNam='row'>

           <div className='col-md-12'>

               <h3 style={{fontSize:17, color:'#073460', textAlign:'center'}}>Modification du tarif</h3>
                <br/>
                <div className='cad778'>
                 <div className='ff9'>
                   <label>N° de l'intervention</label>
                   <strong>{detailmission.ref}</strong>
                 </div>
                   <div className='ff9'>
                   <label>Tarif minimum</label>
                   <strong>Fcfa{detailmission.price}</strong>
                 </div>


                </div>


           </div>


         </div>

         <form className='row' onSubmit={updatetarifdevis} style={{padding: 20}}>


         <input type='hidden' name="typedevis" value='1' />
          <div className='col-md-12'>

              <div style={{marginBottom:20}}>
                 <label style={{color:"#073460"}}>Nouveau tarif</label>
                 <input placeholder="0.00 Fcfa" name="tarif" type="text" className="form-control"   required/> 
              </div>

               <div style={{marginBottom:20}}>
                 <label style={{color:"#073460"}}>Code client</label>
                 <input placeholder="" name="codeclient" type="text" className="form-control"   required/> 
              </div>


              <div style={{marginBottom:20}}>
                 <label style={{color:"#073460"}} >Commentaires</label>
                 <textarea  name="comment" className="form-control">

                </textarea>
              </div>

              <br/>

               <button type='submit' style={{background:'#E99F28',    width: '100%'}} className="btn float-btn color2-bg btn9886 w100">Valider le nouveau tarif</button>
            
          </div>
    
         </form>

        

        </Modal.Body>
        <Modal.Footer style={{    border: 0}}>
          
         
        </Modal.Footer>
</Modal>




<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body>

          <div className='center'>
            <img src='/images/validation-150x150.png' />
          </div>

          <br/><br/>
            <div className='center'><h4 className='center'>{t('Félicitations')} </h4></div>
           <br/>
          <div className='center'>
             {t('Votre réponse a bien été enregistrée..')}
          </div>

        </Modal.Body>
        <Modal.Footer>
          
         
        </Modal.Footer>
</Modal>
        </div>
    );
}

export default Page;
import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card,Modal} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { DashbordMenuClient,DashbordHeaderClient } from "../../../components";
import { useTranslation } from 'react-i18next';
import { apiClient } from "../../../actions/api"

import { AUTHENTICATE_USER, LOGOUT_USER } from "../../../constants/actions";
import { getAllCategories } from "../../../actions/pages";
import { updateInfoUser, suspendCompte } from "../../../actions/user";
import { BASE_URL_PROD } from "../../../components/config/keys";
import Switch from '@material-ui/core/Switch';
import Slider from '@material-ui/core/Slider';
import { saveLocataire } from "../../../actions/pages";

 

const Page = () => {

   
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user)
  const { data } = useSelector(state => state.pages)
  const { t, i18n } = useTranslation();
  const currentlangStorage = localStorage.getItem("currentlang");
  const currentlang=i18n.language;
  const [langset, setLangset] = useState(false);

  const history = useHistory()
  const [startDate, setStartDate] = useState(new Date());
  const label = { inputProps: { 'aria-label': '' } };


  const handleSubmitLocataire = (e) => {
       
    e.preventDefault();
    const formData = new FormData(e.target)
    const formDataObj = Object.fromEntries(formData.entries())
    formDataObj.user_id = user.id
    console.log('formDataObj');
    console.log(formDataObj);
  
    dispatch(saveLocataire(formDataObj)).then((res) => {
      history.push('/client/creer-rapport')
    });
  }

    const returnitems =  () => {
        return(

            <div className='col-md-3  position_relative'>

                 <div className="process-item position_relative" style={{cursor:'pointer'}} onClick={()=>history.push('/detail-bien/1869')}>
                        <div className="process-img" style={{backgroundImage:"url('/images/imo.png')", minHeight: '95px'}}>
                       
                        </div>
                       
                        <h4 className='basecolor title' ><strong style={{color:'#0E0E0E'}}>Appartement </strong>3 pièces 65 m²</h4>
                       
                        <h6 className='' style={{ textAlign:"left", fontSize:"11px", color:"gray",     display: 'flex',alignItems: 'center',justifyContent: 'space-between' }}>
                          <div><img src="/images/immo/icone-map.png" className="" style={{ width:"12px",    height: '16px',     marginTop: '0px' }} /> Paris 18ème</div>
                          <div className="priceoffre" style={{ textAlign:"left", fontSize:"18px" ,padding: '4px',    background: '#073460'}}><span style={{fontSize: '12px', color: '#FFF'}}>85 0000 Fcfa </span></div>
                        </h6>
                        
                      </div>

            </div>


          )
    }
     
    return (
        <div className="myccount editprofil abonnement rapportestreint locataire " >       
                       <section className="gray-bg main-dashboard-sec dashboard myccount" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <DashbordMenuClient src=''/>
                                 
                            </div>


                         <div className='col-md-9 dashbordassitantcontent' >

                             <div className='tab' id=''>


                              <DashbordHeaderClient src={'w'} />

                             <div className='row' style={{marginTop:'30px'}}>

                               <div className='col-md-12'>

                               <form onSubmit={handleSubmitLocataire}>
                                  <div class="rowtitel">
                                    <img src="/images/locataire.png" style={{width: '50px'}}/>
                                     <h3 class="htri1">Fiche locataire </h3>
                                  </div>


                                  <div className='row'>

                                    <div className='col-md-8'>


                                                        <div className="custom-form">
 
                                      <div className='row'  style={{marginBottom:'25px'}}>

                                        <div className="col-md-6"> 
                                        <input placeholder="Nom" name="last_name" type="text" class="form-control inptxt" required=""  />
                                        </div>
                                        <div className="col-md-6"> 
                                        <input placeholder="Prénom" name="first_name" type="text" class="form-control inptxt" required=""  />
                                        </div>
                                   

                                     </div>


                                      <div className='row'  style={{marginBottom:'25px'}}>

                                        <div className="col-md-12"> 
                                        <input placeholder="Adresse" name="adresse" type="text" class="form-control inptxt" required=""  />
                                        </div>

                                      </div>


                                      <div className='row'  style={{marginBottom:'25px'}}>

                                 
                                        <div className="col-md-4"> 
                                        <input placeholder="CP" name="cp" type="text" class="form-control inptxt" required=""  />
                                        </div>
                                        <div className="col-md-8"> 
                                        <input placeholder="Ville" name="ville" type="text" class="form-control inptxt" required=""  />
                                        </div>
                                         

                                     </div>

                                       <div className='row'  style={{marginBottom:'25px'}}>

                                 
                                        <div className="col-md-4"> 
                                        <input placeholder="Téléphone" name="phone" type="text" class="form-control inptxt" required=""  />
                                        </div>
                                        <div className="col-md-8"> 
                                        <input placeholder="Email" name="email" type="text" class="form-control inptxt" required=""  />
                                        </div>
                                     </div>
                                   </div>
                                    </div>
                                     <div className='col-md-4'>
                                     <div className='cdr009'>
                                        <p>
                                         <label>Loyer</label>
                                           <input placeholder="00,00 Fcfa" name="loyer" type="text" class="form-control inptxt" required=""  />
                                        </p>
                                        <br/>
                                        <p>
                                         <label>Charges</label>
                                           <input placeholder="00,00 Fcfa" name="charges" type="text" class="form-control inptxt" required=""  />
                                        </p>
                                      </div>
                                    </div>
                                     <div className='col-md-8'></div>
                                    <div className='col-md-4'>
                                          <button type="submit" className="btn float-btn color2-bg btn9876 btcustom "  style={{borderRadius:27, marginTop: '5px', width:'100%'}}>Enregistrer</button>
                                    </div>
                                  </div>
                                </form>                          
                               </div>
                              </div>                                
                          </div>
                         </div>
                    </div>
            </section>
        </div>
    );
}

export default Page;


